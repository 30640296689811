<template>
  <v-container>
    <div class="back-plot">
      <!-- BOC:[error] -->
      <ApiErrorDialog v-if="api.isError" :api="api" />
      <!-- EOC -->
      <v-card class="transparent" elevation="0">
        <v-card-title class="text-h5 grey lighten-5 rounded-lg py-2">
          <v-row>
            <v-col cols="9" class="d-flex rounded align-center">
              {{ classroom.name }}
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-end">
              <CloseBtn size="40" :callback="exit" />
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <!-- EOC -->
      <v-card
        :class="
          settings.graphics && settings.graphics.mode == 'low'
            ? 'low_plot'
            : 'plot'
        "
        :loading="playerApi.isLoading"
      >
        <v-card-title class="pt-2">{{
          $t("view.PageEntranceStudentClassroom.title")
        }}</v-card-title>
        <v-card-text v-if="classroom">
          <v-card
            v-if="classroom.enableWalkin"
            elevation="0"
            class="green lighten-3 d-flex flex-row justify-space-between align-center pa-4"
          >
            <div class="font-weight-medium text-h6">
              {{ $t("view.PageEntranceStudentClassroom.stringNoAccount") }}
            </div>
            <v-btn @click="navRegister" color="green darken-3" dark rounded>{{
              $t("action.register")
            }}</v-btn>
          </v-card>
          <v-text-field
            hide-details
            v-if="data.Student && data.Student.length > 5"
            prepend-inner-icon="mdi-magnify"
            class="mt-5 mx-3 mb-0"
            :label="$t('view.PageEntranceStudentClassroom.search')"
            :placeholder="$t('view.PageEntranceStudentClassroom.search')"
            solo
            v-model="searchKey"
            @keyup="search"
          ></v-text-field>
          <v-card
            v-if="suggest && !searchKey"
            elevation="0"
            class="green lighten-5 d-flex flex-row justify-space-between align-center pa-2 my-3"
            color="green lighten-4"
          >
            <v-row class="mx-0">
              <v-col cols="3" class="text-left">
                <AbstractAvatar
                  v-if="suggest.avatar"
                  :width="60"
                  :height="60"
                  :avatar="suggest.avatar"
                  :key="suggest.id"
                />
                <div v-else>
                  <v-img
                    max-height="60"
                    max-width="60"
                    :src="require('@/assets/entity/UnnamedCharacter1.png')"
                  ></v-img>
                </div>
              </v-col>
              <v-col
                class="mx-0 d-flex flex-row justify-space-between align-center"
                cols="9"
              >
                <div
                  class="font-weight-medium text-h6"
                  style="word-break: break-all"
                >
                  <v-chip small color="green lighten-3">{{
                    $t("string.lastLogin")
                  }}</v-chip>
                  <br />
                  {{ suggest.name }}
                </div>

                <v-btn
                  color="green lighten-1"
                  rounded
                  class="text-h6"
                  style="text-transform: capitalize"
                  @click="selectStudent(suggest.id, suggest)"
                >
                  {{ $t("action.select") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            v-for="(item, i) in searchData"
            :key="i"
            elevation="0"
            class="green lighten-5 d-flex flex-row justify-space-between align-center pa-2 my-3"
            :color="
              item && selectedStudent == item.id ? ' green lighten-3' : null
            "
          >
            <v-row v-if="item" class="mx-0">
              <v-col cols="3" class="text-left">
                <AbstractAvatar
                  v-if="item.avatar"
                  :width="60"
                  :height="60"
                  :avatar="item.avatar"
                  :key="item.id"
                />
                <div v-else>
                  <v-img
                    max-height="60"
                    max-width="60"
                    :src="require('@/assets/entity/UnnamedCharacter1.png')"
                  ></v-img>
                </div>
              </v-col>
              <v-col
                class="mx-0 d-flex flex-row justify-space-between align-center"
                cols="9"
              >
                <div
                  class="font-weight-medium text-h6"
                  style="word-break: break-all"
                >
                  {{ item.name }}
                </div>

                <v-btn
                  color="green lighten-1"
                  rounded
                  class="text-h6"
                  style="text-transform: capitalize"
                  @click="selectStudent(item.id, item)"
                >
                  {{ $t("action.select") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            v-if="searchData.length == 0"
            elevation="0"
            class="green lighten-5 d-flex flex-row justify-space-between align-center pa-4 my-3"
          >
            <div class="font-weight-medium text-h6">
              {{ $t("string.noItem") }}
            </div>
          </v-card>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import AvatarSkeleton from "@/components/skeleton/Avatar";
let click_sfx = new Audio(require("@/assets/sounds/sfx_click_5.wav"));
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
  }),
  props: [
    //
  ],
  data: () => ({
    suggest: null,
    searchKey: "",
    selectedStudent: null,
    student: null,
    classroom: {},
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    playerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    accountApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    data: [],
    players: [],
    searchData: [],
    Player: {
      avatar: null,
      password: null,
    },
    Student: {},
    authData: {},
  }),
  created() {
    this.accountApi.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.accountApi.callbackReset = () => {
      this.accountApi.isLoading = true;
      this.accountApi.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.accountApi.callbackError = (e) => {
      this.accountApi.isLoading = false;
      this.accountApi.isError = true;
      this.accountApi.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      //this.fetchPlayer();
      this.searchData = this.data.Student;


      if (this.auth && this.auth.Player && this.data.Student.length > 5) {
        this.suggest = this.searchData.find(
          (element, index) =>
            element.mochiId == this.auth.Player.mochiId && index > 5
        );
        // if (this.suggest) {
        //   this.searchData = this.searchData.filter(
        //     (item) => item !== this.suggest
        //   );
        //   this.searchData.unshift(this.suggest);
        // }
      }
    };

    this.accountApi.callbackSuccess = (resp) => {
      this.players = resp;
      this.accountApi.isLoading = false;
    };
  },
  mounted() {
    if (this.auth && this.auth.Classroom) {
      this.classroom = this.auth.Classroom;
      this.authData = this.$_.cloneDeep(this.auth);
      this.fetch();
    }
  },
  methods: {
    search() {
      if (this.searchKey.trim().length == 0) {
        this.searchData = this.data.Student;
      } else {
        let key = this.searchKey.trim().toLowerCase();
        this.searchData = this.data.Student.filter(function (element) {
          return element.name.toLowerCase().includes(key);
        });
      }
    },
    fetch() {
      this.api.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/student";
      this.api.params = { classroomId: this.classroom.id };
      this.$api.fetch(this.api);
    },
    fetchPlayer() {
      this.accountApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/account/" +
        this.auth.Account.id +
        "/player";
      this.$api.fetch(this.accountApi);
    },
    fetchStudent(id) {
      this.playerApi.method = "get";

      this.playerApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/player/" +
        id;

      this.playerApi.callbackReset = () => {
        this.playerApi.isLoading = true;
        this.playerApi.isError = false;
      };

      this.playerApi.callbackError = (e) => {
        this.playerApi.isLoading = false;
        this.playerApi.isError = true;
        this.playerApi.error = e;
      };

      this.playerApi.callbackSuccess = (resp) => {
        this.playerApi.isLoading = false;
        this.authData.Player = resp;
        this.authData.User = this.Student;
        this.$store.commit("updateAuth", this.authData);
        this.$router.push({
          name: "PageAccessPassword",
        });
      };
      this.$api.fetch(this.playerApi);
    },
    navRegister() {
      click_sfx.play();
      click_sfx.volume = this.settings.audio.sfx * this.settings.audio.master;
      this.$router.push({
        name: "PageAccessClassroomRegister",
      });
    },
    getAvatar(student) {
      let avatar = null;
      avatar = this.$_.find(this.players, function (n) {
        if (n.mochiId == student.mochiId) {
          return n.avatar;
        }
      });
      return avatar;
    },
    selectStudent(id, student) {
      //BOC
      if(process.env.VUE_APP_MAINTENANCE == "true" && !this.settings.debug) {
        this.api.isError = true;
        this.api.error = {en:"This season not yet open. Please wait patiently.",zh:"这一季还未开始。请耐心等候。",ms:"Belum mula lagi. Sila tunggu sebentar."}[this.$_getLocale()];
        return;
      }
      //EOC
      this.Student = student;
      this.Student.type = "child";
      click_sfx.play();
      click_sfx.volume = this.settings.audio.sfx * this.settings.audio.master;
      if (student.mochiId) {
        this.fetchStudent(student.mochiId);
      } else {
        this.authData.Player = this.Player;
        this.authData.User = this.Student;
        this.$store.commit("updateAuth", this.authData);
        this.$router.push({
          name: "PageAccessOnboardingAvatar",
        });
      }
    },
    exit() {
      this.$router.push({
        name:
          this.auth.Group.type == "personal"
            ? "PageAccessMain"
            : "PageAccessSchool",
      });
    },
  },
};
</script>

<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.image1 {
  position: absolute;
  z-index: 2;
}
.image2 {
  position: absolute;
  z-index: 3;
}

.image3 {
  position: absolute;
  z-index: 1;
}

.title {
  pointer-events: none;
  font-size: 1em !important;
}
</style>