import { Scene } from "phaser";
import store from "@/store";
import router from "@/router";
import i18n from "@/plugins/i18n";
import _ from "lodash";
import Phaser from "phaser";

export default class BossScene extends Scene {
  constructor() {
    super({ key: "BossScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.round = data.round;
    this.qCount = data.qCount;
    this.questions = data.questions;
    this.score = data.score ? data.score : 0;
    this.friend = data.friend;
    this.forceFriend = data.forceFriend;
    this.isCorrectAnswer = data.isCorrectAnswer;
    this.countCorrect = data.countCorrect;
    this.difficulty = data.difficulty;
    this.noOfRounds = data.noOfRounds;
    this.skyPosition = data.skyPosition ? data.skyPosition : 0;
    this.bossRound = data.bossRound;
    this.bossName = data.bossName ? data.bossName : "Shark";
  }

  playerAvatarObject = [];
  isFishingDone = false;
  fishScore = 0;
  fishWeight = 0;
  fishSize = 0.3;
  catchCount = 0;

  create() {
    this.registerAnims();
    this.registerSounds();

    // this.showScore();

    //* setup the Background  *//
    this.imgSky = this.add
      .tileSprite(0, 0, 480, 854, "imgBackground")
      .setOrigin(0);
    this.imgBG = this.add.image(240, 400, "imgBG").setScale(0.5);
    this.imgSky.tilePositionX = this.skyPosition;

    this.imgFarShip = this.add
      .image(400, 498, "imgFarShip")
      .setOrigin(0)
      .setScale(0.5);
    this.imgWave1 = this.add
      .image(-50, 330, "imgWave1")
      .setOrigin(0)
      .setScale(0.4);
    this.imgWave2 = this.add
      .image(-40, 340, "imgWave2")
      .setOrigin(0)
      .setScale(0.4);
    this.imgRodWire = this.add
      .image(297, 491, "imgRodWire")
      .setOrigin(0)
      .setScale(0.3)
      .setDepth(7);
    this.imgRod = this.add
      .image(150, 490, "imgRod")
      .setOrigin(0)
      .setScale(0.3)
      .setDepth(7);

    this.sptSplash = this.add
      .sprite(300, 590, "sptSplash")
      .setScale(1.2)
      .setDepth(7)
      .play("animSplash");

    this.imgFishingBarBack = this.add
      .image(240, 280, "imgFishingBarBack")
      .setOrigin(0.5, 0)
      .setScale(0.42, 0.5)
      .setDepth(8);

    const fishPosition = Math.floor(Math.random() * (300 - 80 + 1)) + 80;

    this.fishingBarRect = this.add
      .rectangle(
        fishPosition,
        310,
        this.isCorrectAnswer ? 100 : 60,
        50,
        0x2caaf6
      )
      .setDepth(8);

    this.imgFishingBarOutline = this.add
      .image(240, 280, "imgFishingBarOutlineBoss")
      .setOrigin(0.5, 0)
      .setScale(0.42, 0.5)
      .setDepth(8);

    this.imgFishingBarFish = this.add
      .image(fishPosition, 310, "imgFishingBarFish")
      .setOrigin(0.5)
      .setScale(0.4)
      .setDepth(8);

    this.imgFishingBarHook = this.add
      .image(40, 270, "imgFishingBarHook")
      .setOrigin(0.5, 0)
      .setScale(0.42)
      .setDepth(8);

    this.imgFishingBarFish_1 = this.add
      .image(65, 353, "imgFishingBarFish")
      .setOrigin(0.5)
      .setScale(0.3)
      .setDepth(8)
      .setTint(0x000000)
      .setAlpha(0.5);
    this.imgFishingBarFish_2 = this.add
      .image(110, 353, "imgFishingBarFish")
      .setOrigin(0.5)
      .setScale(0.3)
      .setDepth(8)
      .setTint(0x000000)
      .setAlpha(0.5);
    this.imgFishingBarFish_3 = this.add
      .image(155, 353, "imgFishingBarFish")
      .setOrigin(0.5)
      .setScale(0.3)
      .setDepth(8)
      .setTint(0x000000)
      .setAlpha(0.5);
    this.imgBoat = this.add
      .image(50, 600, "imgBoat")
      .setOrigin(0)
      .setScale(0.4)
      .setDepth(8);

    this.imgWave3 = this.add
      .image(-20, 360, "imgWave3")
      .setOrigin(0)
      .setScale(0.4)
      .setDepth(8);

    this.imgWave4 = this.add
      .image(-50, 340, "imgWave4")
      .setOrigin(0)
      .setScale(0.5)
      .setDepth(8);

    this.imgFishingBtn = this.add
      .image(240, 730, "imgFishingBtn")
      .setOrigin(0.5)
      .setDepth(8);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.6)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.scoreBoard = this.add.image(100, 40, "imgScorePanel").setScale(0.4);
    this.roundBoard = this.add.image(73, 85, "imgScorePanel").setScale(0.25);

    this.scoreText = this.add
      .text(
        40,
        40,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 22,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5);

    this.roundText = this.add
      .text(35, 87, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#492a12",
      })
      .setOrigin(0, 0.5);

    this.dark = this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0);

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(120, 560, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(120, 560, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color" && key != "right") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(120, 560, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }

    this.playerNameBoard = this.add.rectangle(120, 480, 180, 40, 0xffffff, 0.5);

    this.playerName = this.add
      .text(
        120,
        480,
        this.name.length > 20 ? this.name.substring(0, 12) : this.name,
        {
          color: "#000",
          fontSize: "15px",
          fontFamily: "Sassoon, sans-serif",
          align: "center",
        }
      )
      .setOrigin(0.5);

    this.playerTween = this.tweens.add({
      targets: this.playerAvatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      y: "-=10",
      duration: 4000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    this.warningBackground = this.add
      .rectangle(240, 390, 480, 200, 0xff0000)
      .setDepth(9)
      .setAlpha(0);
    this.warningText = this.add
      .text(240, 370, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 48,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(9)
      .setShadow(3, 3, "#000", 0, true, true);

    this.warningMessageText = this.add
      .text(240, 420, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 28,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(9)
      .setShadow(1.5, 1.5, "#000", 0, true, true);

    this.time.delayedCall(1000, () => {
      this.cameras.main.shake(1000, 0.008);
      if (this.playerMask) {
        this.playerMask.destroy();
      }
      this.playerMask = this.add
        .image(
          this.playerAvatarObject[0].x,
          this.playerAvatarObject[0].y,
          "maskFear"
        )
        .setScale(0.25)
        .setDepth(6);

      this.sfxWarningAlarm.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxWarningAlarm.play();
      this.warningText.setText(i18n.t("miniGames.fishing.warning"));
      this.warningText.setTint(0xffffff);
      this.warningMessageText.setText(i18n.t("miniGames.fishing.boss"));
      this.warningMessageText.setTint(0xffffff);
      this.tweens.add({
        targets: this.warningBackground,
        alpha: 0.5,
        duration: 200,
        ease: "Sine.easeInOut",
      });
      this.tweens.add({
        targets: [this.warningText, this.warningMessageText],
        scaleY: 1,
        scaleX: 1,
        duration: 200,
        ease: "Sine.easeInOut",
        onComplete: () => {
          this.tweens.add({
            targets: this.warningText,
            alpha: 0,
            repeat: 3,
            yoyo: true,
            duration: 200,
            ease: "Sine.easeInOut",
            onComplete: () => {
              this.sfxWarningAlarm.stop();
              this.tweens.add({
                targets: [this.warningText, this.warningMessageText],
                scaleY: 0,
                scaleX: 0,
                duration: 200,
                delay: 1000,
                ease: "Sine.easeInOut",
              });
              this.tweens.add({
                targets: [this.warningBackground],
                alpha: 0,
                duration: 200,
                delay: 1000,
                ease: "Sine.easeInOut",
                onComplete: () => {
                  this.btnTween = this.tweens.add({
                    targets: this.imgFishingBtn,
                    scaleX: 0.95,
                    scaleY: 0.95,
                    duration: 500,
                    repeat: -1,
                    yoyo: true,
                  });
                  this.imgFishingBtn
                    .setInteractive({ useHandCursor: true })
                    .on("pointerdown", this.fishing, this);
                  this.hookTween = this.tweens.add({
                    targets: this.imgFishingBarHook,
                    x: "+=400",
                    duration:
                      this.difficulty == 1
                        ? 1400
                        : this.difficulty == 2
                        ? 1000
                        : 600,
                    repeat: -1,
                    yoyo: true,
                    onRepeat: () => {
                      this.cameras.main.shake(300, 0.008);
                      const fishPosition =
                        Math.floor(Math.random() * (300 - 80 + 1)) + 80;

                      this.fishingBarRect.x = fishPosition;

                      this.imgFishingBarFish.x = fishPosition;
                    },
                    onStart: () => {
                      this.sfxSplash.setVolume(
                        store.state.settings.data.audio.sfx *
                          store.state.settings.data.audio.master
                      );
                      this.sfxSplash.play();
                    },
                  });
                },
              });
            },
          });
        },
      });
    });

    this.itemTween1 = this.tweens.add({
      targets: [this.imgWave1, this.imgFarShip],
      y: "-=5",
      duration: 3000,
      repeat: -1,
      yoyo: true,
    });

    this.itemTween2 = this.tweens.add({
      targets: [this.imgWave2],
      y: "-=10",
      duration: 3500,
      repeat: -1,
      yoyo: true,
    });

    this.itemTween3 = this.tweens.add({
      targets: [
        this.imgWave3,
        this.imgBoat,
        this.imgRod,
        // this.imgRodHook,
        this.imgRodWire,
        this.sptSplash,
        // this.playerName,
        // this.playerNameBoard,
      ],
      y: "-=10",
      duration: 4000,
      repeat: -1,
      yoyo: true,
    });

    this.itemTween4 = this.tweens.add({
      targets: [this.imgWave4],
      y: "-=20",
      duration: 4500,
      repeat: -1,
      yoyo: true,
    });
  }

  update() {
    this.imgSky.tilePositionX += 0.2;
    if (this.playerMask && this.playerAvatarObject[0]) {
      this.playerMask.x = this.playerAvatarObject[0].x;
      this.playerMask.y = this.playerAvatarObject[0].y;
    }
  }

  showScore() {
    this.scoreDetails = [];
    this.scoreDetails.push(
      this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0.6).setDepth(9)
    );

    this.scoreDetails.push(
      this.add.image(240, 420, "imgSettingPanel").setScale(1, 1.1).setDepth(9)
    );

    this.scoreDetails.push(
      this.add
        .image(240, 375, this.fishScore > 0 ? "imgBossFish" : "imgTrash")
        .setScale(this.fishScore > 0 ? 0.5 : 0.3)
        .setDepth(9)
    );

    this.scoreDetails.push(
      this.add
        .text(240, 445, this.fishScore > 0 ? this.bossName : "Trash", {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 20,
          color: "#492a12",
          align: "center",
        })
        .setOrigin(0.5)
        .setDepth(9)
    );

    this.scoreDetails.push(
      this.add
        .text(
          240,
          480,
          this.fishScore > 0 ? this.fishWeight + " Kg" : "0.50 Kg",
          {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 22,
            color: "#492a12",
            align: "center",
          }
        )
        .setOrigin(0.5)
        .setDepth(9)
    );

    this.scoreDetails.push(
      this.add
        .text(
          240,
          290,
          i18n.t("miniGames.general.score") + " : + " + this.fishScore,
          {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 32,
            color: "#492a12",
            align: "center",
          }
        )
        .setOrigin(0.5)
        .setDepth(9)
    );

    this.scoreDetails.push(
      this.add
        .image(240, 540, "imgOptionBtn")
        .setScale(0.25, 0.3)
        .setDepth(9)
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", this.clickNext, this)
    );

    this.scoreDetails.push(
      this.add
        .text(240, 540, i18n.t("miniGames.general.ok"), {
          fontSize: "24px",
          fill: "#000",
          fontFamily: "Sassoon, sans-serif",
        })
        .setDepth(9)
        .setOrigin(0.5)
    );
  }

  clickNext() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.scoreDetails.forEach((element) => {
      element.destroy();
    });
    this.time.delayedCall(1000, () => {
      // this.imgCatch.destroy();
      this.scoreIndication(300, 500, this.fishScore);
      this.goNext();
    });
  }

  fishing() {
    if (!this.isFishingDone) {
      this.sfxStart.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxStart.play();
      this.hookTween.stop();
      this.sfxSplash.stop();
      if (
        Phaser.Geom.Intersects.RectangleToRectangle(
          this.fishingBarRect.getBounds(),
          new Phaser.Geom.Rectangle(
            this.imgFishingBarHook.getBounds().x + 20,
            this.imgFishingBarHook.getBounds().y,
            this.imgFishingBarHook.getBounds().width - 40,
            this.imgFishingBarHook.getBounds().height
          )
        )
      ) {
        this.catchCount += 1;

        if (this.catchCount == 1) {
          this.imgFishingBarFish_1.setTint(0xffffff).setAlpha(1);
        } else if (this.catchCount == 2) {
          this.imgFishingBarFish_2.setTint(0xffffff).setAlpha(1);
        } else if (this.catchCount == 3) {
          this.imgFishingBarFish_3.setTint(0xffffff).setAlpha(1);
        }

        if (this.catchCount == 3) {
          this.math_data = _.cloneDeep(store.state.math.data);
          this.math_data.boss.isCollected = true;
          store.commit("updateMath", this.math_data);
          this.btnTween.stop();
          this.isFishingDone = true;

          var distance = Math.round(
            Math.abs(this.imgFishingBarHook.x - this.imgFishingBarFish.x)
          );

          if (distance < 5) {
            this.fishWeight = 1.0;
            this.fishScore = 100;
            this.fishSize = 0.35;
          } else if (distance < 10) {
            this.fishWeight = 0.9;
            this.fishScore = 90;
            this.fishSize = 0.34;
          } else if (distance < 15) {
            this.fishWeight = 0.8;
            this.fishScore = 80;
            this.fishSize = 0.33;
          } else if (distance < 20) {
            this.fishWeight = 0.7;
            this.fishScore = 70;
            this.fishSize = 0.32;
          } else {
            this.fishWeight = 0.6;
            this.fishScore = 60;
            this.fishSize = 0.31;
          }

          if (this.playerMask) {
            this.playerMask.destroy();
          }
          let playerEye = this.playerAvatarObject.find(
            (sprite) => sprite.texture.key === "eye"
          );
          if (playerEye) {
            playerEye.setAlpha(0);
          }
          this.playerMask = this.add
            .image(
              this.playerAvatarObject[0].x,
              this.playerAvatarObject[0].y,
              "maskHappy"
            )
            .setScale(0.25)
            .setDepth(6);

          this.imgCatch = this.add
            .image(300, 750, "imgBossFish")
            .setOrigin(0.5, 0)
            .setScale(0.5)
            .setDepth(7);

          this.imgReaction = this.add
            .image(180, 520, "imgReactionLove")
            .setScale(0)
            .setDepth(6);

          this.sfxReaction = this.sfxFishSuccess;
          // this.feedbackBack = this.add
          //   .graphics()
          //   .fillStyle(0x00ff00)
          //   .fillRoundedRect(140, 370, 200, 60, 15)
          //   .setDepth(9);
          // this.feedbackTxt = this.add
          //   .text(240, 400, "Success", {
          //     fontSize: "24px",
          //     fill: "#000",
          //     fontFamily: "Sassoon, sans-serif",
          //   })
          //   .setDepth(9)
          //   .setOrigin(0.5);

          this.sptSplash.setAlpha(0);

          this.tweens.add({
            targets: [this.imgRodWire],
            scaleY: 0.05,
            duration: 1000,
            onStart: () => {
              this.tweens.add({
                targets: [this.imgCatch],
                y: "-= 250",
                duration: 1000,
              });
            },
            onComplete: () => {
              this.tweens.add({
                targets: [this.imgReaction],
                scaleX: 0.4,
                scaleY: 0.4,
                duration: 100,
              });

              this.sfxReaction.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.sfxReaction.play();

              this.time.delayedCall(2000, () => {
                // this.feedbackBack.destroy();
                // this.feedbackTxt.destroy();
                this.showScore();
              });
            },
          });
        } else {
          this.time.delayedCall(200, () => {
            const fishPosition =
              Math.floor(Math.random() * (300 - 80 + 1)) + 80;

            this.fishingBarRect.x = fishPosition;

            this.imgFishingBarFish.x = fishPosition;

            this.hookTween.play();
            this.sfxSplash.play();
          });
        }
      } else {
        this.btnTween.stop();
        this.isFishingDone = true;
        if (this.playerMask) {
          this.playerMask.destroy();
        }
        let playerEye = this.playerAvatarObject.find(
          (sprite) => sprite.texture.key === "eye"
        );
        if (playerEye) {
          playerEye.setAlpha(0);
        }
        this.playerMask = this.add
          .image(
            this.playerAvatarObject[0].x,
            this.playerAvatarObject[0].y,
            "maskSad"
          )
          .setScale(0.25)
          .setDepth(6);
        this.imgCatch = this.add
          .image(300, 750, "imgTrash")
          .setOrigin(0.5, 0)
          .setScale(0.3)
          .setDepth(7);

        this.imgReaction = this.add
          .image(180, 520, "imgReaction!")
          .setScale(0)
          .setDepth(6);

        this.sfxReaction = this.sfxFishFailed;

        // this.feedbackBack = this.add
        //   .graphics()
        //   .fillStyle(0xff0000)
        //   .fillRoundedRect(140, 370, 200, 60, 15)
        //   .setDepth(9);
        // this.feedbackTxt = this.add
        //   .text(240, 400, "Failed", {
        //     fontSize: "24px",
        //     fill: "#fff",
        //     fontFamily: "Sassoon, sans-serif",
        //   })
        //   .setDepth(9)
        //   .setOrigin(0.5);

        this.sptSplash.setAlpha(0);

        this.tweens.add({
          targets: [this.imgRodWire],
          scaleY: 0.05,
          duration: 1000,
          onStart: () => {
            this.tweens.add({
              targets: [this.imgCatch],
              y: "-= 230",
              duration: 1000,
            });
          },
          onComplete: () => {
            this.tweens.add({
              targets: [this.imgReaction],
              scaleX: 0.4,
              scaleY: 0.4,
              duration: 100,
            });

            this.sfxReaction.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxReaction.play();

            this.time.delayedCall(2000, () => {
              // this.feedbackBack.destroy();
              // this.feedbackTxt.destroy();
              this.showScore();
            });
          },
        });
      }
    }
  }

  startGame() {
    /**
     * * Show round panel
     */
  }

  registerSounds() {
    this.sfxSimpleClick = this.sound.add("sfxSimpleClick");
    this.sfxFishSuccess = this.sound.add("sfxFishSuccess");
    this.sfxFishFailed = this.sound.add("sfxFishFailed");
    this.sfxSplash = this.sound.add("sfxSplash");
    this.sfxSplash.loop = true;
    this.sfxWarningAlarm = this.sound.add("sfxWarningAlarm");
    this.sfxWarningAlarm.loop = true;
    this.sfxScoreIncrease = this.sound.add("sfxScoreIncrease");
    this.sfxStart = this.sound.add("sfxStart");
    // this.sfxReady = this.sound.add("sfxReady");
    // this.sfxGo = this.sound.add("sfxGo");
    // this.sfxRound = this.sound.add("sfxRound");
  }

  registerAnims() {
    this.anims.create({
      key: "animSplash",
      frames: this.anims.generateFrameNumbers("sptSplash", {
        frames: [0, 1],
      }),
      frameRate: 5,
      repeat: -1,
    });
  }

  goNext() {
    this.itemTween1.stop();
    this.itemTween2.stop();
    this.itemTween3.stop();
    this.itemTween4.stop();
    this.playerTween.stop();

    this.tweens.add({
      targets: [this.imgWave1],
      y: 330,
      duration: 2000,
    });

    this.tweens.add({
      targets: [this.imgWave2],
      y: 340,
      duration: 2000,
    });

    this.tweens.add({
      targets: [this.imgWave3],
      y: 360,
      duration: 2000,
    });

    this.tweens.add({
      targets: [this.imgWave4],
      y: 340,
      duration: 2000,
    });

    this.tweens.add({
      targets: [this.imgBoat],
      y: 600,
      duration: 2000,
    });

    this.tweens.add({
      targets: [this.imgRod],
      y: 490,
      duration: 2000,
    });

    this.tweens.add({
      targets: [this.imgRodWire],
      y: 491,
      duration: 2000,
    });

    this.tweens.add({
      targets: [this.imgFarShip],
      y: 498,
      duration: 2000,
    });

    this.playerTween = this.tweens.add({
      targets: this.playerAvatarObject,
      scaleY: 0.25,
      scaleX: 0.25,
      y: 560,
      duration: 2000,
      ease: "Sine.easeInOut",
    });

    this.time.delayedCall(2000, () => {
      this.playerAvatarObject = [];
      this.isFishingDone = false;
      this.fishScore = 0;
      this.fishWeight = 0;
      this.fishSize = 0.3;

      this.registry.destroy();
      this.events.off();
      this.scene.stop("BossScene");

      if (this.round == this.noOfRounds) {
        let star;
        this.math_data = _.cloneDeep(store.state.math.data);
        this.score += (this.difficulty - 1) * 50;
        if (this.countCorrect == 3) {
          star = 1;
        } else if (this.countCorrect == 4) {
          star = 2;
        } else if (this.countCorrect == 5) {
          star = 3;
        } else {
          star = 0;
        }
        this.math_data.star = star;

        this.math_data.countCorrect = this.countCorrect;
        this.math_data.score = this.score;
        this.math_data.difficulty = this.difficulty;
        this.math_data.noOfRounds = this.noOfRounds;
        store.commit("updateMath", this.math_data);

        this.time.delayedCall(1000, () => {
          router.push({
            name: "PageMathGameScore",
            params: {
              topic: store.state.math.data.topic,
              game: store.state.math.data.game,
            },
          });
        });
      } else {
        this.scene.start("BeforeQuestionScene", {
          avatar: this.avatar,
          name: this.name,
          round: this.round + 1,
          score: this.score,
          questions: this.questions,
          friend: this.friend,
          difficulty: this.difficulty,
          countCorrect: this.countCorrect,
          noOfRounds: this.noOfRounds,
          skyPosition: this.imgSky.tilePositionX,
          bossRound: this.bossRound,
          bossName: this.bossName,
        });
      }
    });
  }

  clickSetting() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "BossScene",
    });
  }

  scoreIndication(x, y, score, increase = true) {
    let indication = this.add
      .text(x, y, increase ? "+ " + score : "- " + score, {
        color: increase ? "#000" : "#ff0000",
        fontSize: "20px",
        fontFamily: "Sassoon, sans-serif",
      })
      .setOrigin(0.5);
    this.tweens.add({
      targets: indication,
      alpha: 0,
      y: "-=50",
      duration: 1000,
      ease: "Sine.easeInOut",
      onStart: () => {
        this.sfxScoreIncrease.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxScoreIncrease.play();
      },
      onComplete: () => {
        indication.destroy();
        this.score += score;
        this.scoreText.setText(
          i18n.t("miniGames.general.score") + " : " + this.score
        );
      },
    });
  }
}
