import { Scene } from "phaser";
import i18n from "@/plugins/i18n";

export default class LoadingScene extends Scene {
  constructor() {
    super({ key: "LoadingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.questions = data.questions;
    this.friend = data.friend;
    this.bossImage = data.bossImage;
    this.env = data.env;
    this.noOfRounds = data.noOfRounds;
    this.noOfCuts = data.noOfCuts;
    this.difficulty = data.difficulty;
    this.lampTime = data.lampTime;
    this.puzzle = data.puzzle;
  }

  preload() {
    var progressBar = this.add.graphics();
    var progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(100, 270, 280, 50);

    var width = this.cameras.main.width;
    var height = this.cameras.main.height;

    var loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: i18n.t("miniGames.general.loading") + "...",
      style: {
        font: "20px monospace",
        fill: "#000000",
      },
    });
    loadingText.setOrigin(0.5, 0.5);

    var percentText = this.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: "0%",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    percentText.setOrigin(0.5, 0.5);

    var assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: "",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    assetText.setOrigin(0.5, 0.5);

    this.load.on("progress", function (value) {
      percentText.setText(parseInt(value * 100) + "%");
      progressBar.clear();
      progressBar.fillStyle(0x000000, 1);
      progressBar.fillRect(110, 280, 260 * value, 30);
    });

    this.load.on("fileprogress", function (file) {
      assetText.setText("Loading asset: " + file.key);
    });
    this.load.on("complete", function () {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
      assetText.destroy();
    });
    // Loading logic END

    //*** load images ***//
    // general Images
    // buttons
    this.load.image(
      "imgCloseBtn",
      "/assets/gameAssets/common/images/btn-close.png"
    );
    this.load.image("imgOkBtn", "/assets/gameAssets/common/images/btn-ok.png");
    this.load.image(
      "imgOptionBtn",
      "/assets/gameAssets/common/images/btn-option.png"
    );
    this.load.image(
      "imgSettingBtn",
      "/assets/gameAssets/common/images/btn-setting.png"
    );

    this.load.image("imgTutArrow", "/assets/gameAssets/common/tutorial/arrow.png");
    this.load.image("imgTutArrowX", "/assets/gameAssets/common/tutorial/arrow-x.png");
    this.load.image("imgTutArrowY", "/assets/gameAssets/common/tutorial/arrow-y.png");

    // panel bg
    this.load.image(
      "imgRoundPanel",
      "/assets/gameAssets/common/images/panel-round.png"
    );
    this.load.image(
      "imgSettingPanel",
      "/assets/gameAssets/common/images/panel-setting.png"
    );
    this.load.image(
      "imgScorePanel",
      "/assets/gameAssets/common/images/panel-score.png"
    );
    this.load.image(
      "imgAnswerPanel",
      "/assets/gameAssets/common/images/panel-answer.png"
    );

    // others
    this.load.image(
      "imgAnswerTag",
      "/assets/gameAssets/common/images/answer-tag.png"
    );
    this.load.image(
      "imgCorrectAns",
      "/assets/gameAssets/common/images/answer-correct.png"
    );
    this.load.image(
      "imgWrongAns",
      "/assets/gameAssets/common/images/answer-wrong.png"
    );
    this.load.image(
      "imgCorrectMark",
      "/assets/gameAssets/common/images/mark-correct.png"
    );
    this.load.image(
      "imgWrongMark",
      "/assets/gameAssets/common/images/mark-wrong.png"
    );
    this.load.image(
      "imgExclMark",
      "/assets/gameAssets/common/images/mark-exclamation.png"
    );
    this.load.image("imgSpeech", "/assets/gameAssets/common/images/speech.png");

    // images specific to the game
    this.load.image(
      "imgBackground",
      "/assets/gameAssets/discoverGame/images/bg-1.png"
    );

    this.load.image(
      "imgTile",
      "/assets/gameAssets/discoverGame/images/tile-x.png"
    );

    this.load.image(
      "imgSite",
      "/assets/gameAssets/discoverGame/images/site.png"
    );

    this.load.image(
      "imgArrow",
      "/assets/gameAssets/discoverGame/images/arrow.png"
    );

    this.load.image(
      "imgArrowX",
      "/assets/gameAssets/discoverGame/images/arrow-1.png"
    );

    this.load.image(
      "imgLamp",
      "/assets/gameAssets/discoverGame/images/lamp.png"
    );

    this.load.image("imgAxe", "/assets/gameAssets/discoverGame/images/axe.png");

    this.load.image(
      "imgCamp",
      "/assets/gameAssets/discoverGame/images/camp-n.png"
    );

    this.load.image("imgNPC", "/assets/gameAssets/discoverGame/images/npc.png");

    this.load.image(
      "imgDino",
      "/assets/gameAssets/discoverGame/images/dino.png"
    );

    this.load.image(
      "imgDarkX",
      "/assets/gameAssets/discoverGame/images/dark-x.png"
    );

    this.load.image(
      "imgLampX",
      "/assets/gameAssets/discoverGame/images/lamp-x.png"
    );

    this.load.image(
      "imgHeart",
      "/assets/gameAssets/discoverGame/images/heart.png"
    );

    this.load.image(
      "imgBulb",
      "/assets/gameAssets/discoverGame/images/bulb.png"
    );

    this.load.image("imgTop", "/assets/gameAssets/discoverGame/images/top.png");

    this.load.image(
      "imgTopBack",
      "/assets/gameAssets/discoverGame/images/top-back.png"
    );

    this.load.image(
      "imgBlink",
      "/assets/gameAssets/discoverGame/images/blink.png"
    );

    this.load.image(
      "imgPuzzle",
      "/assets/gameAssets/discoverGame/images/puzzle.png"
    );

    this.load.image(
      "imgDino2",
      "/assets/gameAssets/discoverGame/images/dinoP2.png"
    );

    this.load.image(
      "imgBone1",
      "/assets/gameAssets/discoverGame/images/bone-1.png"
    );

    this.load.image(
      "imgBone2",
      "/assets/gameAssets/discoverGame/images/bone-2.png"
    );

    this.load.image(
      "imgBone3",
      "/assets/gameAssets/discoverGame/images/bone-3.png"
    );

    this.load.image(
      "imgBone4",
      "/assets/gameAssets/discoverGame/images/bone-4.png"
    );

    this.load.image("imgPuz", "/assets/gameAssets/discoverGame/images/puz.png");

    this.load.image(
      "imgBoneBrd",
      "/assets/gameAssets/discoverGame/images/bone-board.png"
    );

    this.load.image(
      "imgPuzzleBg",
      "/assets/gameAssets/discoverGame/images/puzzle-bg.png"
    );

    this.load.image(
      "imgLogo",
      "/assets/gameAssets/discoverGame/images/logo.png"
    );

    // ***************************************************************

    // //*** Load Sprite Sheets ***//

    // Common
    this.load.spritesheet(
      "sptIdea",
      "/assets/gameAssets/common/sprites/idea.png",
      {
        frameWidth: 120,
        frameHeight: 120,
      }
    );

    this.load.spritesheet(
      "sptIdea2",
      "/assets/gameAssets/common/sprites/idea-2.png",
      {
        frameWidth: 120,
        frameHeight: 120,
      }
    );

    // Specific
    this.load.spritesheet(
      "sptTile",
      "/assets/gameAssets/discoverGame/sprites/tile.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptCamp",
      "/assets/gameAssets/discoverGame/sprites/camp-n.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptCampEnd",
      "/assets/gameAssets/discoverGame/sprites/camp-end.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptBomb1",
      "/assets/gameAssets/discoverGame/sprites/bomb-1.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptBomb2",
      "/assets/gameAssets/discoverGame/sprites/bomb-2.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptGem1",
      "/assets/gameAssets/discoverGame/sprites/gem-1.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptGem2",
      "/assets/gameAssets/discoverGame/sprites/gem-2.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptBone1",
      "/assets/gameAssets/discoverGame/sprites/bone-1.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptBone2",
      "/assets/gameAssets/discoverGame/sprites/bone-2.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptBone3",
      "/assets/gameAssets/discoverGame/sprites/bone-3.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptBone4",
      "/assets/gameAssets/discoverGame/sprites/bone-4.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptSkull",
      "/assets/gameAssets/discoverGame/sprites/skull.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptCoin",
      "/assets/gameAssets/discoverGame/sprites/coin.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptEnergy",
      "/assets/gameAssets/discoverGame/sprites/energy.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptRock",
      "/assets/gameAssets/discoverGame/sprites/rock.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    this.load.spritesheet(
      "sptBlast",
      "/assets/gameAssets/kombatGame/sprites/bomb-blast.png",
      {
        frameWidth: 200,
        frameHeight: 200,
      }
    );

    this.load.spritesheet(
      "sptDark",
      "/assets/gameAssets/discoverGame/sprites/dark.png",
      {
        frameWidth: 480,
        frameHeight: 854,
      }
    );

    this.load.spritesheet(
      "sptDarkA",
      "/assets/gameAssets/discoverGame/sprites/dark-2.png",
      {
        frameWidth: 960,
        frameHeight: 1708,
      }
    );

    this.load.spritesheet(
      "sptBattry",
      "/assets/gameAssets/discoverGame/sprites/battry.png",
      {
        frameWidth: 90,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "sptMachine",
      "/assets/gameAssets/discoverGame/sprites/machine.png",
      {
        frameWidth: 250,
        frameHeight: 350,
      }
    );

    // ***************************************************************;

    // //*** load audio files ***//
    // general sfxs
    this.load.audio(
      "sfxCorrectAnswer",
      "/assets/gameAssets/common/sounds/answer-correct.wav"
    );
    this.load.audio(
      "sfxWrongAnswer",
      "/assets/gameAssets/common/sounds/answer-wrong.wav"
    );
    this.load.audio(
      "sfxScoreIncrease",
      "/assets/gameAssets/common/sounds/score-increase.wav"
    );
    this.load.audio(
      "sfxScoreDecrease",
      "/assets/gameAssets/common/sounds/score-decrease.wav"
    );
    this.load.audio(
      "sfxSimpleClick",
      "/assets/gameAssets/common/sounds/simple-click.wav"
    );
    this.load.audio(
      "sfxWarningAlarm",
      "/assets/gameAssets/common/sounds/warning-alarm.wav"
    );
    this.load.audio(
      "sfxFailure",
      "/assets/gameAssets/common/sounds/game-failure.wav"
    );
    this.load.audio(
      "sfxSuccess",
      "/assets/gameAssets/common/sounds/game-success.wav"
    );
    this.load.audio("sfxClose", "/assets/gameAssets/common/sounds/close.wav");
    this.load.audio("sfxRound", "/assets/gameAssets/common/sounds/round.wav");
    this.load.audio("sfxGo", "/assets/gameAssets/common/sounds/go.wav");

    // sfx specific to the game
    this.load.audio("bgm", "/assets/gameAssets/discoverGame/sounds/bgm.wav");
    this.load.audio(
      "sfxBomb",
      "/assets/gameAssets/discoverGame/sounds/bomb.wav"
    );
    this.load.audio(
      "sfxCollect",
      "/assets/gameAssets/discoverGame/sounds/collect.wav"
    );
    this.load.audio(
      "sfxEnergyPlus",
      "/assets/gameAssets/discoverGame/sounds/energy-plus.wav"
    );
    this.load.audio(
      "sfxEnergyMinus",
      "/assets/gameAssets/discoverGame/sounds/energy-minus-x.wav"
    );
    this.load.audio(
      "sfxDigSand",
      "/assets/gameAssets/discoverGame/sounds/dig-sand.mp3"
    );
    this.load.audio(
      "sfxDigStone",
      "/assets/gameAssets/discoverGame/sounds/dig-stone.mp3"
    );
    this.load.audio(
      "sfxDiscoverSuccess",
      "/assets/gameAssets/discoverGame/sounds/discover-success.wav"
    );
    this.load.audio(
      "sfxDiscoverFailed",
      "/assets/gameAssets/discoverGame/sounds/discover-failed.wav"
    );

    this.load.audio(
      "sfxThrow",
      "/assets/gameAssets/discoverGame/sounds/throw.wav"
    );

    this.load.audio(
      "sfxDrop",
      "/assets/gameAssets/discoverGame/sounds/drop.wav"
    );

    this.load.audio(
      "sfxCharge",
      "/assets/gameAssets/discoverGame/sounds/charge.wav"
    );

    this.load.audio(
      "sfxMachine",
      "/assets/gameAssets/discoverGame/sounds/machine.wav"
    );

    this.load.audio(
      "sfxPop",
      "/assets/gameAssets/discoverGame/sounds/pop.wav"
    );

    this.load.audio(
      "sfxStartMachine",
      "/assets/gameAssets/discoverGame/sounds/start-machine.wav"
    );

    this.load.audio(
      "sfxBossSuccess",
      "/assets/gameAssets/discoverGame/sounds/boss-success.wav"
    );

    this.load.audio(
      "sfxBossFaild",
      "/assets/gameAssets/discoverGame/sounds/boss-fail.wav"
    );


    this.load.audio(
      "sfxWon",
      "/assets/gameAssets/discoverGame/sounds/won.wav"
    );


    this.load.audio(
      "sfxWin",
      "/assets/gameAssets/discoverGame/sounds/win.wav"
    );


    this.load.image(
      "imgCollection",
      this.bossImage
        ? this.bossImage
        : "/assets/gameAssets/cookingGame/images/collection.png"
    );



    //*** Load Avatars ***//
    // load avatar data
    for (var key of Object.keys(this.avatar)) {
      if (this.avatar[key] && key != "color") {
        this.load.image(key, this.avatar[key]);
      }
    }

    // // load friend avatar data
    // for (var _key of Object.keys(this.friend.avatar)) {
    //   if (this.friend.avatar[_key] && _key != "color") {
    //     this.load.image(_key, this.friend.avatar[_key]);
    //   }
    // }

    // load avatar masks
    this.load.image("maskAngry", "/assets/gameAssets/common/masks/M0021.png");
    this.load.image("maskNice", "/assets/gameAssets/common/masks/M0012.png");
    this.load.image("maskFear", "/assets/gameAssets/common/masks/M0040.png");
    this.load.image("maskSad", "/assets/gameAssets/common/masks/M0031.png");
    this.load.image("maskHappy", "/assets/gameAssets/common/masks/M0013.png");
    this.load.image("maskMad", "/assets/gameAssets/common/masks/M0080.png");
    this.load.image("maskStun", "/assets/gameAssets/common/masks/M0060.png");
    this.load.image("maskWeary", "/assets/gameAssets/common/masks/M0030.png");
    this.load.image("maskYummy", "/assets/gameAssets/common/masks/M0050.png");
    this.load.image("maskGrimace", "/assets/gameAssets/common/masks/M0070.png");
    this.load.image("maskScold", "/assets/gameAssets/common/masks/M0080.png");
    this.load.image("maskWearyx", "/assets/gameAssets/common/masks/M0020.png");
    this.load.image("maskHappyx", "/assets/gameAssets/common/masks/M0011.png");
    this.load.image("maskAttack", "/assets/gameAssets/common/masks/M0090.png");

    this.load.image("maskBoss", "/assets/gameAssets/common/masks/M1000.png");
    this.load.image(
      "maskBossHurt",
      "/assets/gameAssets/common/masks/M1002.png"
    );
    this.load.image(
      "maskBossHappy",
      "/assets/gameAssets/common/masks/M1003.png"
    );
    this.load.image(
      "maskBossAttack",
      "/assets/gameAssets/common/masks/M1004.png"
    );

    this.load.image("clock", "/assets/clock.png");

    this.load.image("imgHamochiVideo", "/assets/gameAssets/common/tutorial/hamochi-video.png");
  }

  create() {
    this.registry.destroy();
    this.events.off();
    this.scene.stop("LoadingScene");
    this.scene.start("LandingScene", {
      avatar: this.avatar,
      name: this.name,
      questions: this.questions,
      friend: this.friend,
      env: this.env,
      noOfRounds: this.noOfRounds,
      noOfCuts: this.noOfCuts ?? 3,
      difficulty: this.difficulty,
      lampTime: this.lampTime,
      puzzle: this.puzzle,
    });
  }
}
