<template>
  <transition name="fade">
    <v-dialog v-model="confirm" max-width="300" class="rounded-xl" persistent>
      <v-card rounded="xl">
        <v-card-title
          class="text-h5 teal--text text--darken-3 d-flex justify-space-between pt-0 pe-0"
        >
          <v-spacer></v-spacer>
          <CloseBtn
            size="30"
            :callback="
              () => {
                $emit('exitConfirm');
              }
            "
          />
        </v-card-title>

        <v-card-text class="text-center">
          <v-icon
            v-if="settings.graphics && settings.graphics.mode == 'low'"
            x-large
            >mdi-alert</v-icon
          >
          <v-img
            v-else
            :src="require('@/assets/island/hamochConfirm.png')"
            max-width="200"
            class="mx-auto"
          ></v-img>
          <div v-if="type && type=='event_math'"
            class="orange lighten-5 orange--text text-center mx-3 my-3 px-5 py-5 rounded-xl text-h6"
          >
            {{ $t("string.recalculateScore") }}
        </div>
        <div v-else
            class="orange lighten-5 orange--text text-center mx-3 my-3 px-5 py-5 rounded-xl text-h6"
          >
            {{ $t("string.recalculateStar") }}
        </div>
          <div>
            <v-btn
              small
              dark
              color="orange"
              @click="recalculate()"
              :loading="api.isLoading"
            >
              {{ $t("action.recalculate") }}
              <v-icon right> mdi-reload </v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  props: ["type", "confirm"],
  computed: mapState({
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
  }),
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    gameApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  created() {
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isError = true;
      this.api.error = e;
      if (this.type == "event_book") {
        this.gameApi.params = {
          bookKey: this.$route.params.bookKey,
          mochiId: this.auth.Player.mochiId,
        };
        this.$api.fetch(this.gameApi);
      }else{
        this.api.isLoading = false;
        window.location.reload();
      }
    };
    this.api.callbackSuccess = () => {
      if (this.type == "event_book") {
        this.gameApi.params = {
          bookKey: this.$route.params.bookKey,
          mochiId: this.auth.Player.mochiId,
        };
        this.$api.fetch(this.gameApi);
      }else{
        window.location.reload();
        this.api.isLoading = false;
      }
    };
    this.api.params = {
      eventCode: this.$route.params.code,
      mochiId: this.auth.Player.mochiId,
    };

    this.gameApi.callbackReset = () => {
      this.gameApi.isLoading = true;
      this.gameApi.isError = false;
    };
    this.gameApi.callbackError = (e) => {
      this.gameApi.isLoading = false;
      this.gameApi.isError = true;
      this.gameApi.error = e;
      this.api.isLoading = false;
      window.location.reload();
    };
    this.gameApi.callbackSuccess = () => {
      this.api.isLoading = false;
      window.location.reload();
      this.gameApi.isLoading = false;
    };
  },
  methods: {
    recalculate() {
      if (this.type == "event_book" || this.type == "event_math" ) {
        this.api.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/patch/240212/recalculateTargetPlayerScoreBasedOnSubmissionLog/2/update";

        this.$api.fetch(this.api);
      }else{
        this.gameApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/patch/241219/updatePlayerBookTotalStarsByChapterTopStar/2/update";
        this.api.isLoading = true;
        this.gameApi.params = {
          bookKey: this.$route.params.bookKey,
          mochiId: this.auth.Player.mochiId,
        };
      this.$api.fetch(this.gameApi);
      }

    },
  },
  mounted() {
  },
};
</script>
