import { Scene } from "phaser";
import store from "@/store";
import router from "@/router";

export default class GameScene extends Scene {
  constructor() {
    super({ key: "GameScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.autoPlay = data.autoPlay;
    this.steps = data.steps;
    this.currentStep = data.currentStep;
    this.subjectLang = data.subjectLang;
    this.isEvent = data.isEvent;
    this.isDemo = data.isDemo;
  }

  create() {
    this.simpleClickSound = this.sound.add("sfxSimpleClick");

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.4)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.add
      .text(240, 230, "Under Development", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 18,
        color: "#000",
      })
      .setOrigin(0.5);

    this.add
      .text(240, 270, "Game Scene", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 32,
        color: "#000",
      })
      .setOrigin(0.5);

    this.add
      .text(240, 300, "End game with condition", {
        fontFamily: "Inter, sans-serif",
        fontSize: 16,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(1);

    this.add
      .image(240, 370, "imgOkBtn")
      .setScale(0.6)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => this.nextStep("success"), this);

    this.add
      .text(235, 370, "Success", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 28,
        color: "#000",
      })
      .setOrigin(0.5);

    this.add
      .image(240, 440, "imgOkBtn")
      .setScale(0.6)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => this.nextStep("failed"), this);

    this.add
      .text(235, 440, "Failed", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 28,
        color: "#000",
      })
      .setOrigin(0.5);
  }

  nextStep(conditionKey) {
    if (this.audio) {
      this.audio.stop();
      this.audio = null;
    }
    if (this.currentStep.actionType == "restartChapter") {
      this.sound.stopAll();
      this.scene.stop();
      router.push({
        name: this.isEvent
          ? "PageEventABCBookFailed"
          : this.isDemo
          ? "PageABCBookFailedDemo"
          : "PageABCBookFailed",
        params: {
          bookKey: this.steps[0].Chapter.bookKey,
          chapterKey: this.steps[0].Chapter.key,
        },
      });
    } else {
      let nextStep = this.steps.findLast(
        (step) => step.order === this.currentStep.order + 1
      );

      if (nextStep) {
        if (nextStep.conditionKey) {
          nextStep = this.steps.findLast(
            (step) =>
              step.order === this.currentStep.order + 1 &&
              step.conditionKey == conditionKey
          );
        }

        let sceneObject = {
          avatar: this.avatar,
          autoPlay: this.autoPlay,
          steps: this.steps,
          currentStep: nextStep,
          subjectLang: this.subjectLang,
          isEvent: this.isEvent,
          isDemo: this.isDemo,
        };

        if (
          nextStep.actionType == "goPage" ||
          nextStep.actionType == "restartChapter"
        )
          this.scene.start("StoryScene", sceneObject);

        if (nextStep.actionType == "goMap")
          this.scene.start("MapScene", sceneObject);

        if (nextStep.actionType == "goGame")
          this.scene.start("GameScene", sceneObject);

        if (nextStep.actionType == "goItem")
          this.scene.start("ItemScene", sceneObject);

        if (nextStep.actionType == "goQuestion") {
          sceneObject.currentProggressStep = 0;
          this.scene.start("QuizScene", sceneObject);
        }

        if (nextStep.actionType == "goReflection")
          this.scene.start("NilamScene", sceneObject);
      } else {
        this.sound.stopAll();
        this.scene.stop();
        router.push({
          name: this.isEvent
            ? "PageEventABCBookCompleted"
            : this.isDemo
            ? "PageABCBookCompletedDemo"
            : "PageABCBookCompleted",
          params: {
            bookKey: this.steps[0].Chapter.bookKey,
            chapterKey: this.steps[0].Chapter.key,
          },
        });
      }
    }
  }

  prevStep(conditionKey) {
    if (this.audio) {
      this.audio.stop();
      this.audio = null;
    }
    let nextStep = this.steps.findLast(
      (step) => step.order === this.currentStep.order - 1
    );

    if (nextStep) {
      if (nextStep.conditionKey) {
        nextStep = this.steps.findLast(
          (step) =>
            step.order === this.currentStep.order - 1 &&
            step.conditionKey == conditionKey
        );
      }

      let sceneObject = {
        avatar: this.avatar,
        autoPlay: this.autoPlay,
        steps: this.steps,
        currentStep: nextStep,
        subjectLang: this.subjectLang,
        isEvent: this.isEvent,
        isDemo: this.isDemo,
      };

      if (nextStep.actionType == "goPage")
        this.scene.start("StoryScene", sceneObject);

      if (nextStep.actionType == "goMap")
        this.scene.start("MapScene", sceneObject);

      if (nextStep.actionType == "goGame")
        this.scene.start("GameScene", sceneObject);

      if (nextStep.actionType == "goItem")
        this.scene.start("ItemScene", sceneObject);

      if (nextStep.actionType == "goQuestion") {
        sceneObject.currentProggressStep = nextStep.Page.length - 1;
        this.scene.start("QuizScene", sceneObject);
      }

      if (nextStep.actionType == "goReflection")
        this.scene.start("NilamScene", sceneObject);
    } else {
      this.sound.stopAll();
      this.scene.stop();
      router.push({
        name: this.isEvent
          ? "PageEventABCBookChapters"
          : this.isDemo
          ? "PageABCBookChaptersDemo"
          : "PageABCBookChapters",
        params: { bookKey: this.steps[0].Chapter.bookKey },
      });
    }
  }

  clickClose() {
    this.sound.stopAll();
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.stop();
    router.push({
      name: this.isEvent
        ? "PageEventABCBookChapters"
        : this.isDemo
        ? "PageABCBookChaptersDemo"
        : "PageABCBookChapters",
      params: { bookKey: this.steps[0].Chapter.bookKey },
    });
  }
  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: this.scene.key,
      bookKey: this.steps[0].Chapter.bookKey,
      isEvent: this.isEvent,
      isDemo: this.isDemo,
    });
  }
  update() {}
}
