<template>
  <div class="text-caption">{{ message }} {{ countdown }}</div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    countdown() {
      return this.remainingTime;
    },
  }),
  props: ["message", "endTime"],
  data: () => ({
    remainingTime: "",
    interval: null,
  }),
  created() {
    //
  },
  mounted() {
    this.updateCountdown();
    this.interval = setInterval(() => {
      this.updateCountdown();
    }, 1000);
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    updateCountdown() {
      const end = this.$moment(this.endTime);
      const now = this.$moment();
      const duration = this.$moment.duration(end.diff(now));
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      this.remainingTime = `${days}D ${hours}H ${minutes}M ${seconds}S`;
    },
  },
};
</script>