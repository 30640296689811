import { Scene } from "phaser";
import store from "@/store";
// import router from "@/router";
import i18n from "@/plugins/i18n";
// import _ from "lodash";
// import Phaser from "phaser";

export default class IntroScene extends Scene {
  constructor() {
    super({ key: "IntroScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.round = data.round;
    this.qCount = data.qCount;
    this.questions = data.questions;
    this.score = data.score ? data.score : 0;
    this.friend = data.friend;
    this.forceFriend = data.forceFriend;
    this.countCorrect = data.countCorrect;
    this.isCorrectAnswer = data.isCorrectAnswer;
    this.difficulty = data.difficulty;
    this.noOfRounds = data.noOfRounds;
    this.noOfCuts = data.noOfCuts;
    this.bossImage = data.bossImage;
    this.currentCampTileX = data.currentCampTileX;
    this.nextCampTileX = data.nextCampTileX;
    this.totalBones = data.totalBones;
    this.collectedBones = data.collectedBones;
    this.collectedSkull = data.collectedSkull;
    this.lampTime = data.lampTime;
  }

  playerAvatarObject = [];

  create() {
    this.registerSounds();
    this.registerAnims();

    //* setup the Background  *//

    this.backImg = this.add
      .image(0, -384, "imgBackground")
      .setDisplaySize(480, 854)
      .setOrigin(0)
      .setDepth(4);

    // this.add.image(0, 0, "imgSite").setOrigin(0).setDepth(3);
    this.add.image(0, 0, "imgDarkX").setOrigin(0).setDepth(4);

    this.topBoardBack = this.add
      .image(0, 280, "imgTopBack")
      .setOrigin(0)
      .setAlpha(0);
    this.topBoard = this.add
      .image(0, 280, "imgTop")
      .setOrigin(0)
      .setDepth(4)
      .setAlpha(0);

    this.boneBoard = this.add
      .image(200, 280 + 60, "imgBoneBrd")
      .setOrigin(0)
      .setDepth(4)
      .setAlpha(0);

    this.BoneText = this.add
      .text(300, 280 + 100, this.collectedBones + "/" + this.totalBones, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 30,
        color: "#fff",
      })
      .setOrigin(0, 0.5)
      .setAlpha(0)
      .setDepth(4);

    this.boneIV = this.add
      .image(230, 280 + 75, "imgBone4")
      .setScale(0.22)
      .setOrigin(0)
      .setAlpha(0)
      .setDepth(4);
    this.boneI = this.add
      .image(218, 280 + 92, "imgBone1")
      .setScale(0.17)
      .setOrigin(0)
      .setAlpha(0)
      .setDepth(4);

    this.npcImg = this.add
      .image(120, 390, "imgNPC")
      .setOrigin(0, 1)
      .setDepth(4)
      .setScale(0.4);

    this.tweens.add({
      targets: this.npcImg,
      scaleY: 0.42,
      scaleX: 0.39,
      duration: 1500,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    this.npcSpeech = this.add
      .image(180, 280, "imgSpeech")
      .setOrigin(0.5, 1)
      .setDepth(4)
      .setScale(0);
    this.playerSpeech = this.add
      .image(335, 280, "imgSpeech")
      .setOrigin(0.5, 1)
      .setDepth(4)
      .setScale(0);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.5)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this)
      .setDepth(4);

    this.scoreBoard = this.add
      .image(95, 280 + 40, "imgScorePanel")
      .setScale(0.38, 0.32)
      .setDepth(4)
      .setAlpha(0);
    this.roundBoard = this.add
      .image(73, 280 + 80, "imgScorePanel")
      .setScale(0.25)
      .setDepth(4)
      .setAlpha(0);

    this.scoreText = this.add
      .text(
        35,
        280 + 40,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 22,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5)
      .setDepth(4)
      .setAlpha(0);

    this.roundText = this.add
      .text(
        35,
        280 + 82,
        i18n.t("miniGames.general.round") + " " + this.round,
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 15,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5)
      .setDepth(4)
      .setAlpha(0);

    //* tiles setup later put it to function *//

    this.tiles = [];
    this.currentCampTileX = Math.floor(Math.random() * 5);
    this.currentCampTileY = 4;

    this.nextCampTileX = Math.floor(Math.random() * 5);
    this.nextCampTileY = 10;

    this.currentTileX = 3;
    this.currentTileY = 0;

    for (let i = 0; i < 11; i++) {
      this.tiles[i] = [];
      for (let j = 0; j < 5; j++) {
        if (
          (i == this.currentCampTileY && j == this.currentCampTileX) ||
          (i == this.nextCampTileY && j == this.nextCampTileX)
        ) {
          this.tiles[i][j] = this.add
            .sprite(j * 96, 278 + i * 96, "sptCamp")
            .setOrigin(0)
            .setDepth(3);

          this.tiles[i][j].state = 1;
          this.tiles[i][j].isCamp = true;
          if (i == this.currentCampTileY && j == this.currentCampTileX) {
            this.tiles[i][j].setFrame(1);
          }
        } else {
          this.tiles[i][j] = this.add
            .sprite(j * 96, 278 + i * 96, "sptTile")
            .setOrigin(0);

          if (j == this.currentTileX && i < this.currentCampTileY) {
            this.tiles[i][j].state = 1;
            this.tiles[i][j].setFrame(1);
          }

          if (
            this.currentTileX > this.currentCampTileX &&
            j <= this.currentTileX &&
            this.currentCampTileX < j &&
            this.currentCampTileY == i
          ) {
            this.tiles[i][j].state = 1;
            this.tiles[i][j].setFrame(1);
          }

          if (
            this.currentTileX < this.currentCampTileX &&
            j >= this.currentTileX &&
            this.currentCampTileX > j &&
            this.currentCampTileY == i
          ) {
            this.tiles[i][j].state = 1;
            this.tiles[i][j].setFrame(1);
          }
        }
      }
    }

    this.tiles[this.currentTileY][this.currentTileX].setFrame(1);

    this.playerPositionX = 48 + 96 * (this.currentTileX + 3);
    this.playerPositionY = 326 + 96 * this.currentTileY;
    this.playerOpacity = 1;

    this.lapm = this.add
      .image(this.playerPositionX, this.playerPositionY - 5, "imgLamp")
      .setOrigin(0.5)
      .setScale(0.2)
      .setDepth(7)
      .setAlpha(0);

    this.axe = this.add
      .image(this.playerPositionX - 5, this.playerPositionY, "imgAxe")
      .setOrigin(0.5)
      .setScale(0.18)
      .setDepth(6)
      .setAlpha(0);

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(this.playerPositionX, this.playerPositionY, key)
              .setScale(0.18)
              // .setCollideWorldBounds(true)
              .setDepth(6)
              .setAlpha(this.playerOpacity)
          );
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(this.playerPositionX, this.playerPositionY, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.18)
              .setAlpha(0.3)
              // .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color" && key != "right" && key != "left") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(this.playerPositionX, this.playerPositionY, key)
              .setScale(0.18)
              // .setCollideWorldBounds(true)
              .setDepth(6)
              .setAlpha(this.playerOpacity)
          );
        }
      }
    }

    this.playerAvatarObject.forEach((element) => {
      element.setFlipX(true);
    });
    this.lapm.setFlipX(true);
    this.axe.setFlipX(true);

    this.ideaBtn = this.add
      .sprite(this.playerPositionX, this.playerPositionY - 80, "sptIdea")
      .setOrigin(0.5)
      .setDepth(7)
      .setScale(0.8)
      .setAlpha(0)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.goQuestion, this);

    this.ideaBtn.play("animIdea");

    this.playerTween = this.tweens.add({
      targets: this.playerAvatarObject,
      scaleY: 0.19,
      scaleX: 0.175,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    this.playerTweenz = this.tweens.add({
      targets: [this.lapm, this.axe],
      scaleY: 0.19,
      scaleX: 0.19,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    this.skipBack = this.add
      .image(240, 810, "imgOptionBtn")
      .setScale(0.25, 0.3)
      .setDepth(9)
      .setAlpha(0)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.skipScene, this);

    this.skipTxt = this.add
      .text(240, 810, "Skip", {
        fontSize: "24px",
        fill: "#000",
        fontFamily: "Sassoon, sans-serif",
      })
      .setAlpha(0)
      .setDepth(9)
      .setOrigin(0.5);

    this.startScene();
  }

  update() {
    this.settingBtn.y = 40 + this.cameras.main.scrollY;

    if (
      !this.tiles[this.currentCampTileY][this.currentCampTileX].isDone &&
      this.currentTileX == this.currentCampTileX &&
      this.currentTileY == this.currentCampTileY
    ) {
      this.cameras.main.pan(240, 708, 500, "Linear", true);
      this.tiles[this.currentCampTileY][this.currentCampTileX].isDone = true;
      this.ideaBtn.setAlpha(1);
      this.tweens.add({
        targets: [
          this.topBoard,
          this.topBoardBack,
          this.scoreText,
          this.roundText,
          this.roundBoard,
          this.scoreBoard,
          this.boneBoard,
          this.BoneText,
          this.boneI,
          this.boneIV,
        ],
        y: "+=0",
        duration: 500,
        delay: 500,
        ease: "Sine.easeInOut",
        onStart: () => {
          this.backImg.setAlpha(0);
          this.npcImg.setAlpha(0);
          this.topBoard.setAlpha(1);
          this.topBoardBack.setAlpha(1);
          this.scoreText.setAlpha(1);
          this.roundText.setAlpha(1);
          this.roundBoard.setAlpha(1);
          this.scoreBoard.setAlpha(1);
          this.boneBoard.setAlpha(1);
          this.BoneText.setAlpha(1);
          this.boneI.setAlpha(1);
          this.boneIV.setAlpha(1);
        },
      });
    }

    if (this.playerAvatarObject[0]) {
      this.playerPositionX = this.playerAvatarObject[0].x;
      this.playerPositionY = this.playerAvatarObject[0].y;
    }

    if (this.playerMask) {
      this.playerMask.x = this.playerPositionX;
      this.playerMask.y = this.playerPositionY;
    }

    if (this.lapm) {
      this.lapm.x = this.playerPositionX;
      this.lapm.y = this.playerPositionY - 5;
    }

    if (this.ideaBtn) {
      this.ideaBtn.x = this.playerPositionX;
      this.ideaBtn.y = this.playerPositionY - 80;
    }

    if (this.axe && !this.isPlayerDigging) {
      this.axe.x = this.playerPositionX;
      this.axe.y = this.playerPositionY;
    }

    if (this.dark) {
      this.dark.x = this.playerPositionX;
      this.dark.y = this.playerPositionY;
    }
  }

  skipScene() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    if (this.playerSpeech) this.playerSpeech.destroy();
    if (this.playerSpeechContent) this.playerSpeechContent.destroy();
    if (this.npcSpeech) this.npcSpeech.destroy();
    if (this.npcSpeechContent) this.npcSpeechContent.destroy();
    this.skip = true;
    this.skipBack.setAlpha(0);
    this.skipTxt.setAlpha(0);
    this.tiles[this.currentCampTileY][this.currentCampTileX].isDone;
    this.currentTileX = this.currentCampTileX;
    this.currentTileY = this.currentCampTileY;
    this.playerXmove.stop();
    this.playerTweenX.stop();
    this.tweens.add({
      targets: this.playerAvatarObject,
      y: 96 + 230 + 96 * this.currentTileY,
      x: 48 + 96 * this.currentTileX,
      duration: 10,
      ease: "Sine.easeInOut",
    });
  }

  startScene() {
    this.playerXmove = this.tweens.add({
      targets: this.playerAvatarObject,
      x: "-=280",
      duration: 3000,
      ease: "Sine.easeInOut",
      onStart: () => {
        this.skipBack.setAlpha(1);
        this.skipTxt.setAlpha(1);
        this.tweens.add({
          targets: [this.lapm, this.axe],
          scaleY: 0.19,
          scaleX: 0.19,
          duration: 2000,
          ease: "Sine.easeInOut",
        });
        this.playerTweenX = this.tweens.add({
          targets: this.playerAvatarObject,
          scaleY: "+=0.01",
          scaleX: "-=0.01",
          duration: 200,
          ease: "Sine.easeInOut",
          repeat: -1,
          yoyo: true,
        });
      },
      onComplete: () => {
        this.playerTweenX.stop();
        this.playerSpeechContent = this.add
          .image(335, 280, "imgExclMark")
          .setOrigin(0.5, 1)
          .setDepth(4)
          .setScale(0);
        if (!this.skip) {
          this.tweens.add({
            targets: [this.playerSpeech, this.playerSpeechContent],
            scaleY: 0.4,
            scaleX: 0.4,
            duration: 400,
            ease: "Sine.easeInOut",
            onStart: () => {
              this.sfxPop.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.sfxPop.play();
            },
            onComplete: () => {
              this.npcSpeechContent = this.add
                .image(180, 280, "imgDino")
                .setOrigin(0.5, 1)
                .setDepth(4)
                .setScale(0);
              if (!this.skip) {
                this.tweens.add({
                  targets: [this.npcSpeech, this.npcSpeechContent],
                  scaleY: 0.4,
                  scaleX: 0.4,
                  duration: 400,
                  delay: 500,
                  ease: "Sine.easeInOut",
                  onStart: () => {
                    this.sfxPop.setVolume(
                      store.state.settings.data.audio.sfx *
                        store.state.settings.data.audio.master
                    );
                    this.sfxPop.play();
                  },
                  onComplete: () => {
                    if (!this.skip) {
                      this.tweens.add({
                        targets: [this.playerSpeech, this.playerSpeechContent],
                        scaleY: 0,
                        scaleX: 0,
                        duration: 200,
                        delay: 500,
                        ease: "Sine.easeInOut",
                        onComplete: () => {
                          this.playerSpeechContent.destroy();
                          this.playerSpeechContent = this.add
                            .image(335, 280, "imgLampX")
                            .setOrigin(0.5, 1)
                            .setDepth(4)
                            .setScale(0);
                          if (!this.skip) {
                            this.tweens.add({
                              targets: [
                                this.playerSpeech,
                                this.playerSpeechContent,
                              ],
                              scaleY: 0.4,
                              scaleX: 0.4,
                              duration: 400,
                              delay: 500,
                              ease: "Sine.easeInOut",
                              onStart: () => {
                                this.sfxPop.setVolume(
                                  store.state.settings.data.audio.sfx *
                                    store.state.settings.data.audio.master
                                );
                                this.sfxPop.play();
                              },
                              onComplete: () => {
                                if (!this.skip) {
                                  this.tweens.add({
                                    targets: [
                                      this.npcSpeech,
                                      this.npcSpeechContent,
                                    ],
                                    scaleY: 0,
                                    scaleX: 0,
                                    duration: 200,
                                    delay: 200,
                                    ease: "Sine.easeInOut",
                                    onComplete: () => {
                                      if (!this.skip) {
                                        this.tweens.add({
                                          targets: [this.lapm, this.axe],
                                          alpha: 1,
                                          duration: 200,
                                          delay: 200,
                                          ease: "Sine.easeInOut",
                                          onComplete: () => {
                                            this.npcSpeechContent = this.add
                                              .image(180, 280, "imgBulb")
                                              .setOrigin(0.5, 1)
                                              .setDepth(4)
                                              .setScale(0);
                                            if (!this.skip) {
                                              this.tweens.add({
                                                targets: [
                                                  this.npcSpeech,
                                                  this.npcSpeechContent,
                                                ],
                                                scaleY: 0.4,
                                                scaleX: 0.4,
                                                duration: 400,
                                                delay: 500,
                                                ease: "Sine.easeInOut",
                                                onStart: () => {
                                                  this.sfxPop.setVolume(
                                                    store.state.settings.data
                                                      .audio.sfx *
                                                      store.state.settings.data
                                                        .audio.master
                                                  );
                                                  this.sfxPop.play();
                                                },
                                                onComplete: () => {
                                                  if (!this.skip) {
                                                    this.tweens.add({
                                                      targets: [
                                                        this.playerSpeech,
                                                        this
                                                          .playerSpeechContent,
                                                      ],
                                                      scaleY: 0,
                                                      scaleX: 0,
                                                      duration: 200,
                                                      delay: 500,
                                                      ease: "Sine.easeInOut",
                                                      onComplete: () => {
                                                        this.playerSpeechContent.destroy();
                                                        this.playerSpeechContent =
                                                          this.add
                                                            .image(
                                                              335,
                                                              280,
                                                              "imgHeart"
                                                            )
                                                            .setOrigin(0.5, 1)
                                                            .setDepth(4)
                                                            .setScale(0);
                                                        if (!this.skip) {
                                                          this.tweens.add({
                                                            targets: [
                                                              this.playerSpeech,
                                                              this
                                                                .playerSpeechContent,
                                                            ],
                                                            scaleY: 0.4,
                                                            scaleX: 0.4,
                                                            duration: 400,
                                                            delay: 500,
                                                            ease: "Sine.easeInOut",
                                                            onStart: () => {
                                                              this.sfxPop.setVolume(
                                                                store.state
                                                                  .settings.data
                                                                  .audio.sfx *
                                                                  store.state
                                                                    .settings
                                                                    .data.audio
                                                                    .master
                                                              );
                                                              this.sfxPop.play();
                                                            },
                                                            onComplete: () => {
                                                              if (!this.skip) {
                                                                this.tweens.add(
                                                                  {
                                                                    targets: [
                                                                      this
                                                                        .playerSpeech,
                                                                      this
                                                                        .playerSpeechContent,
                                                                      this
                                                                        .npcSpeech,
                                                                      this
                                                                        .npcSpeechContent,
                                                                    ],
                                                                    scaleY: 0,
                                                                    scaleX: 0,
                                                                    duration: 200,
                                                                    delay: 500,
                                                                    ease: "Sine.easeInOut",
                                                                    onComplete:
                                                                      () => {
                                                                        if (
                                                                          !this
                                                                            .skip
                                                                        ) {
                                                                          this.moveToCamp();
                                                                        }
                                                                      },
                                                                  }
                                                                );
                                                              }
                                                            },
                                                          });
                                                        }
                                                      },
                                                    });
                                                  }
                                                },
                                              });
                                            }
                                          },
                                        });
                                      }
                                    },
                                  });
                                }
                              },
                            });
                          }
                        },
                      });
                    }
                  },
                });
              }
            },
          });
        }
      },
    });
  }

  moveToCamp() {
    if (this.playerSpeech) this.playerSpeech.destroy();
    if (this.playerSpeechContent) this.playerSpeechContent.destroy();
    if (this.npcSpeech) this.npcSpeech.destroy();
    if (this.npcSpeechContent) this.npcSpeechContent.destroy();
    this.skip = true;
    this.skipBack.setAlpha(0);
    this.skipTxt.setAlpha(0);
    if (this.currentTileY < this.currentCampTileY) {
      this.tweens.add({
        targets: this.playerAvatarObject,
        y: "+=96",
        duration: 500,
        ease: "Sine.easeInOut",
        onStart: () => {
          this.playerTweenX = this.tweens.add({
            targets: this.playerAvatarObject,
            scaleY: "+=0.01",
            scaleX: "-=0.01",
            duration: 200,
            ease: "Sine.easeInOut",
            repeat: -1,
            yoyo: true,
          });
        },
        onComplete: () => {
          this.currentTileY += 1;
          this.playerTweenX.stop();
          this.moveToCamp();
        },
      });
    }

    if (this.currentTileY == this.currentCampTileY) {
      if (this.currentTileX < this.currentCampTileX) {
        this.playerAvatarObject.forEach((element) => {
          element.setFlipX(false);
        });
        this.lapm.setFlipX(false);
        this.axe.setFlipX(false);
        this.tweens.add({
          targets: this.playerAvatarObject,
          x: "+=96",
          duration: 500,
          ease: "Sine.easeInOut",
          onStart: () => {
            this.playerTweenX = this.tweens.add({
              targets: this.playerAvatarObject,
              scaleY: "+=0.01",
              scaleX: "-=0.01",
              duration: 200,
              ease: "Sine.easeInOut",
              repeat: -1,
              yoyo: true,
            });
          },
          onComplete: () => {
            this.currentTileX += 1;
            this.playerTweenX.stop();
            this.moveToCamp();
          },
        });
      }
      if (this.currentTileX > this.currentCampTileX) {
        this.playerAvatarObject.forEach((element) => {
          element.setFlipX(true);
        });
        this.lapm.setFlipX(true);
        this.axe.setFlipX(true);
        this.tweens.add({
          targets: this.playerAvatarObject,
          x: "-=96",
          duration: 500,
          ease: "Sine.easeInOut",
          onStart: () => {
            this.playerTweenX = this.tweens.add({
              targets: this.playerAvatarObject,
              scaleY: "+=0.01",
              scaleX: "-=0.01",
              duration: 200,
              ease: "Sine.easeInOut",
              repeat: -1,
              yoyo: true,
            });
          },
          onComplete: () => {
            this.currentTileX -= 1;
            this.playerTweenX.stop();
            this.moveToCamp();
          },
        });
      }
      if (this.currentCampTileX == this.currentTileX) {
        this.sfxDiscoverSuccess.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxDiscoverSuccess.play();
      }
    }
  }

  registerSounds() {
    this.sfxSimpleClick = this.sound.add("sfxSimpleClick");
    this.sfxPop = this.sound.add("sfxPop");
    this.sfxDiscoverSuccess = this.sound.add("sfxDiscoverSuccess");
  }

  registerAnims() {
    this.anims.create({
      key: "animIdea",
      frames: this.anims.generateFrameNumbers("sptIdea", {
        frames: [0, 1],
      }),
      repeat: -1,
      frameRate: 5,
    });
  }

  goQuestion() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();

    this.playerAvatarObject = [];

    this.registry.destroy();
    this.events.off();
    this.scene.stop("IntroScene");

    this.scene.start("QuestionScene", {
      avatar: this.avatar,
      name: this.name,
      round: this.round,
      score: this.score,
      questions: this.questions,
      friend: this.friend,
      difficulty: this.difficulty,
      countCorrect: this.countCorrect,
      noOfRounds: this.noOfRounds,
      noOfCuts: this.noOfCuts,
      bossImage: this.bossImage,
      currentCampTileX: this.currentCampTileX,
      nextCampTileX: this.nextCampTileX,
      totalBones: this.totalBones,
      collectedBones: this.collectedBones,
      collectedSkull: this.collectedSkull,
      lampTime: this.lampTime,
    });
  }

  clickSetting() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "IntroScene",
    });
  }
}
