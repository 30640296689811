import { Scene } from "phaser";
import i18n from "@/plugins/i18n";
import store from "@/store";

export default class LandingScene extends Scene {
  constructor() {
    super({ key: "LandingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.questions = data.questions;
    this.friend = data.friend;
    this.bossRound = data.bossRound;
    this.env = data.env;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
  }

  create() {
    this._backgroundImage = this.add
      .image(0, 0, "imgBackground")
      .setDisplaySize(480, 854)
      .setOrigin(0);
    this._gameTitle = this.add.image(240, 320, "imgGameTitle").setScale(0.8);

    this._continueBtn = this.add.image(240, 450, "imgOptionBtn").setScale(0.35);
    this._continueTxt = this.add.text(
      240,
      450,
      i18n.t("miniGames.general.continue"),
      {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
        align: "center",
      }
    );

    this.tweens.add({
      targets: this._continueBtn,
      scaleX: 0.38,
      scaleY: 0.38,
      ease: "Linear",
      duration: 500,
      yoyo: true,
      repeat: -1,
    });

    this.tweens.add({
      targets: this._continueTxt,
      scaleX: 1.1,
      scaleY: 1.1,
      ease: "Linear",
      duration: 500,
      yoyo: true,
      repeat: -1,
    });

    this.bgSound = this.sound.add("bgm");
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
    this.bgSound.loop = true;

    this._continueTxt.on(
      "pointerdown",
      () => {
        this._backgroundImage.visible = false;
        this._gameTitle.visible = false;
        this._continueBtn.visible = false;
        this._continueTxt.visible = false;
        
        this.bgSound.play();

        this.scene.launch("PlayScene", {
          avatar: this.avatar,
          round: 1,
          questions: this.questions,
          friend: this.friend,
          score: 0,
          bossRound: this.bossRound,
          forceFriend: false,
          noOfRounds: this.noOfRounds,
          difficulty: this.difficulty,
          countCorrect: 0,
          avatarX: 220,
        });
      },
      this
    );
    this._continueTxt
      .setOrigin(0.5)
      .setPadding(10)
      .setInteractive({ useHandCursor: true })
      .setFixedSize(200, 40);
  }

  update() {
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
  }
}
