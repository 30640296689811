var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body"},[_c('v-container',{staticClass:"app-body"},[(_vm.api.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.api}}):_vm._e(),(_vm.bookInfoDialog)?_c('BookInfoDialog',{attrs:{"callbackClose":() => (_vm.bookInfoDialog = false),"showDialog":true,"data":_vm.bookData}}):_vm._e(),(_vm.chapterErrorDialog)?_c('InfoDialog',{attrs:{"callbackClose":() => (_vm.chapterErrorDialog = false),"showDialog":true,"message":_vm.chapterErrorType == 1
              ? _vm.$t('string.chapterStarMsg1')
              : _vm.$t('string.chapterStarMsg2'),"image":require('@/assets/island/hamochi_sad.png'),"totalStar":_vm.book.PlayerBook && _vm.book.PlayerBook.length > 0
              ? _vm.book.PlayerBook[0].totalStar
              : 0,"unlockStar":_vm.unlockStar,"showStars":_vm.chapterErrorType == 1}}):_vm._e(),(_vm.book)?_c('div',{staticClass:"pe-2 ps-3 app-title text-h5"},[_vm._v(" "+_vm._s(_vm.$t("string.storyBook"))+" "),_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"40","callback":_vm.exit}})],1):_vm._e(),_c('div',{staticClass:"px-5"},[(_vm.book && _vm.book.translatableName)?_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(JSON.parse(_vm.book.translatableName)[_vm.subjectLang])+" ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between py-2 mb-4 align-center"},[_c('v-btn',{attrs:{"small":"","color":"light-blue","dark":""},on:{"click":function($event){_vm.bookInfoDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("string.info"))+" "),_c('v-icon',{staticClass:"ms-1",attrs:{"small":""}},[_vm._v(" mdi-information-outline ")])],1),_c('div',{staticClass:"d-flex align-center ticket-card"},[_c('v-icon',{attrs:{"color":"amber"}},[_vm._v(" mdi-star ")]),_c('div',{staticClass:"text-h5 ps-2 game-title"},[_vm._v(" "+_vm._s(_vm.book.PlayerBook && _vm.book.PlayerBook.length > 0 ? _vm.book.PlayerBook[0].totalStar : 0)+" ")])],1)],1),_c('div',[_c('div',{staticClass:"text-h5 abc-title",attrs:{"id":"QUEST"}},[_vm._v(" "+_vm._s(_vm.$t("string.quest"))+" ")]),_c('v-card',{staticClass:"my-2 list-card",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex flex-no-wrap align-center"},[_c('div',{staticClass:"text-center mx-4 mt-2"},[_c('div',[_c('v-img',{attrs:{"contain":"","max-height":"60","max-width":"60","src":_vm.rwd.img}})],1)]),_c('div',{staticClass:"pa-2",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.rwd.name)+" ")]),_c('div',{staticClass:"d-flex flex-no-wrap align-center"},[_c('div',{staticClass:"pe-3",staticStyle:{"width":"100%"}},[_c('v-progress-linear',{attrs:{"value":_vm.rwd.progress,"color":"green","height":"20","rounded":""}},[_vm._v(" "+_vm._s(_vm.rwd.numerator)+" / "+_vm._s(_vm.rwd.denominator))])],1),(
                        _vm.rwd.progress == 100
                      )?_c('v-btn',{staticClass:"text-h6 constant-tilt-shake",attrs:{"color":"yellow"},on:{"click":function($event){return _vm.claimQuest()}}},[_vm._v(" "+_vm._s(_vm.$t("action.claim"))+" ")]):_c('v-btn',{attrs:{"color":"grey","dark":"","depressed":""}},[_c('v-icon',[_vm._v(" mdi-lock ")])],1)],1)])])])],1),_c('v-divider',{staticClass:"my-3"}),_c('Loader',{attrs:{"isLoading":_vm.api.isLoading}}),(_vm.chapters.length > 0)?_c('div',[_c('div',{staticClass:"text-h5 my-4 abc-title"},[_vm._v(" "+_vm._s(_vm.$t("string.chapters"))+" ")]),_vm._l((_vm.chapters),function(item,i){return _c('v-card',{key:i + 'all',staticClass:"my-2 list-card",attrs:{"id":'CH' + item.sort,"flat":""},on:{"click":function($event){return _vm.clickCard(item, i)}}},[_c('div',{staticClass:"d-flex flex-no-wrap"},[_c('div',{staticClass:"d-flex flex-column justify-space-between pa-2",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex align-center"},[(
                        item.PlayerChapter.length > 0 &&
                        item.PlayerChapter[0].isRead
                      )?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',[_vm._v("mdi-circle-outline")]),_c('div',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(JSON.parse(item.translatableSection)[_vm.$_getLocale()] ? JSON.parse(item.translatableSection)[ _vm.$_getLocale() ] : JSON.parse(item.translatableSection)[_vm.subjectLang])+" ")]),_c('v-spacer'),(item.PlayerChapter.length > 0)?_c('div',_vm._l((item.totalCollectableStar),function(star,i){return _c('v-icon',{key:i,attrs:{"color":"amber"}},[_vm._v(_vm._s(item.PlayerChapter[0].topStar > i ? "mdi-star" : "mdi-star-outline"))])}),1):(item.minStarToUnlock)?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',[_vm._v("mdi-star")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.minStarToUnlock))])],1):_vm._e()],1),_c('div',{staticClass:"d-flex align-center mt-3"},[_c('div',{staticClass:"text-h5 mx-2"},[_vm._v(" "+_vm._s(item.PlayerChapter.length > 0 ? JSON.parse(item.translatableName)[_vm.subjectLang] : "???")+" ")]),_c('v-divider'),(_vm.book.type == 'premium' && !_vm.isSubscribed && i > 0)?_c('v-btn',{staticClass:"ml-1",attrs:{"color":"yellow","light":"","depressed":""}},[_c('v-icon',{staticClass:"ms-1"},[_vm._v(" mdi-crown ")])],1):(item.PlayerChapter.length > 0)?_c('v-btn',{staticClass:"ml-1 text-h6",attrs:{"color":"blue","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("string.read"))+" ")]):(
                        (_vm.book.PlayerBook[0].totalStar >=
                          item.minStarToUnlock &&
                          i == 0) ||
                        (_vm.book.PlayerBook[0].totalStar >=
                          item.minStarToUnlock &&
                          i > 0 &&
                          _vm.chapters[i - 1].PlayerChapter.length > 0 &&
                          _vm.chapters[i - 1].PlayerChapter[0].isRead)
                      )?_c('v-btn',{staticClass:"ml-1 constant-tilt-shake",attrs:{"color":"green","dark":"","loading":_vm.api.isLoading && _vm.selectedItem == item}},[_c('v-icon',[_vm._v(" mdi-lock ")])],1):_c('v-btn',{attrs:{"depressed":"","color":"grey","dark":""}},[_c('v-icon',[_vm._v(" mdi-lock ")])],1)],1)])])])})],2):_vm._e(),_c('div',{staticClass:"d-flex justify-right mt-6"},[_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"small":"","dark":"","color":"black"},on:{"click":_vm.resetBook}},[_c('v-icon',{staticClass:"me-1",attrs:{"small":""}},[_vm._v(" mdi-reload ")]),_vm._v(" Reset ")],1)],1)],1),(_vm.chapterUnlocked)?_c('UnlockDialog',{attrs:{"callbackClose":() => {
              _vm.chapterUnlocked = false;
              _vm.read(_vm.selectedItem);
            },"showDialog":true,"title":_vm.$t('string.chapterUnlocked'),"message":JSON.parse(_vm.selectedItem.translatableName)[_vm.subjectLang]}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }