<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <FinishedBanner v-if="finished"></FinishedBanner>
          <Score
            v-if="showScore"
            :star="math.star"
            :score="math.score"
            :difficulty="math.difficulty"
            :totalQ="math.noOfRounds"
            :countCorrect="math.countCorrect"
            :game="math.game"
            :topic="math.topic"
            :isDemo="true"
          ></Score>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    FinishedBanner: () =>
      import(
        /* webpackChunkName: "component-mini-game-finished-banner" */ "@/components/games/FinishedBanner"
      ),
    Score: () =>
      import(
        /* webpackChunkName: "component-math-game-score" */ "@/components/games/MathScore"
      ),
  },
  computed: mapState({
    math: (state) => state.math.data,
  }),
  props: [
    //
  ],
  data: () => ({
    finished: true,
    showScore: false,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  async created() {
    if (!this.math.isSaved) {
      let tempStore = this.$_.cloneDeep(this.math);
      tempStore["isSaved"] = true;
      this.$store.commit("updateMath", tempStore);
      setTimeout(() => {
        this.finished = false;
        this.showScore = true;
      }, 2000);
    } else {
      setTimeout(() => {
        this.finished = false;
        this.showScore = true;
      }, 3000);
    }
  },
  mounted() {
    //
  },
  methods: {
    next() {
      this.$router.push({
        name: "PageMathGameDemo",
      });
    },
  },
};
</script>

    <style scoped>
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}
</style>