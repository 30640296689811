var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body",style:(_vm.intraction)},[(_vm.movedDialog)?_c('MovedDialog',{attrs:{"callbackClose":() => (_vm.movedDialog = false),"showDialog":true,"message":_vm.movedMsg,"image":_vm.movedRefImg}}):_vm._e(),_c('v-container',{staticClass:"app-body"},[_c('div',{staticClass:"pe-2 ps-3 app-title text-h5"},[_vm._v(" "+_vm._s(_vm.$t("title.town"))+" "),_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"40","callback":_vm.exit}})],1),_c('div',{staticClass:"pa-5"},[_c('v-row',[_vm._l((_vm.items),function(item,i){return _c('v-col',{key:i,staticStyle:{"position":"relative"},attrs:{"cols":"4"}},[(!item.isLock || (item.isLock && _vm.settings.debug))?_c('v-img',{staticClass:"d-flex justify-center align-end text-center text-stroke-sm text-h6 menu-item",attrs:{"id":item.tutId,"src":item.icon,"width":"150"},on:{"click":() => {
                    _vm.$intro().exit();
                    _vm.$router.push({
                      name: item.page,
                    });
                  }}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"aspect-ratio":"1"}},[_c('v-icon',{attrs:{"color":"cyan lighten-2"}},[_vm._v("mdi-circle")])],1),(item.isMoved)?_c('v-img',{staticStyle:{"position":"absolute","top":"0","left":"0"},attrs:{"src":require(`@/assets/explore/menu/moved_${_vm.$_getLocale()}.png`)},on:{"click":function($event){_vm.movedDialog = true;
                  _vm.movedRefImg = item.movedRefImg;
                  _vm.movedMsg = item.movedMsg;}}}):_vm._e()],1)}),_vm._l((12 - _vm.items.length),function(i){return _c('v-col',{key:i + 7,attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"aspect-ratio":"1"}},[_c('v-icon',{attrs:{"color":"cyan lighten-2"}},[_vm._v("mdi-circle")])],1)])})],2)],1)])],1),_c('SkipTut')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }