<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body pt-0" v-if="$route.query.key">
          <div class="d-flex justify-end">
            <CloseBtn size="40" :callback="exit" />
          </div>
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <!-- EOC -->
          <Loader v-if="api.isLoading" :isLoading="api.isLoading" />
          <transition name="fade" v-else-if="book">
            <div class="bg-white py-5 elevation-4">
              <div class="px-3">
                <v-img
                  v-if="book.translatableImage"
                  :src="
                    JSON.parse(book.translatableImage)[$_getLocale()]
                      ? JSON.parse(book.translatableImage)[$_getLocale()].url
                      : JSON.parse(book.translatableImage)[subjectLang].url
                  "
                  class="mb-4 mx-auto"
                >
                  <template v-slot:placeholder>
                    <div
                      style="
                        background-color: #ccaf8b;
                        width: 100%;
                        padding-top: 100%;
                      "
                    ></div>
                  </template>
                </v-img>
                <div
                  class="my-4 mx-auto"
                  v-else
                  style="
                    background-color: #ccaf8b;
                    width: 100%;
                    padding-top: 100%;
                  "
                ></div>
                <div
                  class="text-center text-h4 my-4"
                  v-if="book.translatableName"
                >
                  <!-- {{
                    JSON.parse(book.translatableName)[$_getLocale()]
                      ? JSON.parse(book.translatableName)[$_getLocale()]
                      : JSON.parse(book.translatableName)[subjectLang]
                  }} -->
                  {{ JSON.parse(book.translatableName)[subjectLang] }}
                </div>
                <div class="text-center my-4">
                  {{ $t("string.authorPre") }} {{ book.author }}
                  {{ $t("string.authorPost") }}
                </div>
                <div class="text-center">
                  <v-btn
                    max-width="350"
                    width="100%"
                    color="green"
                    dark
                    large
                    class="text-h6"
                    @click="read()"
                  >
                    {{ $t("string.startReading") }}
                    <span style="position: absolute; right: 0">{{
                      countDown
                    }}</span>
                  </v-btn>
                </div>
              </div>
            </div>
          </transition>
        </v-container>
        <v-container
          class="app-body pt-0 d-flex align-center justify-center"
          v-else
        >
          No Book Key in the query
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),

    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  props: [
    //
  ],
  data: () => ({
    book: null,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    interval: null,
    countDown: 5,
    subjectLang: "en",
  }),
  async created() {
    if (!this.settings) {
      let tempSettings = {
        audio: {
          master: 0.3,
          music: 1,
          sfx: 1,
        },
      };
      this.$store.commit("updateSettings", tempSettings);
    }
    if (this.$route.query.key) {
      await this.openBook();
      if (this.book.subject == "Mandarin") {
        this.subjectLang = "zh";
      } else if (this.book.subject == "Malay") {
        this.subjectLang = "ms";
      } else {
        this.subjectLang = "en";
      }
    } else {
      //
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      if(this.book != null) {
        if (this.countDown > 0) {
          this.countDown--;
        } else {
          clearInterval(this.interval);
          this.read();
        }
      }
    }, 1000);
  },
  methods: {
    exit() {
      window.location.href = `${process.env.VUE_APP_URL_WEB_SCHOOL}/${this.$_getLocale()}/home`;
    },
    async openBook() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/demo/book/open";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.book = resp.Book;
      };
      this.api.params = {
        bookKey: this.$route.query.key,
      };
      await this.$api.fetch(this.api);
    },
    read() {
      this.$router.push({
        name: "PageABCBookChaptersDemo",
        params: { bookKey: this.$route.query.key },
      });
    },
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.countDown = 3;
    }
  },
};
</script>

<style scoped>
.pulse:hover {
  animation: none;
}

.pulse {
  animation: beat 0.5s infinite alternate;
  transform-origin: center;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.1);
  }
}

.menu-button {
  padding: 0 5px;
  cursor: pointer;
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.list-card {
  padding: 5px;
  color: #785c3a;
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #fff9f1;
}

.main-body {
  /*background-color: #c7e5e9;*/
  background-color: rgba(214, 132, 49, 0.3);
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}
</style>