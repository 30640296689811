export default {
  action: {
    back: "Kembali",
    buy: "Beli",
    cancel: "Batal",
    changeLanguage: "Tukar Bahasa",
    checkResult: "Semak Keputusan",
    claim: "Tuntut",
    claimed: "Telah Tuntut",
    claimReward: "Tuntut Ganjaran",
    clickToContinue: "Tekan untuk teruskan",
    clickToClaim: "Tekan untuk tuntutkan",
    continue: "Teruskan",
    copied: "TELAH DISALIN",
    delete: "Padam",
    done: "OK",
    draw: "Lukis",
    enter: "Masuk",
    find: "Cari",
    game: "Cuba versi penuh",
    go: "Pilih",
    goOn: "Teruskan",
    hatch: "Menetas",
    //   home:"",
    //   info:"",
    joinNow: "Sertai Sekarang",
    login: "Log Masuk",
    loginAgain: "Log Masuk Semula",
    loginAsStudent: "Log masuk sebagai pelajar",
    loginAsTeacher: "Log masuk sebagai guru",
    logout: "Log Keluar",
    new: "Baru",
    next: "Teruskan",
    nextTime: "Mungkin lain kali",
    noThx: "Tak apa.",
    //   orderBy:"",
    ok: "Ok",
    play: "Bermain",
    playAgain: "Bermain Semula",
    recalculate:"Kira semula",
    //   regenerateQuestion:"",
    register: "Daftar",
    remove: "Alih keluar",
    //   returnHome:"",
    refresh: "Segarkan semula",
    reset: "Set semula",
    retry: "Cuba semula",
    rewardClaimed: "Ganjaran Dituntut",
    save: "Simpan",
    scoreboard: "Papan markah",
    search: "Cari",
    select: "Pilih",
    selected: "Dipilih",
    share: "Kongsi",
    skip: "SKIP",
    skipTutorial: "Ponteng",
    start: "Mula",
    //   testPlay:"",
    trade: "PERDAGANGAN",
    tryAgain: "Cuba lagi",
    unlock: "Buka kunci",
    //   view:"",
    //   viewSample:"",
    //   whatsapp:"",
  },
  api: {
    response: {
      no_data: "Tiada data.",
    },
  },
  buildingEntranceDialogues: {
    welcomeBugCatcher_1: "Selamat datang!",
    welcomeBugCatcher_2: "Selamat datang!",
    welcomeBugCatcher_3: "Selamat datang!",
    welcomeEnergy_1: "Selamat datang!",
    welcomeEnergy_2: "Selamat datang!",
    welcomeEnergy_3: "Selamat datang!",
    welcomeEvents_1: "Selamat datang!",
    welcomeEvents_2: "Selamat datang!",
    welcomeEvents_3: "Selamat datang!",
    welcomeHouse_1: "Selamat datang!",
    welcomeHouse_2: "Selamat datang!",
    welcomeHouse_3: "Selamat datang!",
    welcomeLuckyegg_1: "Selamat datang!",
    welcomeLuckyegg_2: "Selamat datang!",
    welcomeLuckyegg_3: "Selamat datang!",
    welcomeMission_1: "Selamat datang!",
    welcomeMission_2: "Selamat datang!",
    welcomeMission_3: "Selamat datang!",
    welcomeShop_1: "Selamat datang!",
    welcomeShop_2: "Selamat datang!",
    welcomeShop_3: "Selamat datang!",
    welcomeReport_1: "",
    welcomeReport_2: "",
    welcomeReport_3: "",
    welcomeSchool_1: "Selamat datang!",
    welcomeSchool_2: "Selamat datang!",
    welcomeSchool_3: "Selamat datang!",
  },
  games: {
    random: "Rawak",
    noGame: "Tiada Permainan",
    mining: "Perlombongan",
    foraging: "Mencari Makanan",
    cooking: "Memasak",
    fishing: "Memancing",
  },
  miniGames: {
    general: {
      cache: "Cache",
      cacheOn: "Cache telah dibuka",
      cacheOff: "Cache telah ditutup",
      continue: "Klik untuk Mula",
      go: "MULA",
      loading: "Sedang Memuatkan",
      newGame: "Pusingan Baru",
      ok: "OK",
      on: "Buka",
      off: "Tutup ",
      ready: "SEDIA",
      resume: "Sambung",
      round: "Pusingan",
      score: "Skor",
      next: "Teruskan",
      recommendationTxt : "Mari kita pelajari dari video sebelum meneruskan.",
      recommendationBtn : "Tonton Sekarang",
      restConfirmationTxt: "Adakah anda pasti mahu menetapkan semula?",
    },
    mining: {
      careful: "Berhati-hati!",
      cleared: "Berjaya",
      gemLostBoss: "Batu permata hilang!",
      gemLost: "Batu Permata Pecah!",
      hurryUp: "Cepat cepat!",
      timeFreeze: "Membeku Masa!",
      timeUp: "MASA TAMAT!",
      warning: "AMARAN",
      warningMsg: "Pencuri batu permata muncul!",
      tutorial: {
        dialog_1: "Selamat datang untuk melombong!",
        dialog_2: "Mari kita belajar cara bermain.",
        dialog_3: "Tekan butang beliung untuk bermula.",
        dialog_4: "Tolong pilihkan jawapan yang betul.",
        dialog_5_a: "Bagus, jawapan anda betul!",
        dialog_5_b: "Opps.. Jawapan yang salah.",
        dialog_6_a: "Mari kita meletupkan dinding dengan bom.",
        dialog_6_b: "Mari kita meletupkan dinding dengan mercun.",
        dialog_7: "Kaboom!!!",
        dialog_8: "Wah, awak terjumpa BATU PERMATA yang ditanam!",
        dialog_9: "Anda perlu melombong untuk mendapatkan BATU PERMATA.",
        dialog_10:
          "Dinding akan runtuh apabila masa tamat, oleh itu anda perlu bertindak pantas!",
        dialog_11: "Semoga berjaya!",
        dialog_12_a: "Masa tamat. Kesian!",
        dialog_12_b: "Oh tidak! Anda telah memecahkan BATU PERMATA.",
        dialog_12_c: "Tahniah! Anda mendapat BATU PERMATA. Bagus!",
        dialog_13: "Mari kita cuba lagi.",
        dialog_14: "Bagus! Anda telah melengkapkan tutorial perlombongan.",
        dialog_15: "Semoga berjaya dan dapatkan lebih banyak permata.",
      },
    },
    foraging: {
      failed: "GAGAL",
      failedMsg: "Buah istimewa telah hilang",
      success: "BERJAYA",
      successMsg: "Anda mendapat buah istimewa",
      stun: "Pengsan",
      warning: "AMARAN",
      warningMsg: "Pencuri buah muncul!",
      q_msg_1: "Sempurna",
      q_msg_2: "Syabas",
      q_msg_3: "Hebat",
      q_msg_4: "Baik",
      q_msg_5: "Kesian",
      tutorial: {
        dialog_1: "Selamat datang ke Foraging Fest.",
        dialog_2: "Mari kita belajar bermain.",
        dialog_3: "Tekan butang mula.",
        dialog_4: "Tugas anda adalah untuk mengumpul epal yang segar.",
        dialog_5:
          "Sementara itu, berhati-hati dengan ular, ulat, dan epal busuk.",
        dialog_6: "Gunakan butang anak panah untuk bergerak.",
        dialog_7: "Syabas.",
        dialog_8: "Mari kita mula.",
        dialog_9: "Wah, anda penuh dengan tenaga!",
        dialog_10: "Jawab soalan untuk gunakan kuasa anda.",

        dialog_11: "Pilihkan jawapan yang betul.",
        dialog_12_a: "Bagus, jawapan anda betul.",
        dialog_12_b: "Opps… Jawapan thlah salah.",
        dialog_13_a: "Mari gunakan kuasa anda.",
        dialog_13_b: "Mari gunakan kuasa anda.",
        dialog_14: "Mari cuba sekali lagi.",
        dialog_15: "Bagus  Anda telah menyelesaikan tutorial.",
        dialog_16: "Semoga berjaya!",
      },
    },
    cooking: {
      boss: "Bos akan Datang",
      warning: "AMARAN",
      tutorial: {
        dialog_1: "Selamat datang ke Cooking Craze",
        dialog_2: "Mari kita belajar bermain!",
        dialog_3:
          "Tugas anda adalah menyediakan makanan yang disukai oleh pelanggan",
        dialog_4: "Makanan yang berbeza mempunyai skor yang berbeza",
        dialog_5: "Tetapi, jangan biarkan pelanggan menunggu terlalu lama",
        dialog_6: "Ok, mari kita bermula!",
        dialog_7: "Pertama, klik pada bahan-bahan untuk mula memasak",
        dialog_8: "Kemudian, tunggu sehingga makanan masak",
        dialog_9: "Sekarang, seretkan makanan kepada pelanggan",
        dialog_10: "Syabas, pelanggan sangat gembira!",
        dialog_11: "Seterusnya, mari cuba masak makanan dengan lama",
        dialog_12: "Opps,  dapur terbakar!",
        dialog_13: "Cepat! Seretkan pemadam api untuk memadamkan api",
        dialog_14: "Kemudian, seret makanan yang dibakar ke dalam tong",
        dialog_15: "Baiklah, mari kita teruskan…",
        dialog_16: "Wah, bar anda telah penuh",
        dialog_17: "Jawab soalan untuk menggunakan kuasa anda!",
        dialog_18: "Pilihkan jawapan yang betul.",
        dialog_19_a: "Bagus, jawapan anda betul",
        dialog_19_b: "Jawapan salah",
        dialog_20: "Mari gunakan kuasa anda…",
        dialog_21_a: "Cepat, berikan makan kepada semua pelanggan!",
        dialog_21_b: "Poof.. kemalangan berlaku.",
        dialog_22: "Cepat padamkan api!",
        dialog_23: "Mari cuba sekali lagi.",
        dialog_24: "Syabas! Anda telah melengkapkan tutorial.",
        dialog_25: "Semoga berjaya!",
      },
    },
    towerClimbing: {
      blueStarError: "Tidak cukup bintang biru",
      floor: "Tingkat",
      footprintError: "Tak cukup tapak kaki",
      pickOne: "MEMILIH SATU",
    },
    kombat: {
      boss: "Kapten lanun telah muncul!!!",
      warning: "AMARAN",
      lost: "Gagal",
      fight: "Lawan",
      won: "Menang",
      tutorial: {
        dialog_1: "Selamat datang ke BIBABO",
        dialog_2: "Mari kita belajar bermain!",
        dialog_3: "Oh, lanun ada di sini!",
        dialog_4: "Mari kita kalahkan mereka.",
        dialog_22: "Ini adalah nyawa anda - jangan biarkan ia mencapai 0.",
        dialog_5: "Klik untuk memulakan serangan.",
        dialog_6: " Klik sekali lagi untuk berhenti.",
        dialog_7: "Isikan kedua-dua bar serangan.",
        dialog_8_a: "Hei, terimakan sihir saya!",
        dialog_8_b: "Sihir anda gagal…",
        dialog_9: "Aduh, lanun itu melawan balik.",
        dialog_10: "Mari, kita teruskan menyerang lanun itu.",
        dialog_11: "Oh, lanun itu cedera.",
        dialog_12: "Bersedia untuk menggunakan serangan khas anda.",
        dialog_13: "Klik untuk meneruskan.",
        dialog_14: "Pilihkan jawapan yang betul.",
        dialog_15_a: "Bagus, jawapan anda betul.",
        dialog_15_b: "Jawapan salah.",
        dialog_16_a: "Sihir anda lebih berkuasa sekarang!",
        dialog_16_b: "Oh tidak, anda terlepas peluang anda.",
        dialog_21: "Serang!!!",
        dialog_17_a: "Bagus, anda telah mengalahkan lanun!",
        dialog_17_b: "Oh tidak, awak telah kalah!!!",
        dialog_18: "Jom lawan lain kali.",
        dialog_19: "Anda telah melengkapkan tutorial.",
        dialog_20: "Semoga berjaya!",
      },
    },
    fishing: {
      boss: "Ikan bos dikesan!!!",
      fish_1: "Ikan Kotak",
      fish_2: "Udang",
      fish_3: "Kepah",
      fish_4: "Ikan Dori",
      trash: "Sampah",
      warning: "AMARAN ",
      start: "Mula",
      tutorial: {
        dialog_1: "Selamat datang ke Fishing Frenzy.",
        dialog_2: "Saya Hamochi, saya akan mengajar anda cara memancing!",
        dialog_3: "Klik butang mentol lampu besar kuning untuk mula memancing.",
        dialog_4_1: "Ikan berada di mata kail!",
        dialog_4_2: "Mari lihat apa cabaran yang diberikan!",
        dialog_5: "Pilihkan jawapan yang betul.",
        dialog_6_a: "Bagus, jawapan anda betul.",
        dialog_6_b: "Opps! Jawapan salah.",
        dialog_12_a: "Joran anda menjadi lebih kuat!",
        dialog_12_b: "Oh tidak, joran anda semakin lemah!",
        dialog_7: "Cepat, tekan butang kuning besar untuk tarik ikan naik!",
        dialog_8_a: "Taniah, anda berjaya menangkap ikan!",
        dialog_8_b: "Malangnya, ikan itu terlepas...",
        dialog_9: "Jom berlatih lagi!",
        dialog_10: "Bagus, anda telah menyelesaikan tutorial memancing.",
        dialog_11: "Semoga berjaya!",
      },
    },
    dino:{
      oops: "Alamak...",
      notEnough: "Tak cukup tulang!",
      niceWork: "Kerja Bagus!",
      gameOver: "Permainan Tamat!",
      dragDrop: "Seret dan Lepas",
    }
  },
  model: {
    filterBy: {
      all: "Semua",
    },
    name: {
      chapter: "Unit",
      chapters: "Unit",
      checkpoint: "Soalan",
      checkpoints: "Soalan",
      classroom: "Kelas",
      classrooms: "Kelas",
      //     event:"",
      //     events:"",
      game: "Permainan",
      mission: "Kerja Rumah",
      missions: "Kerja Rumah",
      prize: "Hadiah",
      prizes: "Hadiah",
      question: "Soalan",
      questions: "Soalan",
      results: "Keputusan",
      school: "Sekolah",
      schools: "Sekolah",
      series: "Siri",
      student: "Pelajar",
      students: "Pelajar",
      submissions: "Kertas Jawapan",
      //     syllabus:"",
      //     syllabuses:"",
      //     winner:"",
      //     winners:"",
    },
    prop: {
      //     accuracy:"",
      //     attempt:"",
      //     bought:"",
      //     corrects:"",
      date: "Tarikh",
      //     dateDue:"",
      //     dateEnd:"",
      //     dateStart:"",
      fullname: "Nama Penuh",
      gameCode: "Kod Permainan",
      //     high_score:"",
      //     level: "",
      //     mobile:"",
      //     name:"",
      //     no:"",
      //     password:"",
      rewards: "Ganjaran",
      //     quantity:"",
      round: "Pusingan",
      schoolCode: "Kod Sekolah",
      score: "Skor",
      //     stars:"",
      //     status:"",
      //     time:"",
      timeEnd: "Masa tamat",
      //     timestampEnd:"",
      //     timestampStart:"",
      //     totalAttempt:"",
      //     totalScore:"",
      //     totalStar:"",
      //     weakness:"",
    },
    value: {
      status: {
        absent: "Tidak hadir",
        coming: "Akan datang",
        joined: "Telah menyertai",
        ongoing: "Sedang berlangsung",
        past: "Telah tamat",
      },
    },
  },
  // route:{
  //   PageEntranceStudentMain:"",
  // },
  string: {
    about: "Tentang",
    all: "Semua",
    achievement: "Pencapaian",
    are_you_sure: "Adakah anda pasti?",
    assisting_lang : "Bahasa Bantuan",
    assisting_lang_on: "Bahasa Bantuan dihidupkan",
    assisting_lang_off: "Bahasa Bantuan dimatikan",
    audio: "Audio",
    audioGraphics: "Audio & Grafik",
    authorPre: "",
    authorPost: "",
    autofix: "Pembaikan Automatik",
    autoplay: "Automain",
    bgMusic: " Muzik Latar",
    blankSpace: "",
    book: "BUKU",
    bookInfo: "Maklumat Buku",
    body: "Haiwan",
    bug: "Serangga",
    by: "oleh",
    doll: "Anak patung",
    dino: "Dinosaurus",
    catch: "Tangkap",
    cancel: "Batalkan",
    chapter: "Unit",
    chapterUnlocked: "Bab Telah Dibuka",
    chapters: "Bab",
    change: "Tukar",
    claimed: "Dituntut",
    code: "Kod",
    color: "Warna",
    collection: "Koleksi",
    continueIn: "Sambung dalam {sec} saat",
    completed: "Selesai",
    coming_on: "Akan datang pada",
    comingSoon: "Akan Datang",
    confirm_msg: "Anda ingin menamatkan permainan?",
    chapterStarMsg1 : "Kumpulkan lebih banyak bintang untuk membuka bab ini",
    chapterStarMsg2 : "Selesaikan bab sebelumnya untuk membuka kunci bab ini",
    current: "Semasa",
    days: "Hari",
    days_left: "hari lagi",
    days_start: "hari bermula",
    easy: "Mudah",
    empty: "Kosong",
    empty_msg: "Tiada surat!",
    emptyNameError: "Tolong isikan nama.",
    energyRechargeable: "Cas tenaga anda setiap hari.",
    energyClaimed: "Anda sudah cas hari ini.",
    energySuccess: "Cas penuh!",
    ends_in: "Tamat dalam",
    expired: "Expired",
    expires_in: "Expires in",
    // eventNotStarted:"",
    event_over: "Sudah Tamat!",
    exit: "Keluar",
    failed: "Gagal!",
    finished: "Tamat",
    finisherGiftTitle: "Hadiah Penamat",
    finisherGiftMsg1: "Tahniah kerana menyempurnakan acara ini!",
    finisherGiftMsg2: "Hadiah anda sedang dalam perjalanan ke:",
    finisherGiftMsg3:
      "Hadiah anda telah dihantar! Sila ambil dari sekolah anda.",
    // featureNotAvailable:"",
    // fishingTutorial:"Fishing Tutorial",
    // fishingTutorialCompleted:"You have Completed the Fishing Tutorial Lesson",
    // fishingTutorialIncomplete:"Please complete the tutorial to unlock this level",
    fish: "Ikan",
    fish2: "Ikan",
    fruit: "buah-buahan",
    food: "makanan",
    game_loading_msg:
      "Permainan sedang bermula... Kalau tersekat, cuba semula.",
    gameScore: "Skor Permainan",
    gem: "Batu Permata",
    get: "Dapatkan",
    gifts_error: "Hadiah hanya untuk pelajar.",
    giftDescription: "Kumpul semua bintang untuk mendapatkan hadiah misteri!",
    hall: "Dewan",
    hard: "Susah",
    highGraphics: "Grafik Tinggi",
    thanksDemo: "Terima kasih kerana mencuba Hamochi ABC!",
    info: "Info",
    high_score: "Markah tertinggi:",
    league_reward: "Ganjaran Liga",
    level: "Level",
    language: "Bahasa",
    lastLogin: "Log Terbaru",
    levels: "Topik",
    lockedBuilding: "Apakah ia?",
    learnMoreEvent: "Baca tentang acara ini.",
    manyGifts: "Ada banyak hadiah 🎁!",
    maths: "Matematik",
    mathDaily: "Matematik Harian",
    mathMastery: "Kemahiran Matematik",
    medium: "Sederhana",
    mouth: "Mulut",
    month_theme: "Tema Bulan ini:",
    movedMsg: "Kamu boleh menemukannya di sini!",
    myBook: "Buku Saya",
    myPrize: "Hadiah Saya",
    newArrivals: "Ketibaan Baru",
    no: "tidak",
    noStudents: "Tiada Pelajar dalam liga ini",
    noItem: "Tiada barang.",
    noEnergy: "Tidak cukup tenaga",
    notCompleted: "Belum siap.",
    noThanks: "Tak apa.",
    open: "Buka",
    others: "Lain-lain",
    own: "Dimiliki",
    page: "Muka Surat",
    quest: "Misi",
    questEventBookFinishAllChapters: "Lengkapkan semua bab",
    question: "Soalan",
    question_prefix: "S",
    rank: "Rank",
    ranking: "Ranking",
    rankingClassroom: "Kelas",
    rankingEvent: "Acara",
    read: "Baca",
    read_rules: "Baca peraturan",
    reader_loading_msg:
      "Pembaca sedang bermula... Kalau tersekat, cuba semula.",
    recalculate:"Gunakan butang berikut jika jumlah bintang atau markah tidak dikira",
    recalculateScore:"Adakah anda pasti mahu mengira semula markah?",
    recalculateStar:"Adakah anda pasti mahu mengira semula bintang?",
    remove: "Alih keluar",
    refresh: "Muat semula masuk",
    resetIn: "Diset semula dalam",
    result: "Keputusan",
    round: "Pusingan",
    requiredHigh: "Memerlukan internet berkelajuan tinggi.",
    rewards_claimed: "Ganjaran telah dituntut!",
    rules: "Peraturan",
    //   sample_question:"",
    save: "Simpan",
    saving: "Menyimpan......",
    school: "Sekolah",
    schoolYear_prefix: "Tahun ",
    schoolYear_suffix: "",
    select_year: "Pilih Tahun",
    select_hole: "Pilih Lubang",
    selectKeyError: "Tolong pilihkan kunci anda.",
    settings: "Tetapan",
    sponsors: "Penaja",
    sfx: "SFX",
    show_all: "Tunjuk semua",
    show_less: "Tunjuk fokus",
    showHint: "Tunjuk Petunjuk",
    starts: "Mula {count}",
    starts_in: "Starts in",
    storyBook : "Buku Cerita",
    start_now: "Mula",
    startReading: "Mulakan Membaca",
    success: "Berjaya!",
    towerMoved: "Menara telah dipindahkan ke Matematik!",
    theme: "Tema",
    tutorial: "Tutorial",
    ticketError: "Tak cukup tiket. Datang balik esok untuk kutip tiket baru.",
    total: "Jumlah",
    totalScore: " Jumlah markah",
    topics: "Topik",
    tryAgain: "Cuba Lagi",
    takeBreak: "Berehat",
    youFailed: "Anda Gagal",
    noPick: "Tiada Pilihan",
    updateImages: "Kemas kini Imej",
    upload_art: "Muat naik seni anda!",
    view_result: "View Results",
    volume: "Volum",
    wear: "Pakai",
    warning: "Amaran!",
    yes: "Ya",
    you_have_mail: "Anda ada surat!",
    mail: "Peti Surat",
    year: "Tahun {year}",
    you_got: "Anda menerima",
    you_collected: "Anda telah mendapati",
    you_missed_a: "Anda telah terlepas Batu Permata",
    you_missed_b: "Anda terlepas buah istimewa",
    you_missed_c: "Anda terlepas hidangan yang sedap",
    you_missed_d: "Anda telah terlepas anak patung koleksi",
    you_missed_x: "Anda telah terlepas Koleksi Jarange",
    openIn: "Mula dalam",
    closeIn: "Tamat dalam",
    goToTheEvent: "Pergi ke acara",
    basicItems: "Item Asas",
    drawing: "Pilihan Studio",
    monthly: "Pilihan Bulanan",
    event: "Pilihan Acara",
    tower: "Pilihan Menara Math",
    go: "MULA",
    chapterCompleted: "Bab selesai",
    readAgain: "Baca semula",
    goChapters: "Pergi ke bab",
  },
  title: {
    gallery: "Galeri",
    tower: "Menara Math",
    town: "Bandar",
    shop: "Kedai",
    wardrobe: "Almari",
    hamochi_cup: "Peristiwa",
    game: "Acara",
    level: "Level",
    locksmith: "Tukang kunci",
    select_school: "Pilih Sekolah Anda",
    studio: "Studio",
  },
  tutorial: {
    energy: {
      dialog_1: "Tenaga anda agak rendah.",
      dialog_2: "Jom pergi cas sama sekali.",
      dialog_3: "Tekan sini untuk masuk bangunan ini.",
      dialog_4: "Tekan sini untuk cas tenaga anda.",
      dialog_5: "Tahniah! Tenaga anda telah dicas.",
      dialog_6: "Anda boleh cas sekali setiap hari .",
      dialog_7: "Tekan sini untuk balik ke bandar.",
      dialog_8: "Nampaknya anda telah cas hari ini. Jumpa lagi esok.",
    },
    events: {
      dialog_1: "Lihat, nampaknya ada ramai orang di sana.",
      dialog_2: "Tekan sini untuk masuk bangunan ini.",
      dialog_3: "Tekan sini untuk pergi ke bandar.",
      dialog_4: "Ha! Rupa-rupanya ada acara. Jom tengok.",
      dialog_5: "Tekan sini untuk sertai acara.",
      dialog_6: "Jom cuba versi ringan kali ini.",
      dialog_7: "Jom cuba versi penuh kali ini.",
    },
    exploreIntro: {
      dialog_1: "Hi, saya adalah Hamochi.",
      dialog_2: "Selamat datang ke Hamochi World.",
      dialog_3: "Kami suka belajar sambil bermain.",
    },
    exploreEvents: {
      dialog_1: "Lihat, nampaknya ada ramai orang di sana.",
      dialog_2: "Tekan sini untuk masuk.",
      dialog_3: "Tekan sini untuk sertai acara.",
    },
    exploreMining: {
      dialog_1: "Mari kita belajar cara melombong!",
      dialog_2: "Tekan sini untuk bermula",
    },
    exploreForaging: {
      dialog_1: "Jom belajar cara mengumpul buah-buahan!",
      dialog_2: "Klik untuk bermula.",
    },
    exploreCooking: {
      dialog_1: "Jom belajar memasak!",
      dialog_2: "Klik di sini untuk mula memasak.",
    },
    exploreKombat: {
      dialog_1: "Mari belajar cara menggunakan sihir!",
      dialog_2: "Klik di sini untuk bermula.",
    },
    exploreFishing: {
      dialog_1: "Jom belajar Memancing!",
      dialog_2: "Klik di sini untuk memulakan tutorial permainan.",
    },
    exploreDino: {
      dialog_1: "Mari kita belajar cara Meneroka Dino!",
      dialog_2: "Klik di sini untuk memulakan tutorial permainan.",
    },
    exploreLevel: {
      dialog_1: "Sekarang saya akan mengajar anda untuk mulakan topik",
      dialog_2: "Tekan untuk pilih topik.",
      dialog_4: "Tekan untuk bermula.",
    },
    exploreShop: {
      dialog_1: "Kedai telah dibuka.",
      dialog_2: "Tekan sini untuk masuk ke menu.",
      dialog_3: "Tekan sini untuk masuk ke kedai.",
      dialog_4_1: "Anda boleh menggunakan syiling untuk membeli-belah.",
      dialog_4_2: "Bagus, anda telah memiliki topi!",
      dialog_5: "Mari kita cuba untuk membeli topi.",
      dialog_6: "Tekan untuk memilihkan topi.",
      dialog_7: "Tekan beli.",
      dialog_8: "Bagus, perniagaan berjaya.",
      dialog_9: "Tahniah! anda telah mendapati topi baru.",
      dialog_10:
        "Masih terdapat pelbagai pakaian di sini. Kita boleh kembali bila-bila sahaja.",
      dialog_11: "Klik di sini untuk kembali",
    },
    intro: {
      dialog_1: "Hi, saya adalah Hamochi.",
      dialog_2: "Selamat datang ke Pulau Mochi.",
      dialog_3: "Kami suka belajar sambil bermain.",
    },
    fishing_achievements: {
      dialog_1: "Tahniah! Anda telah menyiapkan tutorial.",
      dialog_2: "Lihat apa yang saya telah sediakan untuk anda.",
      dialog_3: "Tekan sini.",
      dialog_4: "Inilah ganjaran untuk anda.",
      dialog_5: "Tekan sini.",
      dialog_6: "Anda telah mendapat ganjaran anda.",
      dialog_7: "Masih ada banyak ganjaran yang boleh didapati.",
      dialog_8:
        "Seterusnya, saya akan menujukkan anda bagaimana untuk menggunakan syiling.",
      dialog_9: "Jom pulang ke bandar.",
      dialog_10: "Tekan sini.",
    },
    fishing_level_one: {
      dialog_1: "Jom cuba aras pertama.",
      dialog_2: "Tekan sini.",
    },
    fishing_tutorial: {
      dialog_1: "Nampaknya ia adalah acara memancing.",
      dialog_2: "Saya akan ajar anda.",
      dialog_3: "Tekan sini.",
      dialog_4: "Ikan telah memberi soalan.",
      dialog_5: "Pilih jawapan betul.",
      dialog_6: "Bagus.",
      dialog_7: "Alamak...",
      dialog_8: "Mana ikan bersembunyi?",
      dialog_9: "Tahniah! Anda telah menangkap ikan.",
      dialog_10: "Jom cuba lagi.",
      dialog_11: "Tahniah, anda sudah menyiapkan tutorial.",
      dialog_12: "Ayuh.",
      dialog_13: "Nampaknya ikan telah bersembunyi dalam salah satu lubang.",
      dialog_14: "Tekan dan tangkap rama-rama.",
      dialog_15: "Tahniah, anda sudah menyiapkan tutorial.",
      dialog_16: "Nampaknya ia adalah acara tangkap serangga.",
      dialog_17: "Saya akan ajar anda.",
      dialog_18: "Serangga sudah memberi soalan.",
      dialog_19: "Lihat,ada banyak rama-rama.",
    },
    pick_shop: {
      dialog_1: "Nampaknya kedai telah dibuka.",
      dialog_2: "Jom!",
      dialog_3: "Tekan sini.",
      dialog_4: "Anda boleh menggunakan syiling untuk membeli barang.",
      dialog_5: "Cuba topi ini.",
      dialog_6: "Bagus.",
      dialog_7: "Tahniah, anda telah mendapat topi baru.",
      dialog_8: "Ada banyak barang lagi. Kamu boleh datang pada masa depan.",
      dialog_9: "Nampaknya kamu ada topi sendiri.",
      dialog_10: "Tekan sini.",
    },
    return_events: {
      dialog_1: "Jom balik ke acara.",
      dialog_2: "Tekan sini.",
      dialog_3: "Tekan sini.",
      dialog_4: "Pilih versi yang anda nak cuba.",
      dialog_5: "Tekan sini.",
      dialog_6: "Tekan sini.",
    },
  },
  view: {
    Island: {
      Citizen: "Pelajar",
      Classroom: "Kelas",
      Home: "Kediaman",
      Market: "Pasaran",
      Playground: "Padang",
      Town: "Bandar",
      Teacher: "Cikgu",
      Building: {
        BugCatcher: "Bug Catcher",
        Events: "Stadium",
        Energy: "Tenaga",
        Garden: "Taman",
        House: "Rumah",
        LuckyEgg: "Telur Bertuah",
        Mayor: "Cikgu",
        Mission: "Kerja rumah",
        News: "Berita",
        Report: "Kad laporan",
        School: "Sekolah",
        Shop: "Kedai",
      },
    },
    PageBuildingMission: {
      bonusRewardsMessage: "Ganjaran bonus tamat",
      claimRewardMessage: "Tuntut ganjaran anda.",
      completedMessage: "Selesai pada",
      completedPastDueMessage: "Selesai tamat tempoh pada",
      deleteMissionMessage: "Adakah anda pasti mahu memadamkan misi ini?",
      dueMessage: "Berlaku pada",
      pageAddTitle: "Misi Baru",
      pageEditTitle: "Edit Misi",
      pagePerformanceTitle: "Prestasi Pelajar",
      rewardNotReceivedMessage: "Anda tidak mendapat apa-apa",
      rewardReceivedMessage: "Anda menerima",
    },
    PageBugcatcherHome: {
      bigBugCaught: "Anda menangkap {Bugname}.",
      bigBugMissed: "Anda telah terlepas serangga besar. Cuba lain kali.",
      bigBugWarning: "AMARAN!",
      bigBugDes: "Tekan 5 kali untuk menangkapnya.",
      bossDetected: "Bos telah dikesan",
      bugCatch: "Anda menangkap {count} ekor rama-rama.",
      bugMissed: "Anda tak dapat tangkap apa-apa.",
      difficultyBonus: "Bonus Kesukaran",
      gameTitle: "Bug Catcher",
      getReady: "Sedia",
      go: "Mula",
      points: "Skor",
      questionEndCorrectMessage:
        "Tahniah! Anda telah memilih jawapan yang betul.",
      questionEndWrongMessage: "Aduh! Anda terpilih jawapan yang salah.",
      roundEndMessage: "Masa untuk menjawab soalan...",
    },
    //   PageErrorNotFound:{
    //     title:"",
    //     description:"",
    //   },
    //   PageErrorSessionExpired:{
    //     title:"",
    //     description:"",
    //   },
    PageEntranceStudentAvatarSelect: {
      title: "Cipta Watak",
    },
    PageEntranceStudentClassroom: {
      title: "Pilih Nama Anda",
      stringNoAccount: "Tiada akaun?",
      search: "Cari nama anda...",
    },
    PageEntranceStudentMain: {
      //     fullVersionGameTitle:"",
      title: "Log Masuk",
      stringCodeEmpty: "Tolong isikan kod.",
      stringCodeIncomplete: "Tolong lengkapkan kod.",
    },
    PageEntranceStudentPin: {
      stringCodeEmpty: "PIN tiada.",
      stringCodeIncomplete: "PIN tidak lengkap.",
      title: "Masukkan PIN anda",
    },
    PageEntranceStudentPasswordSelect: {
      title: "Pilih Kunci Anda",
    },
    PageGuestSchool: {
      title: "Pilih Kelas Anda",
      search: "Cari kelas anda...",
    },
    PageEntranceStudentRegister: {
      title: "Daftar Akaun Baru",
    },
    PageEventLanding: {
      fast: "PANTAS",
      slow: "PERLAHAN",
      textInternet: "Kelajuan internet anda ",
      textStatusEntering: "Log masuk dalam...",
      textStatusInit: "Sila tunggu sebentar...",
      textStatusTest: "Sedang menguji kelajuan internet... ",
      textVersionFull: "Versi Penuh",
      textVersionFullDescription: "Perlukan ",
      textVersionFullDescriptionMins: " minit",
      textVersionLite: "Versi Ringan",
      textVersionLiteDescription: "Cadangan",
    },
    PageSleeping: {
      message:
        "Masa untuk tidur. Hamochi dan kawan-kawannya akan rehat dari pukul 11 malam. Sila balik pada pukul 7 pagi.",
    },
    PageParticipantLanding: {
      countdown_end: "Tamat dalam",
      countdown_ended: "Telah tamat",
      countdown_start: "Mula dalam",
      countdown_time_days: "H",
      countdown_time_hours: "J",
      countdown_time_minutes: "M",
      countdown_time_seconds: "S",
    },
    //   PageStudentClassmate: {
    //     title:"",
    //     description: "",
    //   },
    //   PageStudentHome: {
    //     title:"",
    //     description: "",
    //   },
    //   PageStudentLanding: {
    //     title:"",
    //     description: "",
    //   },
    //   PageStudentMedal: {
    //     title:"",
    //     description: "",
    //   },
    PageStudentShop: {
      // title:"",
      description: "Apa anda nak beli?",
    },
    //   PageStudentStadium: {
    //     title:"",
    //     description: "",
    //   },
    //   PageStudentWardrobe: {
    //     title:"",
    //     description: "",
    //   },
  },
  subscription: {
    name: "Pelan Masa Depan Cerah",
    description: "Pelan ini merangkumi ciri menarik yang direka untuk meningkatkan perjalanan pembelajaran anak anda:",
    feature1: "Buku Cerita Bulanan",
    feature2: "Kuasai Matematik",
    cta: "Baca Maklumat Lanjut",
  },
};
