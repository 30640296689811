<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body pt-0">
          <div class="d-flex justify-end">
            <CloseBtn size="40" :callback="readAgain" />
          </div>

          <transition name="fade">
            <div
              class="bg-white py-5 elevation-4"
            >
              <div class="px-3">
                <div class="text-center text-h4 my-4">
                  {{ $t("string.thanksDemo") }}
                </div>
                
                <div class="d-flex flex-column align-center mt-6 fade-in">
                  <v-btn
                    color="green"
                    max-width="200"
                    width="100%"
                    class="my-2"
                    rounded
                    dark
                    @click="signUp"
                  >
                    
                    SIGN UP
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    class="my-2"
                    max-width="200"
                    width="100%"
                    rounded
                    @click="readAgain"
                  >
                    {{ $t("action.playAgain") }}
                  </v-btn>
                </div>
              </div>
            </div>
          </transition>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //
  }),
  async created() {},
  mounted() {
    //
  },
  methods: {
    exit() {
      window.location.href = `${
        process.env.VUE_APP_URL_WEB_SCHOOL
      }/${this.$_getLocale()}/home`;
    },
    signUp() {
      window.location.href = `${
        process.env.VUE_APP_URL_WEB_SCHOOL
      }/${this.$_getLocale()}/register`;
    },
    readAgain() {
      this.$router.push({
        name: "PageABCBookChaptersDemo",
        params: {
          bookKey: this.$route.params.bookKey,
          // chapterKey: this.$route.params.chapterKey,
        },
      });
    },
  },
  beforeDestroy() {
    //
  },
};
</script>

<style scoped>
.pulse:hover {
  animation: none;
}

.pulse {
  animation: beat 0.5s infinite alternate;
  transform-origin: center;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.1);
  }
}

.menu-button {
  padding: 0 5px;
  cursor: pointer;
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.list-card {
  padding: 5px;
  color: #785c3a;
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #fff9f1;
}

.main-body {
  /*background-color: #c7e5e9;*/
  background-color: rgba(214, 132, 49, 0.3);
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}
</style>