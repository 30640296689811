<script>
import { mapState } from "vuex";
const fishingGame = require("@/dailyGames/Fishing/game");
// const cookingGame = require("@/dailyGames/Cooking/game");
// const foragingGame = require("@/dailyGames/Foraging/game");
// const miningGame = require("@/dailyGames/Mining/game");
// const onlyQuestions = require("@/dailyGames/Questions/game");
export default {
  components: {
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    math: (state) => state.math.data,
    settings: (state) => state.settings.data,
  }),
  props: [
    //
  ],
  data: () => ({
    showDifficulty: false,
    difficulty: 1,
    env: process.env.VUE_APP_ENV,
    noOfRounds: 5,
    containerId: "game-container",
    gameInstance: null,
    bossRound: -1,
    master: 0,
    music: 0,
    sfx: 0,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    questions: [],
  }),
  async created() {
    if (!this.settings) {
      let tempSettings = {
        audio: {
          master: 0.3,
          music: 1,
          sfx: 1,
        },
      };
      this.$store.commit("updateSettings", tempSettings);
    }

    await this.fetchQuestions();
    this.startGame();
  },
  mounted() {
    //
  },
  beforeDestroy() {
    let master = this.settings.audio.master;
    let music = this.settings.audio.music;
    this.$bgMusic.src = require("@/assets/sounds/music_bg_1.mp3");
    this.$bgMusic.volume = music * master;
    this.$bgMusic.play();
  },
  destroyed() {
    if (this.gameInstance) {
      this.gameInstance.destroy(true);
      this.gameInstance = null;
    }
  },
  methods: {
    async fetchQuestions() {
      this.api.url = this.$api.servers.question + "/questions";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.questions = resp;
        this.api.isLoading = false;
      };
      this.api.params = {
        lang: this.$_getLocale(),
        count: this.noOfRounds,
        key: "s010_c020_q040",
        showOption: false,
      };
      await this.$api.fetch(this.api);
    },
    clickStartGame() {
      this.showDifficulty = false;
      this.startGame();
    },
    startGame() {
      let tempStore = this.$_.cloneDeep(this.math) || {};
      tempStore["topic"] = "s010_c020_q040";
      tempStore["game"] = "FISH";
      tempStore["isSaved"] = false;
      tempStore["isDemo"] = true;
      tempStore["exitURL"] = process.env.VUE_APP_URL_WEB_SCHOOL;
      this.$store.commit("updateMath", tempStore);

      let game = fishingGame;

      this.gameInstance = game.launch(this.containerId);
      this.gameInstance.scene.start("LoadingScene", {
        avatar: { body: require("@/demo/Math/guest.png"), color: "FFFFFF" },
        name: "Guest",
        questions: this.questions,
        bossRound: this.bossRound,
        friend: {
          name: null,
          avatar: {},
        },
        env: this.env,
        noOfRounds: this.noOfRounds,
        difficulty: this.difficulty,
      });
      this.$bgMusic.pause();
      this.$bgMusic.src = null;
    },
  },
};
</script>

<template>
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="game-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <!-- EOC -->
          <div
            class="d-flex flex-column justify-center align-center dif"
            v-if="showDifficulty"
          >
            <div class="d-flex justify-center ma-3 mx-auto">
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 6 : 2"
                  class="ma-2 d-flex align-center justify-center"
                  width="80"
                  height="80"
                  :color="
                    difficulty == 1 ? 'yellow lighten-4' : 'grey lighten-4'
                  "
                  :class="{ 'on-hover': hover }"
                  @click="difficulty = 1"
                >
                  <span>
                    <div class="text-cption font-weight-medium">
                      {{ $t("string.easy") }}
                    </div>
                    <!-- <div>+50</div> -->
                    <div class="text-h4 text-center text--primary mb-3 pt-3">
                      <v-row align="center" justify="center">
                        <v-img
                          max-width="15"
                          max-height="25"
                          class="mt-2"
                          :src="
                            require('@/assets/bugCatcher/DifficultyFire.png')
                          "
                        ></v-img>
                      </v-row>
                    </div>
                    <div class="text-center text-caption">+ 0</div>
                  </span>
                </v-card>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 6 : 2"
                  class="ma-2 d-flex align-center justify-center"
                  width="80"
                  height="80"
                  :color="
                    difficulty == 2 ? 'yellow lighten-4' : 'grey lighten-4'
                  "
                  :class="{ 'on-hover': hover }"
                  @click="difficulty = 2"
                >
                  <span>
                    <div class="text-cption font-weight-medium">
                      {{ $t("string.medium") }}
                    </div>
                    <!-- <div>+100</div> -->
                    <div class="text-h4 text-center text--primary mb-3 pt-3">
                      <v-row align="center" justify="center">
                        <v-img
                          max-width="15"
                          max-height="25"
                          class="mt-2"
                          :src="
                            require('@/assets/bugCatcher/DifficultyFire.png')
                          "
                        ></v-img>
                        <v-img
                          max-width="15"
                          max-height="25"
                          class="mt-2"
                          :src="
                            require('@/assets/bugCatcher/DifficultyFire.png')
                          "
                        ></v-img>
                      </v-row>
                    </div>
                    <div class="text-center text-caption">+ 50</div>
                  </span>
                </v-card>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 6 : 2"
                  class="ma-2 d-flex align-center justify-center"
                  width="80"
                  height="80"
                  :color="
                    difficulty == 3 ? 'yellow lighten-4' : 'grey lighten-4'
                  "
                  :class="{ 'on-hover': hover }"
                  @click="difficulty = 3"
                >
                  <span>
                    <div class="text-cption font-weight-medium">
                      {{ $t("string.hard") }}
                    </div>
                    <!-- <div>+150</div> -->
                    <div class="text-h4 text-center text--primary mb-3 pt-3">
                      <v-row align="center" justify="center">
                        <v-img
                          max-width="15"
                          max-height="25"
                          class="mt-2"
                          :src="
                            require('@/assets/bugCatcher/DifficultyFire.png')
                          "
                        ></v-img>
                        <v-img
                          max-width="15"
                          max-height="25"
                          class="mt-2"
                          :src="
                            require('@/assets/bugCatcher/DifficultyFire.png')
                          "
                        ></v-img>
                        <v-img
                          max-width="15"
                          max-height="25"
                          class="mt-2"
                          :src="
                            require('@/assets/bugCatcher/DifficultyFire.png')
                          "
                        ></v-img>
                      </v-row>
                    </div>
                    <div class="text-center text-caption">+ 100</div>
                  </span>
                </v-card>
              </v-hover>
            </div>
            <v-btn
              class="ma-2"
              @click="clickStartGame()"
              width="260"
              :loading="api.isLoading"
              >Start game</v-btn
            >
          </div>

          <div
            :id="containerId"
            style="max-width: 480px; padding: 0; max-height: 100vh !important"
          />
          <div
            v-if="!gameInstance && !showDifficulty"
            style="position: absolute"
            class="d-flex align-center justify-center flex-column"
          >
            <v-progress-linear
              color="brown darken-1"
              indeterminate
              height="10"
              style="max-width: 200px"
              class="my-5"
            ></v-progress-linear>
            <span class="brown--text py-2">
              {{ $t("string.game_loading_msg") }}</span
            >

            <v-btn
              depressed
              color="brown lighten-3"
              class="brown--text text--darken-3"
              @click="
                () => {
                  $router.go($router.currentRoute);
                }
              "
            >
              ↻ {{ $t("action.retry") }}
            </v-btn>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<style>
.game-body {
  max-width: 480px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(49, 192, 214, 0.3);
  position: relative;
}

.dif {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>