import { Scene } from "phaser";
import store from "@/store";
// import router from "@/router";
import i18n from "@/plugins/i18n";
// import _ from "lodash";
// import Phaser from "phaser";

export default class BeforeQuestionScene extends Scene {
  constructor() {
    super({ key: "BeforeQuestionScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.round = data.round;
    this.qCount = data.qCount;
    this.questions = data.questions;
    this.score = data.score ? data.score : 0;
    this.friend = data.friend;
    this.forceFriend = data.forceFriend;
    this.countCorrect = data.countCorrect;
    this.difficulty = data.difficulty;
    this.noOfRounds = data.noOfRounds;
    this.skyPosition = data.skyPosition ? data.skyPosition : 0;
    this.bossRound = data.bossRound;
    this.bossName = data.bossName;
  }

  playerAvatarObject = [];

  create() {
    this.registerSounds();
    this.registerAnims();
    //* setup the Background  *//
    this.imgSky = this.add
      .tileSprite(0, 0, 480, 854, "imgBackground")
      .setOrigin(0);

    this.imgBG = this.add.image(240, 400, "imgBG").setScale(0.5);

    this.imgSky.tilePositionX = this.skyPosition;

    this.imgFarShip = this.add
      .image(400, 498, "imgFarShip")
      .setOrigin(0)
      .setScale(0.5);
    this.imgWave1 = this.add
      .image(-50, 330, "imgWave1")
      .setOrigin(0)
      .setScale(0.4);
    this.imgWave2 = this.add
      .image(-40, 340, "imgWave2")
      .setOrigin(0)
      .setScale(0.4);
    this.imgRodWire = this.add
      .image(297, 501, "imgRodWire")
      .setOrigin(0)
      .setScale(0.3, 0.05)
      .setDepth(7);
    this.imgRod = this.add
      .image(150, 500, "imgRod")
      .setOrigin(0)
      .setScale(0.3)
      .setDepth(7);

    this.imgRodHook = this.add
      .image(285, 540, "imgRodHook")
      .setOrigin(0)
      .setScale(0.3)
      .setDepth(7);
    this.imgBoat = this.add
      .image(50, 600, "imgBoat")
      .setOrigin(0)
      .setScale(0.4)
      .setDepth(7);

    this.imgWave3 = this.add
      .image(-20, 360, "imgWave3")
      .setOrigin(0)
      .setScale(0.4)
      .setDepth(7);

    this.imgWave4 = this.add
      .image(-50, 340, "imgWave4")
      .setOrigin(0)
      .setScale(0.5)
      .setDepth(7);

    this.imgFishingBtn = this.add
      .sprite(150, 380, "sptIdea")
      .setOrigin(0.5)
      .setDepth(7)
      .setScale(1.2)
      .setAlpha(0);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.6)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.scoreBoard = this.add.image(100, 40, "imgScorePanel").setScale(0.4);
    this.roundBoard = this.add.image(73, 85, "imgScorePanel").setScale(0.25);

    this.scoreText = this.add
      .text(
        40,
        40,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 22,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5);

    this.roundText = this.add
      .text(35, 87, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#492a12",
      })
      .setOrigin(0, 0.5);

    this.dark = this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0);

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(120, 560, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(120, 560, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color" && key != "right") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(120, 560, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }

    this.playerNameBoard = this.add.rectangle(120, 480, 180, 40, 0xffffff, 0.5);

    this.playerName = this.add
      .text(
        120,
        480,
        this.name.length > 20 ? this.name.substring(0, 12) : this.name,
        {
          color: "#000",
          fontSize: "15px",
          fontFamily: "Sassoon, sans-serif",
          align: "center",
        }
      )
      .setOrigin(0.5);

    this.itemTween1 = this.tweens.add({
      targets: [
        this.imgWave3,
        this.imgBoat,
        this.imgRod,
        // this.imgRodHook,
        this.imgRodWire,
        // this.playerName,
        // this.playerNameBoard,
      ],
      y: "-=10",
      duration: 4000,
      repeat: -1,
      yoyo: true,
    });

    this.roundPanel = [
      this.add.image(240, 350, "imgRoundPanel").setDepth(9).setScale(0),

      this.add
        .text(240, 350, i18n.t("miniGames.general.round") + " " + this.round, {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 30,
          color: "#000",
        })
        .setOrigin(0.5)
        .setDepth(9)
        .setScale(0),
    ];

    if (
      this.round == 3 &&
      this.countCorrect == 0 &&
      this.questions[this.round - 1].youtubeUrl &&
      this.questions[this.round - 1].youtubeUrl[i18n.locale]
    ) {
      this.openRecomendation();
    }
    this.startGame();

    this.itemTween2 = this.tweens.add({
      targets: [this.imgWave1, this.imgFarShip],
      y: "-=5",
      duration: 3000,
      repeat: -1,
      yoyo: true,
    });

    this.itemTween3 = this.tweens.add({
      targets: [this.imgWave2],
      y: "-=10",
      duration: 3500,
      repeat: -1,
      yoyo: true,
    });

    this.itemTween4 = this.tweens.add({
      targets: [this.imgWave4],
      y: "-=20",
      duration: 4500,
      repeat: -1,
      yoyo: true,
    });

    this.playerTween = this.tweens.add({
      targets: this.playerAvatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      y: "-=10",
      duration: 4000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });
  }

  update() {
    this.imgSky.tilePositionX += 0.2;
    if (this.playerMask && this.playerAvatarObject[0]) {
      this.playerMask.x = this.playerAvatarObject[0].x;
      this.playerMask.y = this.playerAvatarObject[0].y;
    }
  }

  fishing() {
    this.imgFishingBtn.anims.stop("animIdea");
    this.imgFishingBtn.removeInteractive();
    this.tweens.add({
      targets: this.imgFishingBtn,
      alpha: 0,
      duration: 100,
      ease: "Sine.easeInOut",
    });

    this.sfxStart.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxStart.play();
    this.tweens.add({
      targets: [this.imgRodWire],
      scaleY: 0.3,
      duration: 1000,
      onStart: () => {
        this.tweens.add({
          targets: [this.imgRodHook],
          y: "+= 230",
          duration: 1000,
        });
      },
      onComplete: () => {
        this.goQuestion();
      },
    });
  }

  startGame() {
    this.tweens.add({
      targets: this.roundPanel,
      scaleX: 1,
      scaleY: 1,
      duration: 200,
      delay: 500,
      onStart: () => {
        this.sfxRound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxRound.play();
        this.dark.setAlpha(0.5);
        this.dark.setDepth(8);
      },
      onComplete: () => {
        this.tweens.add({
          targets: this.roundPanel,
          scaleX: 0,
          scaleY: 0,
          duration: 200,
          delay: 1500,
          onComplete: () => {
            this.dark.setAlpha(0);
            this.tweens.add({
              targets: this.imgFishingBtn,
              alpha: 1,
              duration: 200,
              delay: 500,
              onComplete: () => {
                this.imgFishingBtn
                  .setInteractive({ useHandCursor: true })
                  .on("pointerdown", this.fishing, this);
                this.imgFishingBtn.play("animIdea");
              },
            });
          },
        });
      },
    });
  }

  registerSounds() {
    this.sfxSimpleClick = this.sound.add("sfxSimpleClick");
    this.sfxRound = this.sound.add("sfxRound");
    this.sfxStart = this.sound.add("sfxStart");
  }

  registerAnims() {
    this.anims.create({
      key: "animIdea",
      frames: this.anims.generateFrameNumbers("sptIdea", {
        frames: [0, 1],
      }),
      repeat: -1,
      frameRate: 5,
    });
  }

  goQuestion() {
    this.playerAvatarObject = [];

    this.registry.destroy();
    this.events.off();
    this.scene.stop("BeforeQuestionScene");

    this.scene.start("QuestionScene", {
      avatar: this.avatar,
      name: this.name,
      round: this.round,
      score: this.score,
      questions: this.questions,
      friend: this.friend,
      difficulty: this.difficulty,
      countCorrect: this.countCorrect,
      noOfRounds: this.noOfRounds,
      skyPosition: this.imgSky.tilePositionX,
      bossRound: this.bossRound,
      bossName: this.bossName,
    });
  }

  clickSetting() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "BeforeQuestionScene",
    });
  }

  openRecomendation() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.scene.pause();
    this.scene.launch("Recommendation", {
      sceneName: "BeforeQuestionScene",
      youtubeUrl: this.questions[this.round - 1].youtubeUrl[i18n.locale],
    });
  }
}
