import { Scene } from "phaser";
import Phaser from "phaser";
import store from "@/store";
import i18n from "@/plugins/i18n";

export default class PlayScene extends Scene {
  constructor() {
    super({ key: "PlayScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.score = data.score;
    this.round = data.round;
    this.questions = data.questions;
    this.bossRound = data.bossRound;
    this.friend = data.friend;
    this.noOfRounds = data.noOfRounds;
    this.forceFriend = data.forceFriend;
    this.difficulty = data.difficulty;
    this.countCorrect = data.countCorrect;
    this.avatarX = data.avatarX;
  }

  avatarObject = [];
  customerCount = 0;
  exitCustomerCount = 0;
  customerList = [];
  gameOngoing = true;
  isFriendArrived = false;
  isFriendExit = false;

  create() {
    console.log(store)
    //* setup the Background  *//
    this.imgBackground = this.add
      .image(0, 0, "imgBackground")
      .setDisplaySize(480, 854)
      .setOrigin(0);
    this.imgDoor = this.add.image(0, 0, "imgDoor").setOrigin(0).setDepth(2);
    this.sptDoor = this.add.sprite(233, 150, "sptDoor").setScale(0.9);
    this.imgCounter = this.add
      .image(0, 550, "imgCounter")
      .setOrigin(0)
      .setDepth(2);
    this.imgWoodPlane1 = this.add.image(97, 690, "imgWoodPlane").setDepth(2);
    this.imgWoodPlane2 = this.add.image(237, 690, "imgWoodPlane").setDepth(2);
    this.imgWoodPlane3 = this.add.image(377, 690, "imgWoodPlane").setDepth(2);
    this.scoreBoard = this.add
      .image(80, 35, "imgScorePanel")
      .setScale(0.3)
      .setDepth(2);
    this.resultBoard = this.add
      .image(70, 70, "imgScorePanel")
      .setScale(0.25, 0.2)
      .setDepth(2);
    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.6)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.scoreText = this.add
      .text(
        30,
        35,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 18,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5)
      .setDepth(2);
    this.roundText = this.add
      .text(30, 70, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#492a12",
      })
      .setOrigin(0, 0.5)
      .setDepth(2);

    this.feedback = this.add
      .text(240, 390, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 48,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(5)
      .setShadow(3, 3, "#000", 0, true, true);

    // * setup the Sprite Animations *//
    this.anims.create({
      key: "animDoorOpen",
      frames: this.anims.generateFrameNumbers("sptDoor", {
        frames: [0, 1, 2, 3, 4, 5, 5, 5, 5, 5, 4, 3, 2, 1, 0],
      }),
      frameRate: 12,
    });

    this.anims.create({
      key: "animStoveOn",
      frames: this.anims.generateFrameNumbers("sptStovePan", {
        frames: [1, 2],
      }),
      frameRate: 3,
      repeat: -1,
    });

    this.anims.create({
      key: "animCooking",
      frames: this.anims.generateFrameNumbers("sptStoveActions", {
        frames: [3, 4, 5, 0],
      }),
      frameRate: 3,
      repeat: -1,
    });

    this.anims.create({
      key: "animCooked",
      frames: this.anims.generateFrameNumbers("sptStoveActions", {
        frames: [1, 2],
      }),
      frameRate: 2,
      repeat: -1,
    });

    this.anims.create({
      key: "animBurning",
      frames: this.anims.generateFrameNumbers("sptStoveActions", {
        frames: [8, 9],
      }),
      frameRate: 2,
      repeat: -1,
    });

    this.anims.create({
      key: "animShine",
      frames: this.anims.generateFrameNumbers("sptShine", {
        frames: [0, 2],
      }),
      repeat: -1,
      frameRate: 5,
    });

    this.anims.create({
      key: "animOverCooked",
      frames: this.anims.generateFrameNumbers("sptStoveActions", {
        frames: [6, 7],
      }),
      frameRate: 2,
      repeat: -1,
    });

    this.anims.create({
      key: "animFireExting",
      frames: this.anims.generateFrameNumbers("sptFireExtingActions", {
        frames: [0, 1, 2, 3, 4, 4, 3, 4, 4, 3, 4, 4, 3, 2, 1, 0],
      }),
      frameRate: 10,
    });

    this.sptIdea = this.add
      .sprite(0, 0, "sptIdea")
      .setAlpha(0)
      .setDepth(8)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", this.goQuestion, this);
    this.anims.create({
      key: "animIdea",
      frames: this.anims.generateFrameNumbers("sptIdea", {
        frames: [0, 1],
      }),
      repeat: -1,
      frameRate: 5,
    });

    //* declare the SFXs *//
    this.sfxScoreIncrease = this.sound.add("sfxScoreIncrease");
    this.sfxSimpleClick = this.sound.add("sfxSimpleClick");
    this.sfxGo = this.sound.add("sfxGo");
    this.sfxDoor = this.sound.add("sfxDoor");
    this.sfxBubble = this.sound.add("sfxBubble");
    this.sfxCookingBell = this.sound.add("sfxCookingBell");
    this.sfxShine = this.sound.add("sfxShine");
    this.sfxCharge = this.sound.add("sfxCharge");
    this.sfxSkill = this.sound.add("sfxSkill");
    this.sfxSkillX = this.sound.add("sfxSkillX");
    this.sfxWrong = this.sound.add("sfxWrong");
    this.sfxExting = this.sound.add("sfxExting");
    this.sfxBin = this.sound.add("sfxBin");

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 770, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 770, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 770, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }
    this.tweens.add({
      targets: this.avatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    //* Energy bar *//
    this.sptEbar = this.add
      .sprite(this.avatarX + 80, 780, "sptEbar")
      .setDepth(7)
      .setFrame(0);

    // this.energyLineX = new Phaser.Geom.Line(-50, 760, 50, 760);
    // this.energyGraphicsX = this.add.graphics({
    //   lineStyle: { width: 10, color: 0x916763 },
    // });
    // this.energyGraphicsX.strokeLineShape(this.energyLineX).setDepth(7);
    // this.energyGraphicsX.x = this.avatarObject[0].x + 100;

    // this.energyLine = new Phaser.Geom.Line(-49, 760, -49, 760);
    // this.energyGraphics = this.add.graphics({
    //   lineStyle: { width: 8, color: 0xff3624 },
    // });
    // this.energyGraphics.strokeLineShape(this.energyLine).setDepth(7);
    // this.energyGraphics.x = this.avatarObject[0].x + 100;

    //* Stoves and raw foods *//

    this.stoveGroup = this.add.group();

    for (let index = 0; index < 3; index++) {
      const stove = this.add
        .sprite(140 * (index + 1) - 50, 605, "sptStovePan")
        .setFrame(0)
        .setDepth(2);
      stove.status = "OFF";
      stove.actions = this.add
        .sprite(140 * (index + 1) - 44, 580, "sptStoveActions")
        .setFrame(0)
        .setDepth(4);
      stove._id = index + 1;
      this.stoveGroup.add(stove);
    }

    this.imgRawCrab = this.add
      .sprite(97, 690, "sptCrab")
      .setFrame(0)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => this.clickRawFood("CRAB"), this);
    this.imgRawWing = this.add
      .sprite(237, 690, "sptWing")
      .setFrame(0)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => this.clickRawFood("WING"), this);
    this.imgRawSteak = this.add
      .sprite(377, 690, "sptSteak")
      .setFrame(0)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => this.clickRawFood("STEAK"), this);

    //* Tools *//

    this.sptFireExting = this.add
      .sprite(420, 795, "sptFireExting")
      .setDepth(4)
      .setFrame(0)
      .setInteractive({ useHandCursor: true, draggable: true });
    this.sptFireExting.name = "Exting";
    this.sptFireExting._x = 420;
    this.sptFireExting._y = 795;

    this.imgBin = this.add.image(60, 810, "imgTrashBin").setDepth(2);

    //* Customers Group *//
    this.customerGroup = this.add.group();
    this.cookingFoodGroup = this.add.group();

    //* Game transitions starts here *//
    this.startGame();

    // * Dragging settings *//
    this.input.on("drag", (pointer, gameObject, dragX, dragY) => {
      if (this.gameOngoing) {
        gameObject.x = dragX;
        gameObject.y = dragY;
      }

      if (gameObject.name == "Exting") {
        gameObject.setScale(0.8);
      }

      if (
        Phaser.Geom.Intersects.RectangleToRectangle(
          gameObject.getBounds(),
          this.imgBin.getBounds()
        )
      ) {
        this.imgBin.setScale(1.1);
      } else {
        this.imgBin.setScale(1);
      }

      this.customerGroup.getChildren().forEach(function (customer) {
        if (!customer.isFriend) {
          if (
            Phaser.Geom.Intersects.RectangleToRectangle(
              gameObject.getBounds(),
              new Phaser.Geom.Rectangle(
                customer.order.itemBbl.getBounds().x + 10,
                customer.order.itemBbl.getBounds().y + 20,
                customer.order.itemBbl.getBounds().width - 20,
                customer.order.itemBbl.getBounds().height - 40
              )
            ) ||
            Phaser.Geom.Intersects.RectangleToRectangle(
              gameObject.getBounds(),
              new Phaser.Geom.Rectangle(
                customer.getBounds().x + 40,
                customer.getBounds().y + 40,
                10,
                10
              )
            )
          ) {
            if (
              gameObject.status == "DONE" &&
              customer.order.item == gameObject.food
            ) {
              customer.order.itemBbl.setTint(0x7affc1);
            } else {
              customer.order.itemBbl.setTint(0xff7a7a);
            }
          } else {
            customer.order.itemBbl.clearTint();
          }
        }
      }, this);
    });

    this.input.on("dragstart", (pointer, gameObject) => {
      if (this.gameOngoing) {
        if (!(gameObject.name && gameObject.name == "Exting")) {
          gameObject.dragging = true;
          gameObject._status = "";
          if (gameObject.status == "RAW") {
            gameObject.setFrame(4);
          } else if (gameObject.status == "COOK") {
            gameObject.setFrame(5);
          } else if (gameObject.status == "DONE") {
            gameObject.setFrame(6);
          } else if (
            gameObject.status == "OVERCOOK" ||
            gameObject.status == "BURNING"
          ) {
            gameObject.setFrame(7);
          }
          this.time.removeEvent(gameObject.timeEvent);
          const avaStove = this.stoveGroup
            .getChildren()
            .find((stove) => stove._id == gameObject.stoveId);
          avaStove.actions.anims.stop("animCooked");
          avaStove.actions.setFrame(0);
        }
      }
    });

    this.input.on("dragend", (pointer, gameObject) => {
      this.customerGroup.getChildren().forEach(function (customer) {
        if (!customer.isFriend) {
          if (
            Phaser.Geom.Intersects.RectangleToRectangle(
              gameObject.getBounds(),
              new Phaser.Geom.Rectangle(
                customer.order.itemBbl.getBounds().x + 10,
                customer.order.itemBbl.getBounds().y + 20,
                customer.order.itemBbl.getBounds().width - 20,
                customer.order.itemBbl.getBounds().height - 40
              )
            ) ||
            Phaser.Geom.Intersects.RectangleToRectangle(
              gameObject.getBounds(),
              new Phaser.Geom.Rectangle(
                customer.getBounds().x + 40,
                customer.getBounds().y + 40,
                10,
                10
              )
            )
          ) {
            if (customer.notServed) {
              if (
                gameObject.status == "DONE" &&
                customer.order.item == gameObject.food &&
                customer.order.waitingTime > 0
              ) {
                this.time.removeEvent(gameObject.timeEvent);
                gameObject.destroy();
                const avaStove = this.stoveGroup
                  .getChildren()
                  .find((stove) => stove._id == gameObject.stoveId);
                avaStove.status = "OFF";
                avaStove.setFrame(0);
                avaStove.stop("animStoveOn");
                avaStove.actions.anims.stop("animCooked");
                avaStove.actions.setFrame(0);

                this.customerServed(customer);
              } else {
                if (customer.faceMask) {
                  customer.faceMask.destroy();
                }
                customer.faceMask = this.add
                  .image(customer.x, customer.y - 15, "maskWearyx")
                  .setScale(0.25)
                  .setDepth(6);

                this.sfxWrong.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );
                this.sfxWrong.play();

                if (
                  customer.order.waitingTimeText &&
                  customer.order.waitingTime
                ) {
                  if (customer.order.waitingTime > 5) {
                    customer.order.waitingTime -= 5;
                  } else if (customer.order.waitingTime > 1) {
                    customer.order.waitingTime = 1;
                  }

                  customer.order.waitingTimeText.setText(
                    customer.order.waitingTime + " s"
                  );
                }
                this.time.delayedCall(800, () => {
                  customer.faceMask.destroy();
                  customer.order.itemBbl.clearTint();
                });
              }
            }
          }
        }
      }, this);

      if (gameObject.name && gameObject.name == "Exting") {
        this.stoveGroup.getChildren().forEach(function (stove) {
          if (
            Phaser.Geom.Intersects.RectangleToRectangle(
              gameObject.getBounds(),
              stove.actions.getBounds()
            )
          ) {
            if (stove.actions.isBurning) {
              stove.actions.isBurning = false;
              let fireExting = this.add
                .sprite(stove.x, stove.y - 15, "sptFireExtingActions")
                .setFrame(0)
                .setScale(0.8)
                .setDepth(5)
                .setOrigin(0.2, 0.7);
              this.sfxExting.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.sfxExting.play();
              fireExting.play("animFireExting").on("animationcomplete", () => {
                fireExting.setAlpha(0);
                fireExting.destroy();
                stove.actions.anims.stop("animBurning");
                stove.actions.setFrame(0);
                stove.actions.removeInteractive();
                stove.setFrame(0);
                stove.stop("animStoveOn");
                if (stove.icon) {
                  stove.icon.destroy();
                }
                if (stove.actions.foodIndex >= 0) {
                  stove.icon = this.add
                    .image(stove.x + 40, stove.y, "imgTrashBinIcon")
                    .setOrigin(0, 0)
                    .setDepth(3)
                    .setScale(0.9);
                } else {
                  stove.status = "OFF";
                }
              });
            }
          }
        }, this);
      }

      if (
        Phaser.Geom.Intersects.RectangleToRectangle(
          gameObject.getBounds(),
          this.imgBin.getBounds()
        )
      ) {
        this.sfxBin.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxBin.play();
        gameObject.destroy();
        const avaStove = this.stoveGroup
          .getChildren()
          .find((stove) => stove._id == gameObject.stoveId);
        avaStove.status = "OFF";
        avaStove.setFrame(0);
        avaStove.stop("animStoveOn");
        avaStove.actions.anims.stop("animCooked");
        avaStove.actions.setFrame(0);
        this.imgBin.setScale(1);
        if (avaStove.icon) {
          avaStove.icon.destroy();
        }
      }

      if (gameObject) {
        if (gameObject.name == "Exting") {
          gameObject.setScale(1);
        }
        gameObject.x = gameObject._x;
        gameObject.y = gameObject._y;
        gameObject.dragging = false;
        const avaStove = this.stoveGroup
          .getChildren()
          .find((stove) => stove._id == gameObject.stoveId);
        if (gameObject.status == "RAW") {
          gameObject.setFrame(0);
          if (avaStove.status == "ON") avaStove.actions.play("animCooking");
        } else if (gameObject.status == "COOK") {
          gameObject.setFrame(1);
          if (avaStove.status == "ON") avaStove.actions.play("animCooking");
        } else if (gameObject.status == "DONE") {
          gameObject.setFrame(2);
          if (avaStove.status == "ON") avaStove.actions.play("animCooked");
        } else if (gameObject.status == "OVERCOOK") {
          gameObject.setFrame(3);
          if (avaStove.status == "ON") avaStove.actions.play("animOverCooked");
        } else if (gameObject.status == "BURNING") {
          gameObject.setFrame(3);
        }
      }
    });
  }

  update() {
    if (this.avatarMask && this.avatarObject[0]) {
      this.avatarMask.x = this.avatarObject[0].x;
      this.avatarMask.y = this.avatarObject[0].y;
    }
    this.customerGroup.getChildren().forEach(function (customer) {
      if (customer && customer.isFriend && customer.avatar[0]) {
        customer.x = customer.avatar[0].x + 70;
        customer.y = customer.avatar[0].y - 30;
        if (customer.shine) {
          customer.shine.x = customer.avatar[0].x + 70;
          customer.shine.y = customer.avatar[0].y - 30;
        }
        if (customer.name) {
          customer.name.x = customer.avatar[0].x;
          customer.name.y = customer.avatar[0].y + 60;
        }
      } else {
        if (customer && customer.order && customer.order.itemBbl) {
          customer.order.itemBbl.x = customer.x + 40;
          customer.order.itemBbl.y = customer.y - 30;
        }

        if (customer && customer.order && customer.order.itemSpt) {
          customer.order.itemSpt.x = customer.x + 54;
          customer.order.itemSpt.y = customer.y - 35;
        }

        if (customer && customer.order && customer.order.feedback) {
          customer.order.feedback.x = customer.x + 90;
          customer.order.feedback.y = customer.y - 30;
        }

        if (customer && customer.order && customer.order.waitingTimeText) {
          customer.order.waitingTimeText.x = customer.x + 80;
          customer.order.waitingTimeText.y = customer.y - 13;
        }

        if (customer && customer.order && customer.order.clock) {
          customer.order.clock.x = customer.x + 68;
          customer.order.clock.y = customer.y - 3;
        }

        if (customer && customer.freezeImg) {
          customer.freezeImg.x = customer.x;
          customer.freezeImg.y = customer.y;
        }

        if (customer && customer.faceMask) {
          customer.faceMask.x = customer.x;
          customer.faceMask.y = customer.y - 5;
        }
      }
    }, this);

    this.cookingFoodGroup.getChildren().forEach(function (cookingFood, index) {
      if (!cookingFood.dragging) {
        if (cookingFood.status == "RAW") {
          if (cookingFood._status != "RAW") {
            cookingFood._status = "RAW";
            cookingFood.timeEvent = this.time.delayedCall(
              cookingFood.cookTime / 2,
              () => {
                cookingFood.status = "COOK";
                cookingFood.setFrame(1);
              }
            );
          }
        }

        if (cookingFood.status == "COOK") {
          if (cookingFood._status != "COOK") {
            cookingFood._status = "COOK";
            cookingFood.timeEvent = this.time.delayedCall(
              cookingFood.cookTime / 2,
              () => {
                cookingFood.status = "DONE";
                cookingFood.setFrame(2);
                const avaStove = this.stoveGroup
                  .getChildren()
                  .find((stove) => stove._id == cookingFood.stoveId);
                avaStove.actions.anims.stop("animCooking");
                avaStove.actions.play("animCooked");
                this.sfxCookingBell.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );
                this.sfxCookingBell.play();
              }
            );
          }
        }
        if (this.difficulty > 1) {
          if (cookingFood.status == "DONE") {
            if (cookingFood._status != "OVERCOOK") {
              cookingFood._status = "OVERCOOK";
              cookingFood.timeEvent = this.time.delayedCall(
                this.difficulty == 3 ? 3000 : 5000,
                () => {
                  if (cookingFood) {
                    cookingFood.status = "OVERCOOK";
                    cookingFood.setFrame(3);
                    const avaStove = this.stoveGroup
                      .getChildren()
                      .find((stove) => stove._id == cookingFood.stoveId);
                    avaStove.actions.anims.stop("animCooked");
                    avaStove.actions.play("animOverCooked");
                    if (avaStove.icon) {
                      avaStove.icon.destroy();
                    }
                    avaStove.icon = this.add
                      .image(avaStove.x + 40, avaStove.y, "imgTrashBinIcon")
                      .setOrigin(0, 0)
                      .setDepth(3)
                      .setScale(0.9);
                  }
                }
              );
            }
          }

          if (cookingFood.status == "OVERCOOK") {
            if (cookingFood._status != "BURNING") {
              cookingFood._status = "BURNING";
              cookingFood.timeEvent = this.time.delayedCall(
                this.difficulty == 3 ? 1500 : 3000,
                () => {
                  if (cookingFood) {
                    cookingFood.status = "BURNING";
                    cookingFood.setFrame(3);
                    const avaStove = this.stoveGroup
                      .getChildren()
                      .find((stove) => stove._id == cookingFood.stoveId);
                    avaStove.actions.anims.stop("animOverCooked");
                    avaStove.actions.play("animBurning");
                    avaStove.actions.setInteractive();
                    avaStove.actions.isBurning = true;
                    avaStove.actions.foodIndex = index;
                    if (avaStove.icon) {
                      avaStove.icon.destroy();
                    }
                    avaStove.icon = this.add
                      .image(avaStove.x + 40, avaStove.y, "imgFireExtingIcon")
                      .setOrigin(0, 0)
                      .setDepth(3)
                      .setScale(0.9);
                  }
                }
              );
            }
          }
        }
      }
    }, this);
  }

  startGame() {
    this.customerList = [1, 1, 2, 2, 3, 3];
    for (let i = this.customerList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.customerList[i], this.customerList[j]] = [
        this.customerList[j],
        this.customerList[i],
      ];
    }
    this.customerEnteringEvent = this.time.addEvent({
      callback: () => {
        this.customerEnter();
        this.time.removeEvent(this.customerEnteringEvent);
        this.customerEnteringEvent = this.time.addEvent({
          delay: (4 + Math.random()) * 1000,
          callback: () => {
            if (this.gameOngoing) {
              if (this.customerGroup.children.size < 6) this.customerEnter();
            } else {
              this.time.removeEvent(this.customerEnteringEvent);
            }
          },
          callbackScope: this,
          loop: true,
        });
      },
      callbackScope: this,
    });
  }

  finishGame() {
    //
  }

  customerEnter() {
    if (
      (this.customerCount == 5 || this.customerCount == 6) &&
      !this.isFriendArrived &&
      this.friend &&
      this.friend.avatar &&
      this.friend.avatar.body &&
      (Math.floor(Math.random() * 10) > 7 || this.forceFriend)
    ) {
      this.isFriendArrived = true;
      let magic = Math.floor(Math.random() * 3);
      let friend = this.add
        .sprite(240, -100, "sptMagicBalls")
        .setDepth(2)
        .setFrame(magic)
        .setScale(0)
        .setInteractive({ useHandCursor: true });

      switch (magic) {
        case 0:
          friend.on("pointerdown", this.superMagicBall, this);
          break;
        case 1:
          friend.on("pointerdown", this.iceBall, this);
          break;
        default:
          friend.on("pointerdown", this.fireBall, this);
          break;
        // default:
        //   friend.on("pointerdown", this.magicBall, this);
        //   break;
      }

      friend.shine = this.add
        .sprite(240, -100, "sptShine")
        .setDepth(1)
        .setScale(0);

      friend.isFriend = true;
      friend.name = this.add
        .text(240, -100, this.friend.name, {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 15,
          color: "#ffffff",
        })
        .setOrigin(0.5)
        .setDepth(1);

      let friendAvatar = [];

      for (let key of Object.keys(this.friend.avatar)) {
        if (this.friend.avatar[key]) {
          if (key == "body") {
            friendAvatar.push(
              this.add.image(240, -100, key).setScale(0.21, 0.21).setDepth(1)
            );
            friendAvatar.push(
              this.add
                .image(240, -100, key)
                .setTint("0x" + this.friend.avatar.color)
                .setScale(0.21, 0.21)
                .setAlpha(0.3)
                .setDepth(1)
            );
          } else if (key != "color") {
            friendAvatar.push(
              this.add.image(240, -100, key).setScale(0.21, 0.21).setDepth(1)
            );
          }
        }
      }
      friend.avatar = friendAvatar;

      let tokenNum = 1;

      for (let index = 1; index <= 6; index++) {
        if (
          !this.customerGroup
            .getChildren()
            .some((obj) => obj.tokenNumber === index)
        ) {
          tokenNum = index;
          break;
        }
      }
      friend.tokenNumber = tokenNum;

      this.customerGroup.add(friend);
      this.tweens.add({
        targets: friend.avatar,
        scaleY: 0.213,
        scaleX: 0.207,
        duration: 500,
        ease: "Sine.easeInOut",
        repeat: -1,
        yoyo: true,
      });

      this.tweens.add({
        targets: friend.avatar,
        y: 150,
        duration: 800,
        ease: "Sine.easeInOut",
        onStart: () => {
          this.doorOpen();
        },
        onComplete: () => {
          if (!friend.moving) this.moveCustomer(friend);
        },
      });
    } else {
      let cno = Math.floor(Math.random() * 36) + 1;
      let customer = this.add
        .image(240, -100, `imgCustomer${cno}`)
        .setScale(0.85)
        .setDepth(1);

      let tokenNum = 1;

      for (let index = 1; index <= 6; index++) {
        if (
          !this.customerGroup
            .getChildren()
            .some((obj) => obj.tokenNumber === index)
        ) {
          tokenNum = index;
          break;
        }
      }
      customer.tokenNumber = tokenNum;
      customer.notServed = true;

      let waitingTime;
      if (this.customerList[this.customerCount] == 2) {
        switch (this.difficulty) {
          case 3:
            waitingTime = 10;
            break;
          case 2:
            waitingTime = 15;
            break;
          default:
            waitingTime = 30;
            break;
        }
      } else if (this.customerList[this.customerCount] == 3) {
        switch (this.difficulty) {
          case 3:
            waitingTime = 12;
            break;
          case 2:
            waitingTime = 20;
            break;
          default:
            waitingTime = 40;
            break;
        }
      } else {
        switch (this.difficulty) {
          case 3:
            waitingTime = 7;
            break;
          case 2:
            waitingTime = 10;
            break;
          default:
            waitingTime = 20;
            break;
        }
      }

      customer.order = {
        item:
          this.customerList[this.customerCount] == 2
            ? "WING"
            : this.customerList[this.customerCount] == 3
            ? "STEAK"
            : "CRAB",
        itemBbl: this.add
          .sprite(240, -100, "sptOrderBubble")
          .setFrame(0)
          .setScale(0)
          .setOrigin(0, 0.5),
        itemSpt:
          this.customerList[this.customerCount] == 2
            ? this.add.image(240, -100, "imgWing").setScale(0).setOrigin(0, 0.5)
            : this.customerList[this.customerCount] == 3
            ? this.add
                .image(240, -100, "imgSteak")
                .setScale(0)
                .setOrigin(0, 0.5)
            : this.add
                .image(240, -100, "imgCrab")
                .setScale(0)
                .setOrigin(0, 0.5),
        waitingTime: waitingTime,
        waitingTimeText: this.add
          .text(240, -100, waitingTime + " s", {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 18,
            fill: "#000",
          })
          .setScale(0),
        clock: this.add
          .image(240, -100, "imgFreezeClock")
          .setAlpha(0)
          .setScale(0),
      };
      this.customerGroup.add(customer);
      this.tweens.add({
        targets: customer,
        scaleY: 0.86,
        scaleX: 0.84,
        duration: 500,
        ease: "Sine.easeInOut",
        repeat: -1,
        yoyo: true,
      });

      this.tweens.add({
        targets: customer,
        y: 150,
        duration: 800,
        ease: "Sine.easeInOut",
        onStart: () => {
          this.doorOpen();
        },
        onComplete: () => {
          if (!customer.moving) this.moveCustomer(customer);
        },
      });

      this.customerCount++;
    }
  }

  moveCustomer(customer) {
    let x = 0;
    let y = 0;
    switch (customer.tokenNumber) {
      case 1:
        x = 100;
        y = 480;
        break;
      case 2:
        x = 320;
        y = 480;
        break;
      case 3:
        x = 100;
        y = 350;
        break;
      case 4:
        x = 320;
        y = 350;
        break;
      case 5:
        x = 100;
        y = 220;
        break;
      case 6:
        x = 320;
        y = 220;
        break;
      default:
        break;
    }

    if (customer.isFriend) {
      if (customer.avatar[0].x != x || customer.avatar[0].y != y) {
        customer.moving = true;
        this.tweens.add({
          targets: customer.avatar,
          y: y,
          x: x,
          duration: 1000,
          delay: 1000,
          ease: "Sine.easeInOut",
          onComplete: () => {
            customer.moving = false;
            this.tweens.add({
              targets: [customer, customer.shine],
              scaleY: 0.9,
              scaleX: 0.9,
              duration: 200,
              ease: "Sine.easeInOut",
              onStart: () => {
                this.sfxSkillX.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );
                this.sfxSkillX.play();
                this.tweens.add({
                  targets: customer.shine,
                  scaleY: 1.2,
                  scaleX: 1.2,
                  duration: 200,
                  ease: "Sine.easeInOut",
                });
              },
              onComplete: () => {
                customer.shine.play("animShine");
                this.tweens.add({
                  targets: customer.shine,
                  angle: "+=360",
                  duration: 5000,
                  ease: "Linear",
                  repeat: -1,
                });
                this.tweens.add({
                  targets: customer,
                  scaleY: 0.8,
                  scaleX: 0.8,
                  duration: 500,
                  repeat: -1,
                  yoyo: true,
                  ease: "Sine.easeInOut",
                });

                this.time.delayedCall(8000, () => {
                  if (customer && !this.isFriendExit) {
                    customer.visible = false;
                    customer.shine.visible = false;
                    this.time.delayedCall(1000, () => {
                      if (customer && !this.isFriendExit) {
                        this.customerExit(customer);
                      }
                    });
                  }
                });
              },
            });
          },
        });
      }
    } else {
      if (customer.x != x || customer.y != y) {
        customer.moving = true;
        this.tweens.add({
          targets: customer,
          y: y,
          x: x,
          duration: 1000,
          delay: 1000,
          ease: "Sine.easeInOut",
          onComplete: () => {
            customer.moving = false;
            if (!customer.isBubble) {
              customer.isBubble = true;
              this.tweens.add({
                targets: [
                  customer.order.itemBbl,
                  customer.order.waitingTimeText,
                  customer.order.clock,
                ],
                scaleY: 1,
                scaleX: 1,
                duration: 200,
                ease: "Sine.easeInOut",
                onStart: () => {
                  this.sfxBubble.setVolume(
                    store.state.settings.data.audio.sfx *
                      store.state.settings.data.audio.master
                  );
                  this.sfxBubble.play();
                },
                onComplete: () => {
                  if (!customer.freeze) {
                    this.customerCoundown(customer);
                  }
                },
              });
              this.tweens.add({
                targets: customer.order.itemSpt,
                scaleY: 0.9,
                scaleX: 0.9,
                duration: 200,
                ease: "Sine.easeInOut",
              });
            }
          },
        });
      }
    }
  }

  refreshToken(removedToken) {
    if (removedToken == 1) {
      let customer = this.customerGroup
        .getChildren()
        .find((customer) => customer.tokenNumber == 3);
      if (customer) {
        customer.tokenNumber = 1;
        this.moveCustomer(customer);
        this.refreshToken(3);
      }
    }

    if (removedToken == 2) {
      let customer = this.customerGroup
        .getChildren()
        .find((customer) => customer.tokenNumber == 4);
      if (customer) {
        customer.tokenNumber = 2;
        this.moveCustomer(customer);
        this.refreshToken(4);
      }
    }

    if (removedToken == 3) {
      let customer = this.customerGroup
        .getChildren()
        .find((customer) => customer.tokenNumber == 5);
      if (customer) {
        customer.tokenNumber = 3;
        this.moveCustomer(customer);
      }
    }

    if (removedToken == 4) {
      let customer = this.customerGroup
        .getChildren()
        .find((customer) => customer.tokenNumber == 6);
      if (customer) {
        customer.tokenNumber = 4;
        this.moveCustomer(customer);
      }
    }
  }

  customerExit(customer) {
    if (customer.isFriend) {
      const token = customer.tokenNumber;

      this.tweens.add({
        targets: [customer, customer.name],
        alpha: 0,
        duration: 100,
        ease: "Sine.easeInOut",
        onStart: () => {
          this.tweens.add({
            targets: customer.avatar,
            alpha: 0,
            duration: 100,
            ease: "Sine.easeInOut",
          });
        },
        onComplete: () => {
          customer.avatar.forEach((avatar) => {
            avatar.destroy();
          });
          customer.name.destroy();
          customer.destroy();
          this.refreshToken(token);
        },
      });
    } else {
      this.exitCustomerCount++;
      const token = customer.tokenNumber;

      this.tweens.add({
        targets: [customer, customer.order.feedback, customer.order.itemBbl],
        alpha: 0,
        duration: 100,
        ease: "Sine.easeInOut",
        onComplete: () => {
          customer.order.waitingTimeText.destroy();
          if (customer.freezeImg) {
            customer.freezeImg.destroy();
          }
          customer.order.clock.destroy();
          customer.order.feedback.destroy();
          customer.order.itemBbl.destroy();
          customer.destroy();
          this.refreshToken(token);
          if (this.exitCustomerCount >= 6 && this.gameOngoing) {
            this.time.removeAllEvents();
            this.time.delayedCall(200, () => {
              this.sfxCharge.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.sfxCharge.play();
              this.gameOngoing = false;

              // this.energyLineX.visible = false;
              // this.energyGraphicsX.visible = false;
              // this.energyLine.visible = false;
              // this.energyGraphics.visible = false;

              this.add
                .rectangle(240, 427, 480, 854, 0x000000)
                .setAlpha(0.7)
                .setDepth(4);

              this.sfxShine.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.sfxShine.play();
              this.sfxShine.loop = true;

              this.add
                .sprite(
                  this.avatarObject[0].x - 20,
                  this.avatarObject[0].y + 20,
                  "sptStoveActions"
                )
                .setFrame(0)
                .setDepth(6)
                .setScale(1.2)
                .play("animCooked");

              this.add
                .sprite(
                  this.avatarObject[0].x - 10,
                  this.avatarObject[0].y - 20,
                  "sptStoveActions"
                )
                .setFrame(0)
                .setDepth(6)
                .setScale(1.2)
                .play("animCooked");

              this.add
                .sprite(
                  this.avatarObject[0].x + 20,
                  this.avatarObject[0].y + 20,
                  "sptStoveActions"
                )
                .setFrame(0)
                .setDepth(6)
                .setScale(1.2)
                .play("animCooked");

              this.add
                .sprite(
                  this.avatarObject[0].x - 10,
                  this.avatarObject[0].y - 20,
                  "sptStoveActions"
                )
                .setFrame(0)
                .setDepth(6)
                .setScale(1.2)
                .play("animCooked")
                .setInteractive({ cursor: "pointer" })
                .on("pointerdown", this.goQuestion, this);

              this.sptEbar
                .setFrame(7)
                .setInteractive({ cursor: "pointer" })
                .on("pointerdown", this.goQuestion, this);

              this.sptIdea.y = this.avatarObject[0].y - 35;
              this.sptIdea.x = this.avatarObject[0].x + 95;
              this.sptIdea.setAlpha(1);
              this.sptIdea.play("animIdea");
            });
          }

          this.sptEbar.setFrame(this.exitCustomerCount);

          // this.energyGraphics.clear();
          // this.energyLine.x2 = -49 + (98 * this.exitCustomerCount) / 6;
          // this.energyGraphics.strokeLineShape(this.energyLine);
        },
      });
    }
  }

  customerCoundown(customer) {
    customer.timeEvent = this.time.addEvent({
      delay: 1000,
      callback: () => {
        if (customer.notServed) {
          customer.order.waitingTime -= 1;
          customer.order.waitingTimeText.setText(
            customer.order.waitingTime + " s"
          );
          if (!this.gameOngoing) {
            this.time.removeEvent(customer.timeEvent);
          }

          if (customer.order.waitingTime <= 0) {
            customer.done = true;
            this.time.removeEvent(customer.timeEvent);
            this.tweens.add({
              targets: customer.order.itemSpt,
              scaleY: 0,
              scaleX: 0,
              duration: 200,
              ease: "Sine.easeInOut",
            });

            if (customer.faceMask) {
              customer.faceMask.destroy();
            }
            customer.faceMask = this.add
              .image(customer.x, customer.y - 15, "maskWearyx")
              .setScale(0.25)
              .setDepth(4);

            customer.order.itemSpt.destroy();
            customer.order.waitingTimeText.visible = false;
            customer.order.clock.visible = false;

            if (customer.order.feedback) {
              customer.order.feedback.destroy();
            }
            customer.order.feedback = this.add
              .sprite(customer.x + 90, customer.y - 35, "sptHeart")
              .setScale(0)
              .setDepth(4)
              .setFrame(1);
            this.tweens.add({
              targets: customer.order.feedback,
              scaleY: 0.9,
              scaleX: 0.9,
              duration: 200,
              ease: "Sine.easeInOut",
            });

            this.time.delayedCall(500, () => {
              customer.faceMask.destroy();
              this.customerExit(customer);
            });
          }
        }
      },
      callbackScope: this,
      loop: true,
    });
  }

  customerServed(customer) {
    customer.done = true;
    customer.notServed = false;

    if (customer.faceMask) {
      customer.faceMask.destroy();
    }
    customer.faceMask = this.add
      .image(customer.x, customer.y - 15, "maskHappyx")
      .setScale(0.25)
      .setDepth(4);
    this.time.removeEvent(customer.timeEvent);

    customer.order.itemSpt.destroy();
    customer.order.waitingTimeText.visible = false;
    customer.order.clock.visible = false;

    if (customer.order.feedback) {
      customer.order.feedback.destroy();
    }

    customer.order.feedback = this.add
      .sprite(customer.x + 65, customer.y - 30, "sptHeart")
      .setScale(0)
      .setDepth(4)
      .setFrame(0);
    this.tweens.add({
      targets: customer.order.feedback,
      scaleY: 0.9,
      scaleX: 0.9,
      duration: 200,
      ease: "Sine.easeInOut",
    });

    this.time.delayedCall(500, () => {
      customer.faceMask.destroy();
      this.customerExit(customer);
    });

    let cuscore =
      customer.order.item == "WING"
        ? 3
        : customer.order.item == "STEAK"
        ? 5
        : 2;

    this.score += cuscore;
    this.scoreText.setText(
      i18n.t("miniGames.general.score") + " : " + this.score
    );
    let scoreHint = this.add
      .text(0, 0, "+" + cuscore, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 22,
        color: "#492a12",
      })
      .setOrigin(0, 0.5)
      .setAlpha(0);
    scoreHint.setAlpha(1);
    scoreHint.x = customer.x + 80;
    scoreHint.y = customer.y - 50;
    this.tweens.add({
      targets: [scoreHint],
      y: customer.y - 100,
      duration: 500,
      ease: "Sine.easeOut",
      onStart: () => {
        this.sfxScoreIncrease.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxScoreIncrease.play();
      },
      onComplete: () => {
        scoreHint.setAlpha(0);
        scoreHint.destroy();
      },
    });
  }

  doorOpen() {
    this.sptDoor.play("animDoorOpen");
    this.sfxDoor.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxDoor.play();
  }

  clickRawFood(food) {
    const avaStove = this.stoveGroup
      .getChildren()
      .find((stove) => stove.status == "OFF");

    if (avaStove && this.gameOngoing) {
      avaStove.status = "ON";
      avaStove.play("animStoveOn");
      avaStove.actions.play("animCooking");
      const cookingFood =
        food == "WING"
          ? this.add
              .sprite(avaStove.x + 5, avaStove.y - 18, "sptWing")
              .setFrame(0)
              .setScale(0.9)
              .setDepth(2)
          : food == "STEAK"
          ? this.add
              .sprite(avaStove.x + 5, avaStove.y - 18, "sptSteak")
              .setFrame(0)
              .setScale(0.9)
              .setDepth(2)
          : this.add
              .sprite(avaStove.x + 5, avaStove.y - 18, "sptCrab")
              .setFrame(0)
              .setScale(0.9)
              .setDepth(2);

      cookingFood._x = avaStove.x + 5;
      cookingFood._y = avaStove.y - 18;
      cookingFood.stoveId = avaStove._id;
      cookingFood.food = food;
      cookingFood.status = "RAW";
      cookingFood._status = "";
      cookingFood.setInteractive({ useHandCursor: true, draggable: true });
      cookingFood.cookTime =
        food == "WING" ? 5000 : food == "STEAK" ? 8000 : 3000;
      cookingFood.score = food == "WING" ? 3 : food == "STEAK" ? 5 : 2;
      this.cookingFoodGroup.add(cookingFood);
    }
  }

  doorColose() {
    //
  }

  cooking() {
    //
  }

  finishCooking() {
    //
  }

  clickSetting() {
    if (this.gameOngoing) {
      this.sfxSimpleClick.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxSimpleClick.play();
      this.scene.pause();
      this.scene.launch("Settings", {
        sceneName: "PlayScene",
      });
    }
  }

  goQuestion() {
    this.sfxShine.stop();
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    let avatarX = this.avatarObject[0].x;
    this.avatarObject = [];
    this.customerCount = 0;
    this.exitCustomerCount = 0;
    this.customerList = [];
    this.gameOngoing = true;
    this.isFriendExit = false;
    this.isFriendArrived = false;
    this.registry.destroy();
    this.events.off();
    this.scene.stop("PlayScene");

    this.scene.start("QuestionScene", {
      avatar: this.avatar,
      round: this.round,
      score: this.score,
      questions: this.questions,
      friend: this.friend,
      bossRound: this.bossRound,
      noOfRounds: this.noOfRounds,
      difficulty: this.difficulty,
      countCorrect: this.countCorrect,
      avatarX: avatarX,
    });
  }

  _refreshToken(removedToken) {
    if (removedToken == 1) {
      if (
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 3)
      ) {
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 3).tokenNumber = 1;
        this.moveCustomer(
          this.customerGroup
            .getChildren()
            .find((customer) => customer.tokenNumber == 1)
        );
        this.refreshToken(3);
      } else if (
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 4)
      ) {
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 4).tokenNumber = 1;
        this.moveCustomer(
          this.customerGroup
            .getChildren()
            .find((customer) => customer.tokenNumber == 1)
        );
        this.refreshToken(4);
      }
    }

    if (removedToken == 2) {
      if (
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 4)
      ) {
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 4).tokenNumber = 2;
        this.moveCustomer(
          this.customerGroup
            .getChildren()
            .find((customer) => customer.tokenNumber == 2)
        );
        this.refreshToken(4);
      } else if (
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 3)
      ) {
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 3).tokenNumber = 2;
        this.moveCustomer(
          this.customerGroup
            .getChildren()
            .find((customer) => customer.tokenNumber == 2)
        );
        this.refreshToken(3);
      }
    }

    if (removedToken == 3) {
      if (
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 5)
      ) {
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 5).tokenNumber = 3;
        this.moveCustomer(
          this.customerGroup
            .getChildren()
            .find((customer) => customer.tokenNumber == 3)
        );
      } else if (
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 6)
      ) {
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 6).tokenNumber = 3;
        this.moveCustomer(
          this.customerGroup
            .getChildren()
            .find((customer) => customer.tokenNumber == 3)
        );
      }
    }

    if (removedToken == 4) {
      if (
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 6)
      ) {
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 6).tokenNumber = 4;
        this.moveCustomer(
          this.customerGroup
            .getChildren()
            .find((customer) => customer.tokenNumber == 4)
        );
      } else if (
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 5)
      ) {
        this.customerGroup
          .getChildren()
          .find((customer) => customer.tokenNumber == 5).tokenNumber = 4;
        this.moveCustomer(
          this.customerGroup
            .getChildren()
            .find((customer) => customer.tokenNumber == 4)
        );
      }
    }
  }

  fireBall() {
    const friend = this.customerGroup
      .getChildren()
      .find((customer) => customer.isFriend);
    for (let i = 0; i < 3; i++) {
      let ball = this.add
        .sprite(friend.x, friend.y, "sptMagicBalls")
        .setDepth(4)
        .setFrame(2)
        .setScale(0.8);

      this.tweens.add({
        targets: [ball],
        x: 140 * (i + 1) - 50,
        y: 605,
        duration: 200,
        ease: "Sine.easeInOut",
        onStart: () => {
          this.sfxSkill.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxSkill.play();
          friend.visible = false;
          friend.shine.visible = false;
          this.time.delayedCall(1000, () => {
            if (this.avatarMask) {
              this.avatarMask.destroy();
            }
            if (!this.isFriendExit) {
              this.isFriendExit = true;
              this.customerExit(friend);
            }
          });
        },
        onComplete: () => {
          ball.destroy();
        },
      });
    }

    this.time.delayedCall(200, () => {
      if (this.avatarMask) {
        this.avatarMask.destroy();
      }
      this.avatarMask = this.add
        .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskFear")
        .setScale(0.25)
        .setDepth(6);
      this.cookingFoodGroup
        .getChildren()
        .forEach(function (cookingFood, index) {
          if (cookingFood) {
            this.time.removeEvent(cookingFood.timeEvent);
            cookingFood.status = "BURNING";
            cookingFood.setFrame(3);
            const avaStove = this.stoveGroup
              .getChildren()
              .find((stove) => stove._id == cookingFood.stoveId);
            avaStove.actions.anims.stop("animOverCooked");
            avaStove.actions.play("animBurning");
            avaStove.actions.setInteractive();
            avaStove.actions.isBurning = true;
            avaStove.actions.foodIndex = index;
            if (avaStove.icon) {
              avaStove.icon.destroy();
            }
            avaStove.icon = this.add
              .image(avaStove.x + 40, avaStove.y, "imgFireExtingIcon")
              .setOrigin(0, 0)
              .setDepth(3)
              .setScale(0.9);
          }
        }, this);

      this.stoveGroup.getChildren().forEach(function (stove) {
        if (stove && stove.status == "OFF") {
          stove.status = "ON";
          stove.actions.play("animBurning");
          stove.actions.setInteractive();
          stove.actions.isBurning = true;
          stove.actions.foodIndex = -1;
          if (stove.icon) {
            stove.icon.destroy();
          }
          stove.icon = this.add
            .image(stove.x + 40, stove.y, "imgFireExtingIcon")
            .setOrigin(0, 0)
            .setDepth(3)
            .setScale(0.9);
        }
      }, this);
    });
  }

  iceBall() {
    if (this.avatarMask) {
      this.avatarMask.destroy();
    }
    this.avatarMask = this.add
      .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskHappyx")
      .setScale(0.25)
      .setDepth(6);
    const friend = this.customerGroup
      .getChildren()
      .find((customer) => customer.isFriend);
    this.customerGroup.getChildren().forEach(function (customer) {
      if (!customer.isFriend && customer.timeEvent && !customer.done) {
        let ball = this.add
          .sprite(friend.x, friend.y, "sptMagicBalls")
          .setDepth(4)
          .setFrame(1)
          .setScale(0.8);

        this.tweens.add({
          targets: [ball],
          x: customer.x,
          y: customer.y,
          duration: 200,
          ease: "Sine.easeInOut",
          onStart: () => {
            this.sfxSkill.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxSkill.play();
            friend.visible = false;
            friend.shine.visible = false;
            this.time.delayedCall(1000, () => {
              if (this.avatarMask) {
                this.avatarMask.destroy();
              }
              if (!this.isFriendExit) {
                this.isFriendExit = true;
                this.customerExit(friend);
              }
            });
          },
          onComplete: () => {
            ball.destroy();
            if (customer.timeEvent && !customer.done) {
              this.time.removeEvent(customer.timeEvent);
              customer.freeze = true;
              customer.freezeImg = this.add
                .image(customer.x, customer.y, "imgFreeze")
                .setScale(1)
                .setDepth(2);
              customer.order.clock.setAlpha(1);
              // customer.order.itemBbl.setFrame(1);
              this.time.delayedCall(5000, () => {
                if (customer && !customer.done) {
                  customer.freeze = false;
                  customer.freezeImg.destroy();
                  customer.order.clock.setAlpha(0);
                  // customer.order.itemBbl.setFrame(0);
                  this.customerCoundown(customer);
                }
              });
            }
          },
        });
      }
    }, this);
  }

  magicBall() {
    if (this.avatarMask) {
      this.avatarMask.destroy();
    }
    this.avatarMask = this.add
      .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskHappyx")
      .setScale(0.25)
      .setDepth(6);
    const friend = this.customerGroup
      .getChildren()
      .find((customer) => customer.isFriend);
    this.customerGroup.getChildren().forEach(function (customer) {
      if (!customer.isFriend && customer.timeEvent && !customer.done) {
        let ball = this.add
          .sprite(friend.x, friend.y, "sptMagicBalls")
          .setDepth(4)
          .setFrame(3)
          .setScale(0.8);

        this.tweens.add({
          targets: [ball],
          x: customer.x,
          y: customer.y,
          duration: 200,
          ease: "Sine.easeInOut",
          onStart: () => {
            this.sfxSkill.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxSkill.play();
            friend.visible = false;
            friend.shine.visible = false;
            this.time.delayedCall(1000, () => {
              if (this.avatarMask) {
                this.avatarMask.destroy();
              }
              if (!this.isFriendExit) {
                this.isFriendExit = true;
                this.customerExit(friend);
              }
            });
          },
          onComplete: () => {
            ball.destroy();
            if (customer.timeEvent && !customer.done) {
              customer.order.item = "CRAB";
              customer.order.itemSpt.destroy();
              customer.order.itemSpt = this.add
                .image(customer.x + 54, customer.y - 35, "imgCrab")
                .setScale(customer.timeEvent ? 0.9 : 0)
                .setOrigin(0, 0.5);
            }
          },
        });
      }
    }, this);
  }

  superMagicBall() {
    if (this.avatarMask) {
      this.avatarMask.destroy();
    }
    this.avatarMask = this.add
      .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskHappyx")
      .setScale(0.25)
      .setDepth(6);
    const friend = this.customerGroup
      .getChildren()
      .find((customer) => customer.isFriend);
    this.customerGroup.getChildren().forEach(function (customer) {
      if (!customer.isFriend && customer.timeEvent && !customer.done) {
        let ball = this.add
          .sprite(friend.x, friend.y, "sptMagicBalls")
          .setDepth(4)
          .setFrame(0)
          .setScale(0.8);

        this.tweens.add({
          targets: [ball],
          x: customer.x,
          y: customer.y,
          duration: 200,
          ease: "Sine.easeInOut",
          onStart: () => {
            this.sfxSkill.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxSkill.play();
            friend.visible = false;
            friend.shine.visible = false;
            this.time.delayedCall(1000, () => {
              if (this.avatarMask) {
                this.avatarMask.destroy();
              }
              if (!this.isFriendExit) {
                this.isFriendExit = true;
                this.customerExit(friend);
              }
            });
          },
          onComplete: () => {
            ball.destroy();
            if (customer.timeEvent && !customer.done) {
              this.customerServed(customer);
            }
          },
        });
      }
    }, this);
  }
}
