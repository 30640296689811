<template>
  <transition name="fade">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="400"
      class="rounded-xl"
      scrollable
    >
      <v-card class="pb-5" rounded="lg">
        <v-card-title
          class="text-h6 teal--text text--darken-3 d-flex justify-space-between pt-1 pe-1 pb-1 yellow"
        >
          <v-icon color="black">mdi-crown</v-icon>
          <span class="ml-2 black--text">{{ plan.name }}</span>
          <v-spacer></v-spacer>
          <CloseBtn size="30" :callback="callbackClose" />
        </v-card-title>
        <v-img :src="require(`@/assets/subscription/BannerBrightFuture.png`)" class="mb-3" lazy></v-img>
        <v-card-subtitle>{{ plan.description }}</v-card-subtitle>
        <v-card-text>
          <v-list dense>
            <v-list-item v-for="(feature, i) in plan.features" :key="`feature-${i}`">
              <v-list-item-icon>
                <v-icon color="green">mdi-check-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ feature.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mx-auto orange" x-large rounded target="_blank" :href="plan.href">{{ plan.cta }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </transition>
</template>
  
<script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  props: [
    "showDialog",
    "callbackClose",
  ],
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  data: () => ({
    plan: {},
  }),
  mounted() {
    const featurePremium = [
      {
        name: this.$t('subscription.feature1'),
      },
      {
        name: this.$t('subscription.feature2'),
      },
    ]
    this.plan = {
      name: this.$t('subscription.name'),
      description: this.$t('subscription.description'),
      href: `${process.env.VUE_APP_URL_WEB_SCHOOL}/${this.$_getLocale()}/upgrade`,
      features: featurePremium,
      cta: this.$t('subscription.cta'),
    }
  },
};
</script>
  