import { Scene } from "phaser";
import i18n from "@/plugins/i18n";

export default class LoadingScene extends Scene {
  constructor() {
    super({ key: "LoadingScene" });
  }

  init(data) {
    this.questions = data.questions;
    this.env = data.env;
    this.noOfRounds = data.noOfRounds;
  }

  preload() {
    var progressBar = this.add.graphics();
    var progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(100, 270, 280, 50);

    var width = this.cameras.main.width;
    var height = this.cameras.main.height;

    var loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: i18n.t("miniGames.general.loading") + "...",
      style: {
        font: "20px monospace",
        fill: "#000000",
      },
    });
    loadingText.setOrigin(0.5, 0.5);

    var percentText = this.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: "0%",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    percentText.setOrigin(0.5, 0.5);

    var assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: "",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    assetText.setOrigin(0.5, 0.5);

    this.load.on("progress", function (value) {
      percentText.setText(parseInt(value * 100) + "%");
      progressBar.clear();
      progressBar.fillStyle(0x000000, 1);
      progressBar.fillRect(110, 280, 260 * value, 30);
    });

    this.load.on("fileprogress", function (file) {
      assetText.setText("Loading asset: " + file.key);
    });
    this.load.on("complete", function () {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
      assetText.destroy();
    });
    // Loading logic END

    //*** load images ***//
    // general Images
    // buttons
    this.load.image(
      "imgCloseBtn",
      "/assets/gameAssets/common/images/btn-close.png"
    );
    this.load.image("imgOkBtn", "/assets/gameAssets/common/images/btn-ok.png");
    this.load.image(
      "imgOptionBtn",
      "/assets/gameAssets/common/images/btn-option.png"
    );
    this.load.image(
      "imgSettingBtn",
      "/assets/gameAssets/common/images/btn-setting.png"
    );

    // panel bg
    this.load.image(
      "imgRoundPanel",
      "/assets/gameAssets/common/images/panel-round.png"
    );
    this.load.image(
      "imgSettingPanel",
      "/assets/gameAssets/common/images/panel-setting.png"
    );
    this.load.image(
      "imgScorePanel",
      "/assets/gameAssets/common/images/panel-score.png"
    );
    this.load.image(
      "imgAnswerPanel",
      "/assets/gameAssets/common/images/panel-answer.png"
    );

    // others
    this.load.image(
      "imgAnswerTag",
      "/assets/gameAssets/common/images/answer-tag.png"
    );
    this.load.image(
      "imgCorrectAns",
      "/assets/gameAssets/common/images/answer-correct.png"
    );
    this.load.image(
      "imgWrongAns",
      "/assets/gameAssets/common/images/answer-wrong.png"
    );
    this.load.image(
      "imgCorrectMark",
      "/assets/gameAssets/common/images/mark-correct.png"
    );
    this.load.image(
      "imgWrongMark",
      "/assets/gameAssets/common/images/mark-wrong.png"
    );
    this.load.image(
      "imgExclMark",
      "/assets/gameAssets/common/images/mark-exclamation.png"
    );
    this.load.image("imgSpeech", "/assets/gameAssets/common/images/speech.png");


    // //*** Load Sprite Sheets ***//

    // Common
    this.load.spritesheet(
      "sptIdea",
      "/assets/gameAssets/common/sprites/idea.png",
      {
        frameWidth: 120,
        frameHeight: 120,
      }
    );

    // //*** load audio files ***//
    // general sfxs
    this.load.audio(
      "sfxCorrectAnswer",
      "/assets/gameAssets/common/sounds/answer-correct.wav"
    );
    this.load.audio(
      "sfxWrongAnswer",
      "/assets/gameAssets/common/sounds/answer-wrong.wav"
    );
    this.load.audio(
      "sfxScoreIncrease",
      "/assets/gameAssets/common/sounds/score-increase.wav"
    );
    this.load.audio(
      "sfxSimpleClick",
      "/assets/gameAssets/common/sounds/simple-click.wav"
    );
    this.load.audio(
      "sfxWarningAlarm",
      "/assets/gameAssets/common/sounds/warning-alarm.wav"
    );
    this.load.audio(
      "sfxFailure",
      "/assets/gameAssets/common/sounds/game-failure.wav"
    );
    this.load.audio(
      "sfxSuccess",
      "/assets/gameAssets/common/sounds/game-success.wav"
    );
    this.load.audio("sfxClose", "/assets/gameAssets/common/sounds/close.wav");
    this.load.audio("sfxRound", "/assets/gameAssets/common/sounds/round.wav");
    this.load.audio("sfxGo", "/assets/gameAssets/common/sounds/go.wav");
  }

  create() {
    this.registry.destroy();
    this.events.off();
    this.scene.stop("LoadingScene");
    this.scene.start("QuestionScene", {
      noOfRounds: this.noOfRounds,
      score: 0,
      round: 1,
      qCount: 0,
      questions: this.questions,
      countCorrect: 0,
      env: this.env,
    });
  }
}
