import LayoutDemo from "@/layouts/LayoutDemo.vue";

export default {
  path: "/demo",
  component: LayoutDemo,
  children: [
    {
      path: "math",
      name: "PageMathGameDemo",
      component: () =>
        import(
          /* webpackChunkName: "Math-demo" */ "@/demo/Math/PageMathGame"
        ),
      meta: {
        title: "Demo - Math",
      },
    },
    {
      path: "math/score",
      name: "PageMathGameScoreDemo",
      component: () =>
        import(
          /* webpackChunkName: "Math-score-demo" */ "@/demo/Math/PageMathGameScore"
        ),
      meta: {
        title: "Demo - Math",
      },
    },

    {
      path: "abc",
      name: "PageABCBookDemo",
      component: () =>
        import(
          /* webpackChunkName: "ABC-book-demo" */ "@/demo/ABC/PageABCBookCover"
        ),
      meta: {
        title: "Demo - ABC",
      },
    },
    {
      path: "abc/:bookKey/chapters",
      name: "PageABCBookChaptersDemo",
      component: () =>
        import(
          /* webpackChunkName: "ABC-books-chapter-demo" */ "@/demo/ABC/PageABCBookChapters.vue"
        ),
      meta: {
        title: "Demo - ABC",
      },
    },

    {
      path: "abc/:bookKey/quest",
      name: "PageABCBookQuest",
      component: () =>
        import(
          /* webpackChunkName: "ABC-books-quest */ "@/demo/ABC/PageABCBookQuest.vue"
        ),
      meta: {
        title: "Demo - ABC",
      },
    },

    {
      path: "abc/:bookKey/chapters/:chapterKey",
      name: "PageABCBookReaderDemo",
      component: () =>
        import(
          /* webpackChunkName: "ABC-books-reader-demo" */ "@/demo/ABC/PageABCBookReader.vue"
        ),
      meta: {
        title: "Demo - ABC",
      },
    },

    {
      path: "abc/:bookKey/chapters/:chapterKey/failed",
      name: "PageABCBookFailedDemo",
      component: () =>
        import(
          /* webpackChunkName: "ABC-books-failed-demo" */ "@/demo/ABC/PageABCBookFailed.vue"
        ),
      meta: {
        title: "Demo - ABC",
      },
    },

    {
      path: "abc/:bookKey/chapters/:chapterKey/completed",
      name: "PageABCBookCompletedDemo",
      component: () =>
        import(
          /* webpackChunkName: "ABC-books-score-demo" */ "@/demo/ABC/PageABCBookCompleted.vue"
        ),
      meta: {
        title: "Demo - ABC",
      },
    },
  ],
};
