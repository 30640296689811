import { Scene } from "phaser";
import Phaser from "phaser";
import store from "@/store";
import router from "@/router";
import i18n from "@/plugins/i18n";
import _ from "lodash";
import constants from "./constants.json";
import StoryScene from "./StoryScene";
import NilamScene from "./NilamScene";

export default class QuizScene extends Scene {
  constructor(key = "QuizScene") {
    super({ key: key });
  }

  init(data) {
    this.avatar = data.avatar;
    this.steps = data.steps;
    this.currentStep = data.currentStep;
    this.autoPlay = data.autoPlay;
    this.subjectLang = data.subjectLang;
    this.currentProggressStep = data.currentProggressStep;
    this.isEvent = data.isEvent;
    this.isDemo = data.isDemo;
  }

  create() {
    if (
      store.state.settings.data.audio.sfx == 0 ||
      store.state.settings.data.audio.master == 0
    ) {
      this.sfxVolume = 0.5;
    } else {
      this.sfxVolume =
        store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master;
    }

    this.qTitle = {
      en: {
        text: "It's Quiz Time!",
        sfx: "sfx_QS_en",
      },
      zh: {
        text: "提问时间",
        sfx: "sfx_QS_zh",
      },
      ms: {
        text: "Jom Kuiz!",
        sfx: "sfx_QS_ms",
      },
    };

    this.qResult = {
      QE_1: {
        en: {
          text: "Perfect!",
          sfx: "sfx_QE1_en",
        },
        zh: {
          text: "完美！",
          sfx: "sfx_QE1_zh",
        },
        ms: {
          text: "Cemerlang!",
          sfx: "sfx_QE1_ms",
        },
      },
      QE_2: {
        en: {
          text: "Awesome!",
          sfx: "sfx_QE2_en",
        },
        zh: {
          text: "太棒了！",
          sfx: "sfx_QE2_zh",
        },
        ms: {
          text: "Hebat!",
          sfx: "sfx_QE2_ms",
        },
      },
      QE_3: {
        en: {
          text: "Well done!",
          sfx: "sfx_QE3_en",
        },
        zh: {
          text: "做得好！",
          sfx: "sfx_QE3_zh",
        },
        ms: {
          text: "Bagus sekali!",
          sfx: "sfx_QE3_ms",
        },
      },
      QE_4: {
        en: {
          text: "Oh, no!",
          sfx: "sfx_QE4_en",
        },
        zh: {
          text: "哎呀！",
          sfx: "sfx_QE4_zh",
        },
        ms: {
          text: "Aduh!",
          sfx: "sfx_QE4_ms",
        },
      },
    };
    this.add.rectangle(240, 427, 480, 854, 0xffffff);
    this.progressSteps = this.currentStep.Page.length;
    this.simpleClickSound = this.sound.add("sfxSimpleClick");
    this.sfxWrongAnswer = this.sound.add("sfxWrongAnswer");
    this.sfxSuccess = this.sound.add("sfxSuccess");

    this.isTranslateON = false;
    this.questionTexts = [];
    this.options = [];
    this.stars = [];

    if (!this.currentStep.correctCount) {
      this.currentStep.correctCount = 0;
    }
    if (!this.currentStep.starCount) {
      this.currentStep.starCount = 0;
    }

    if (!this.registry.get("isStartQuizShown")) {
      let temp = this.add
        .text(240, 350, this.qTitle[this.subjectLang].text, {
          fontFamily: "MouseMemoirs, sans-serif",
          fontSize: 48,
          color: "#000",
        })
        .setOrigin(0.5);
      let tempx;

      if (this.subjectLang != i18n.locale) {
        tempx = this.add
          .text(240, 450, this.qTitle[i18n.locale].text, {
            fontFamily: "MouseMemoirs, sans-serif",
            fontSize: 48,
            color: "#000",
          })
          .setOrigin(0.5);
      }

      this.audio = this.sound.add(this.qTitle[this.subjectLang].sfx);
      this.audio.setVolume(this.sfxVolume);
      this.time.delayedCall(500, () => {
        this.audio.play();
      });

      this.audio.on("complete", () => {
        if (this.subjectLang != i18n.locale) {
          this.audio = null;
          this.audio = this.sound.add(this.qTitle[i18n.locale].sfx);
          this.audio.setVolume(this.sfxVolume);
          this.audio.play();
          this.audio.on("complete", () => {
            this.registry.set("isStartQuizShown", true);
            temp.destroy();
            tempx.destroy();
            this.audio = null;
            this.showpage();
          });
        } else {
          this.registry.set("isStartQuizShown", true);
          temp.destroy();
          this.audio = null;
          this.showpage();
        }
      });
    } else {
      this.showpage();
    }

    this.events.on("resume", () => {
      if (
        store.state.settings.data.audio.sfx == 0 ||
        store.state.settings.data.audio.master == 0
      ) {
        this.sfxVolume = 0.5;
      } else {
        this.sfxVolume =
          store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master;
      }
      if (this.audio && this.imgPauseCtrlBtn.visible) {
        this.audio.setVolume(this.sfxVolume);
        this.audio.resume();
      }
    });
  }

  showpage() {
    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.4)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    for (let index = 1; index < 4; index++) {
      this.stars.push(
        this.add
          .sprite(10 + 35 * index, 40, "sptStar")
          .setScale(0.35)
          .setFrame(this.currentStep.starCount >= index ? 1 : 0)
          .setAlpha(this.currentStep.starCount >= index ? 1 : 0.5)
      );
    }

    this.imgPrevCtrlBtn = this.add
      .image(this.subjectLang != i18n.locale ? 90 : 120, 780, "imgPrevCtrlBtn")
      .setScale(0.14);

    if (this.currentStep.order == 1) {
      this.imgPrevCtrlBtn.setAlpha(0.3);
    } else {
      this.imgPrevCtrlBtn
        .setInteractive({ cursor: "pointer" })
        .on("pointerdown", this.triggerPrev, this);
    }
    this.imgPlayCtrlBtn = this.add
      .image(this.subjectLang != i18n.locale ? 190 : 240, 780, "imgPlayCtrlBtn")
      .setInteractive({ useHandCursor: true })
      .setScale(0.18)
      .on("pointerdown", () => this.play(), this);
    this.imgPauseCtrlBtn = this.add
      .image(
        this.subjectLang != i18n.locale ? 190 : 240,
        780,
        "imgPauseCtrlBtn"
      )
      .setInteractive({ useHandCursor: true })
      .setScale(0.18)
      .on("pointerdown", this.pause, this);
    this.imgPauseCtrlBtn.visible = false;

    if (this.subjectLang != i18n.locale) {
      this.imgTranslateCtrlBtn = this.add
        .image(290, 780, "imgTranslateCtrlBtn")
        .setScale(0.18)
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", this.translateX, this);
    }

    this.imgNextCtrlBtn = this.add
      .image(this.subjectLang != i18n.locale ? 390 : 360, 780, "imgNextCtrlBtn")
      .setScale(0.14)
      .setAlpha(0.3)
      .on("pointerdown", this.triggerNext, this);

    this.createProgressBar();

    this.countdownText = this.add
      .text(this.subjectLang != i18n.locale ? 440 : 420, 780, "", {
        fontFamily: "Inter, sans-serif",
        fontSize: 16,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(1)
      .setVisible(false)
      .setInteractive({ useHandCursor: true })
      .on(
        "pointerdown",
        () => {
          if (this.countdownEvent) {
            this.countdownEvent.destroy();
          }
          this.countdownText.setVisible(false);
        },
        this
      );

    this.generateStory(this.currentStep.Page[this.currentProggressStep]);
  }

  translateX() {
    this.stop();
    if (this.countdownEvent) {
      this.countdownEvent.destroy();
    }
    this.countdownText.setVisible(false);

    if (this.isTranslateON) {
      this.isTranslateON = false;
      this.imgTranslateCtrlBtn.clearTint();
    } else {
      this.isTranslateON = true;
      this.imgTranslateCtrlBtn.setTint(0x00ff00);
    }

    this.generateStory(this.currentStep.Page[this.currentProggressStep]);
  }

  triggerNext() {
    if (this.countdownEvent) {
      this.countdownEvent.destroy();
    }
    this.countdownText.setVisible(false);
    this.currentProggressStep < this.progressSteps - 1
      ? this.nextQ()
      : this.endQ();
  }

  endQ() {
    if (!this.registry.get("isEndQuizShown")) {
      this.imgNextCtrlBtn.removeInteractive();
      this.imgPrevCtrlBtn.removeInteractive();
      this.imgPlayCtrlBtn.removeInteractive();
      this.imgPauseCtrlBtn.removeInteractive();

      this.time.removeAllEvents();
      this.sound.stopAll();
      this.add.rectangle(240, 427, 480, 854, 0xffffff).setDepth(99);
      this.add
        .image(
          240,
          280,
          this.currentStep.starCount == 3
            ? "imgHamochiHappy"
            : this.currentStep.starCount == 2
            ? "imgHamochiTalk"
            : this.currentStep.starCount == 1
            ? "imgHamochiTalk"
            : "imgHamochiSad"
        )
        .setDepth(99)
        .setScale(0.25);
      this.add
        .text(
          240,
          380,
          this.currentStep.starCount == 3
            ? this.qResult.QE_1[this.subjectLang].text
            : this.currentStep.starCount == 2
            ? this.qResult.QE_2[this.subjectLang].text
            : this.currentStep.starCount == 1
            ? this.qResult.QE_3[this.subjectLang].text
            : this.qResult.QE_4[this.subjectLang].text,
          {
            fontFamily: "MouseMemoirs, sans-serif",
            fontSize: 38,
            color: "#000",
          }
        )
        .setOrigin(0.5)
        .setDepth(99);

      if (this.subjectLang != i18n.locale) {
        this.add
          .text(
            240,
            420,
            this.currentStep.starCount == 3
              ? this.qResult.QE_1[i18n.locale].text
              : this.currentStep.starCount == 2
              ? this.qResult.QE_2[i18n.locale].text
              : this.currentStep.starCount == 1
              ? this.qResult.QE_3[i18n.locale].text
              : this.qResult.QE_4[i18n.locale].text,
            {
              fontFamily: "MouseMemoirs, sans-serif",
              fontSize: 38,
              color: "#000",
            }
          )
          .setOrigin(0.5)
          .setDepth(99);
      }

      for (let index = 1; index < 4; index++) {
        this.add
          .sprite(140 + 50 * index, 480, "sptStar")
          .setScale(0.5)
          .setFrame(this.currentStep.starCount >= index ? 1 : 0)
          .setAlpha(this.currentStep.starCount >= index ? 1 : 0.5)
          .setDepth(99);
      }

      this.audio = this.sound.add(
        this.currentStep.starCount == 3
          ? this.qResult.QE_1[this.subjectLang].sfx
          : this.currentStep.starCount == 2
          ? this.qResult.QE_2[this.subjectLang].sfx
          : this.currentStep.starCount == 1
          ? this.qResult.QE_3[this.subjectLang].sfx
          : this.qResult.QE_4[this.subjectLang].sfx
      );
      this.audio.setVolume(this.sfxVolume);
      this.time.delayedCall(500, () => {
        this.audio.play();
      });

      this.audio.on("complete", () => {
        if (this.subjectLang != i18n.locale) {
          this.audio = null;
          this.audio = this.sound.add(
            this.currentStep.starCount == 3
              ? this.qResult.QE_1[i18n.locale].sfx
              : this.currentStep.starCount == 2
              ? this.qResult.QE_2[i18n.locale].sfx
              : this.currentStep.starCount == 1
              ? this.qResult.QE_3[i18n.locale].sfx
              : this.qResult.QE_4[i18n.locale].sfx
          );
          this.audio.setVolume(this.sfxVolume);
          this.audio.play();
          this.audio.on("complete", () => {
            this.time.delayedCall(500, () => {
              this.registry.set("isEndQuizShown", true);
              this.nextStep(
                this.currentStep.correctCount == 0 ? "failed" : "success"
              );
            });
          });
        } else {
          this.time.delayedCall(500, () => {
            this.registry.set("isEndQuizShown", true);
            this.nextStep(
              this.currentStep.correctCount == 0 ? "failed" : "success"
            );
          });
        }
      });
    } else {
      this.nextStep(this.currentStep.correctCount == 0 ? "failed" : "success");
    }
  }

  triggerPrev() {
    this.currentProggressStep > 0
      ? this.prevQ()
      : this.prevStep(this.currentStep.conditionKey ?? "success");
  }

  play(isTranslateFirst = false) {
    if (!this.audio) {
      if (isTranslateFirst) {
        this.readStoryAloudX(this.currentStep.Page[this.currentProggressStep]);
      } else {
        this.readStoryAloud(this.currentStep.Page[this.currentProggressStep]);
      }
    } else {
      this.audio.setVolume(this.sfxVolume);
      this.audio.resume();
    }
    this.imgPauseCtrlBtn.visible = true;
    this.imgPlayCtrlBtn.visible = false;
  }

  pause() {
    if (this.audio && this.audio.isPlaying) {
      this.audio.pause();
    }
    this.imgPauseCtrlBtn.visible = false;
    this.imgPlayCtrlBtn.visible = true;
  }

  stop() {
    if (this.audio && this.audio.isPlaying) {
      this.audio.stop();
      this.audio = null;
    }
    this.imgPauseCtrlBtn.visible = false;
    this.imgPlayCtrlBtn.visible = true;
  }

  resume() {
    if (this.audio && !this.audio.isPlaying) {
      this.audio.setVolume(this.sfxVolume);
      this.audio.resume();
    }
    this.imgPauseCtrlBtn.visible = true;
    this.imgPlayCtrlBtn.visible = false;
  }

  generateStory(story) {
    this.imgNextCtrlBtn.removeInteractive().setAlpha(0.3);
    this.stop();

    this.questionTexts.forEach((element) => {
      element.destroy();
    });
    this.options.forEach((element) => {
      if (element.secondaryTxt) element.secondaryTxt.destroy();
      element.primaryTxt.destroy();
      element.optionNo.destroy();
      element.panel.destroy();
      if (element.answerMark) element.answerMark.destroy();
    });
    this.questionTexts = [];
    this.options = [];

    if (this.hintBg) this.hintBg.destroy();
    if (this.hintTxt) this.hintTxt.destroy();

    let storyText =
      "<p>" + JSON.parse(story.translatableText)[this.subjectLang] + "</p>";
    let processedText = storyText;

    let currentY = this.renderHtmlString(this, processedText, 30, 80, 420);

    if (this.isTranslateON) {
      let secondaryStoryText =
        "<p>" + JSON.parse(story.translatableText)[i18n.locale] + "</P>";

      let secondaryProcessedText = secondaryStoryText;

      currentY = this.renderHtmlString(
        this,
        secondaryProcessedText,
        30,
        currentY + 50,
        420
      );
    }

    if ((story.isSecondaryImg && this.isTranslateON) || story.isPrimaryImg) {
      this.hintBg = this.add
        .image(240, (currentY += 60), "imgOptionBtn")
        .setScale(0.2)
        .setInteractive({ cursor: "pointer" })
        .on("pointerdown", this.clickHint, this);
      this.hintTxt = this.add
        .text(240, currentY, i18n.t("string.showHint"), {
          fontFamily: "Inter, sans-serif",
          fontSize: 14,
          color: "#000",
        })
        .setOrigin(0.5);
      currentY += 80;
    } else {
      currentY += 100;
    }

    this.options = story.ChapterQuestionOption;
    this.options.forEach((element, i) => {
      element.panel = this.add
        .sprite(240, currentY + 15, "sptAnswerPanel")
        .setScale(0.9, 1.25);
      if (!story.isCompleted) {
        element.panel
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => this.optionSelect(element), this);
      }
      element.optionNo = this.add
        .text(45, currentY + 10, "(" + String.fromCharCode(65 + i) + ")", {
          fontFamily: "Inter, sans-serif",
          fontSize: 18,
          color: "#000",
        })
        .setOrigin(0, 0.5);
      element.primaryTxt = this.add
        .text(
          80,
          this.isTranslateON ? currentY + 5 : currentY + 20,
          JSON.parse(element.translatableText)[this.subjectLang],
          {
            fontFamily: "Inter, sans-serif",
            fontSize: 17,
            color: "#000",
            wordWrap: {
              width: 360,
              useAdvancedWrap: true,
            },
          }
        )
        .setOrigin(0, 1);

      if (this.isTranslateON) {
        element.secondaryTxt = this.add
          .text(
            80,
            currentY + 15,
            JSON.parse(element.translatableText)[i18n.locale],
            {
              fontFamily: "Inter, sans-serif",
              fontSize: 17,
              color: "#000",
              wordWrap: {
                width: 360,
                useAdvancedWrap: true,
              },
            }
          )
          .setOrigin(0);
      }

      currentY += 145;
    });

    if (story.isCompleted) {
      this.optionSelect(story.selectedOption);
    } else if (this.autoPlay) {
      this.time.delayedCall(1000, () => {
        this.play(this.isTranslateON);
      });
    }
  }

  optionSelect(option) {
    if (option.isCorrect) {
      if (!this.currentStep.Page[this.currentProggressStep].isCompleted) {
        this.sfxSuccess.setVolume(this.sfxVolume);
        this.sfxSuccess.play();
        this.currentStep.starCount++;
        store.commit("updateABC", { stars: this.currentStep.starCount });
        this.stars.forEach((element, i) => {
          element.setFrame(this.currentStep.starCount > i ? 1 : 0);
          element.setAlpha(this.currentStep.starCount > i ? 1 : 0.5);
        });
        this.currentStep.correctCount++;
      }
      this.options.forEach((element) => {
        element.panel.removeInteractive();
        if (element.isCorrect) {
          element.panel.setFrame(1);
          element.answerMark = this.add
            .image(425, element.panel.y + 35, "imgCorrectMark")
            .setScale(0.4);
        } else {
          element.panel.setAlpha(0.5);
          if (element.secondaryTxt) element.secondaryTxt.setAlpha(0.5);
          element.optionNo.setAlpha(0.5);
          element.primaryTxt.setAlpha(0.5);
        }
      });
    } else {
      this.options.forEach((element) => {
        element.panel.removeInteractive();
        if (element.isCorrect) {
          element.panel.setFrame(1);
          element.answerMark = this.add
            .image(420, element.panel.y + 25, "imgAnswerTag")
            .setScale(0.7);
        } else if (element == option) {
          element.panel.setFrame(2);
          element.answerMark = this.add
            .image(425, element.panel.y + 35, "imgWrongMark")
            .setScale(0.4);
        } else {
          element.panel.setAlpha(0.5);
          if (element.secondaryTxt) element.secondaryTxt.setAlpha(0.5);
          element.optionNo.setAlpha(0.5);
          element.primaryTxt.setAlpha(0.5);
        }
      });
      if (!this.currentStep.Page[this.currentProggressStep].isCompleted) {
        this.shakeObject(this.stars, 50, 5);
        this.sfxWrongAnswer.setVolume(this.sfxVolume);
        this.sfxWrongAnswer.play();
      }
    }
    this.stop();

    if (!this.currentStep.Page[this.currentProggressStep].isCompleted) {
      this.currentStep.Page[this.currentProggressStep].isCompleted = true;
      this.currentStep.Page[this.currentProggressStep].selectedOption = option;
      this.startCountdown();
    }

    this.updateProgress();
    this.imgNextCtrlBtn.setAlpha(1).setInteractive({ useHandCursor: true });
  }

  readStoryAloud(story) {
    if (story.isPrimarySfx) {
      this.audio = this.sound.add("sfxPrimary" + story.key);
      this.audio.setVolume(this.sfxVolume);
      this.audio.play();

      this.audio.on("complete", () => {
        this.audio = null;
        if (this.isTranslateON && story.isSecondarySfx) {
          this.audio = this.sound.add("sfxSecondary" + story.key);
          this.audio.setVolume(this.sfxVolume);
          this.audio.play();

          this.audio.on("complete", () => {
            this.audio = null;
            this.optionIndex = 0;
            this.readOptions();
          });
        } else {
          this.optionIndex = 0;
          this.readOptions();
        }
      });
    } else {
      this.optionIndex = 0;
      this.readOptions();
    }
  }

  readStoryAloudX(story) {
    if (story.isSecondarySfx) {
      this.audio = this.sound.add("sfxSecondary" + story.key);
      this.audio.setVolume(this.sfxVolume);
      this.audio.play();

      this.audio.on("complete", () => {
        this.audio = null;
        this.optionIndex = 0;
        this.readOptionsX();
      });
    } else {
      this.optionIndex = 0;
      this.readOptionsX();
    }
  }

  readOptions() {
    this.audio = null;
    this.audio = this.sound.add(
      this.optionIndex == 0
        ? "sfx_A"
        : this.optionIndex == 1
        ? "sfx_B"
        : "sfx_C"
    );
    this.audio.setVolume(this.sfxVolume);
    this.audio.play();

    this.audio.on("complete", () => {
      if (this.options[this.optionIndex].isPrimarySfx) {
        this.audio = null;
        this.audio = this.sound.add(
          "sfxPrimary" +
            this.options[this.optionIndex].chapterQuestionKey +
            "_" +
            this.options[this.optionIndex].id
        );
        this.audio.setVolume(this.sfxVolume);
        this.audio.play();

        this.audio.on("complete", () => {
          this.audio = null;
          if (
            this.options[this.optionIndex].isSecondarySfx &&
            this.isTranslateON
          ) {
            this.audio = this.sound.add(
              "sfxSecondary" +
                this.options[this.optionIndex].chapterQuestionKey +
                "_" +
                this.options[this.optionIndex].id
            );
            this.audio.setVolume(this.sfxVolume);
            this.audio.play();

            this.audio.on("complete", () => {
              this.audio = null;
              this.optionIndex++;
              if (this.optionIndex < this.options.length) {
                this.readOptions();
              } else {
                this.stopAudio();
              }
            });
          } else {
            this.optionIndex++;
            if (this.optionIndex < this.options.length) {
              this.readOptions();
            } else {
              this.stopAudio();
            }
          }
        });
      } else {
        this.optionIndex++;
        if (this.optionIndex < this.options.length) {
          this.readOptions();
        } else {
          this.stopAudio();
        }
      }
    });
  }

  readOptionsX() {
    this.audio = null;
    this.audio = this.sound.add(
      this.optionIndex == 0
        ? "sfx_A"
        : this.optionIndex == 1
        ? "sfx_B"
        : "sfx_C"
    );
    this.audio.setVolume(this.sfxVolume);
    this.audio.play();

    this.audio.on("complete", () => {
      if (this.options[this.optionIndex].isSecondarySfx) {
        this.audio = this.sound.add(
          "sfxSecondary" +
            this.options[this.optionIndex].chapterQuestionKey +
            "_" +
            this.options[this.optionIndex].id
        );
        this.audio.setVolume(this.sfxVolume);
        this.audio.play();

        this.audio.on("complete", () => {
          this.audio = null;
          this.optionIndex++;
          if (this.optionIndex < this.options.length) {
            this.readOptionsX();
          } else {
            this.stopAudio();
          }
        });
      } else {
        this.optionIndex++;
        if (this.optionIndex < this.options.length) {
          this.readOptionsX();
        } else {
          this.stopAudio();
        }
      }
    });
  }

  stopAudio() {
    this.imgPauseCtrlBtn.visible = false;
    this.imgPlayCtrlBtn.visible = true;
  }

  async nextStep(conditionKey) {
    this.imgNextCtrlBtn.removeInteractive();
    this.imgPrevCtrlBtn.removeInteractive();
    this.imgPlayCtrlBtn.removeInteractive();
    this.imgPauseCtrlBtn.removeInteractive();

    this.time.removeAllEvents();
    this.sound.stopAll();

    if (this.currentStep.actionType == "restartChapter") {
      this.scene.stop();
      router.push({
        name: this.isEvent
          ? "PageEventABCBookFailed"
          : this.isDemo
          ? "PageABCBookFailedDemo"
          : "PageABCBookFailed",
        params: {
          bookKey: this.steps[0].Chapter.bookKey,
          chapterKey: this.steps[0].Chapter.key,
        },
      });
    } else {
      let nextStep = this.steps.findLast(
        (step) => step.order === this.currentStep.order + 1
      );

      if (nextStep) {
        if (nextStep.conditionKey) {
          nextStep = this.steps.findLast(
            (step) =>
              step.order === this.currentStep.order + 1 &&
              step.conditionKey == conditionKey
          );
        }

        let sceneObject = {
          avatar: this.avatar,
          autoPlay: this.autoPlay,
          steps: this.steps,
          currentStep: nextStep,
          subjectLang: this.subjectLang,
          isEvent: this.isEvent,
          isDemo: this.isDemo,
        };

        if (
          nextStep.actionType == "goPage" ||
          nextStep.actionType == "restartChapter"
        ) {
          let newSceneName = "StoryScene" + Date.now();
          let newScene = await new StoryScene(newSceneName);
          this.scene.add(newSceneName, newScene);
          this.scene.launch(newSceneName, sceneObject);
          this.scene.bringToTop();
          // this.scene.get(newSceneName).cameras.main.scrollX =
          //   -this.game.config.width / 2;

          this.cameras.main.pan(
            this.cameras.main.width * 1.5,
            this.cameras.main.height / 2,
            1000,
            "Power2"
          );

          // this.scene
          //   .get(newSceneName)
          //   .cameras.main.pan(
          //     this.scene.get(newSceneName).cameras.main.width / 2,
          //     this.scene.get(newSceneName).cameras.main.height / 2,
          //     1000,
          //     "Power2"
          //   );

          this.time.delayedCall(1000, () => {
            this.scene.stop();
          });
        }

        if (nextStep.actionType == "goMap")
          this.scene.start("MapScene", sceneObject);

        if (nextStep.actionType == "goGame")
          this.scene.start("GameScene", sceneObject);

        if (nextStep.actionType == "goItem")
          this.scene.start("ItemScene", sceneObject);

        if (nextStep.actionType == "goQuestion") {
          sceneObject.currentProggressStep = 0;

          this.scene.start("QuizScene", sceneObject);
          let newSceneName = "QuizScene" + Date.now();
          let newScene = await new QuizScene(newSceneName);
          this.scene.add(newSceneName, newScene);
          this.scene.launch(newSceneName, sceneObject);
          this.scene.bringToTop();
          // this.scene.get(newSceneName).cameras.main.scrollX =
          //   -this.game.config.width / 2;

          this.cameras.main.pan(
            this.cameras.main.width * 1.5,
            this.cameras.main.height / 2,
            1000,
            "Power2"
          );

          // this.scene
          //   .get(newSceneName)
          //   .cameras.main.pan(
          //     this.scene.get(newSceneName).cameras.main.width / 2,
          //     this.scene.get(newSceneName).cameras.main.height / 2,
          //     1000,
          //     "Power2"
          //   );

          this.time.delayedCall(1000, () => {
            this.scene.stop();
          });
        }

        if (nextStep.actionType == "goReflection") {
          let newSceneName = "NilamScene" + Date.now();
          let newScene = await new NilamScene(newSceneName);
          this.scene.add(newSceneName, newScene);
          this.scene.launch(newSceneName, sceneObject);
          this.scene.bringToTop();
          // this.scene.get(newSceneName).cameras.main.scrollX =
          //   -this.game.config.width / 2;

          this.cameras.main.pan(
            this.cameras.main.width * 1.5,
            this.cameras.main.height / 2,
            1000,
            "Power2"
          );

          // this.scene
          //   .get(newSceneName)
          //   .cameras.main.pan(
          //     this.scene.get(newSceneName).cameras.main.width / 2,
          //     this.scene.get(newSceneName).cameras.main.height / 2,
          //     1000,
          //     "Power2"
          //   );

          this.time.delayedCall(1000, () => {
            this.scene.stop();
          });
        }
      } else {
        this.scene.stop();
        router.push({
          name: this.isEvent
            ? "PageEventABCBookCompleted"
            : this.isDemo
            ? "PageABCBookCompletedDemo"
            : "PageABCBookCompleted",
          params: {
            bookKey: this.steps[0].Chapter.bookKey,
            chapterKey: this.steps[0].Chapter.key,
          },
        });
      }
    }
  }

  async prevStep(conditionKey) {
    this.imgNextCtrlBtn.removeInteractive();
    this.imgPrevCtrlBtn.removeInteractive();
    this.imgPlayCtrlBtn.removeInteractive();
    this.imgPauseCtrlBtn.removeInteractive();

    this.time.removeAllEvents();
    this.sound.stopAll();

    let nextStep = this.steps.findLast(
      (step) => step.order === this.currentStep.order - 1
    );

    if (nextStep) {
      if (nextStep.conditionKey) {
        nextStep = this.steps.findLast(
          (step) =>
            step.order === this.currentStep.order - 1 &&
            step.conditionKey == conditionKey
        );
      }

      let sceneObject = {
        avatar: this.avatar,
        autoPlay: this.autoPlay,
        steps: this.steps,
        currentStep: nextStep,
        subjectLang: this.subjectLang,
        isEvent: this.isEvent,
        isDemo: this.isDemo,
      };

      if (nextStep.actionType == "goPage") {
        let newSceneName = "StoryScene" + Date.now();

        let newScene = await new StoryScene(newSceneName);
        this.scene.add(newSceneName, newScene, false, sceneObject);
        this.scene.launch(newSceneName, sceneObject);

        this.scene.get(newSceneName).cameras.main.scrollX =
          this.game.config.width;

        // this.cameras.main.pan(
        //   -this.cameras.main.width /2,
        //   this.cameras.main.height / 2,
        //   1000,
        //   "Power2"
        // );

        this.scene
          .get(newSceneName)
          .cameras.main.pan(
            this.scene.get(newSceneName).cameras.main.width / 2,
            this.scene.get(newSceneName).cameras.main.height / 2,
            1000,
            "Power2"
          );

        this.time.delayedCall(1000, () => {
          this.scene.stop();
        });
      }

      if (nextStep.actionType == "goMap")
        this.scene.start("MapScene", sceneObject);

      if (nextStep.actionType == "goGame")
        this.scene.start("GameScene", sceneObject);

      if (nextStep.actionType == "goItem")
        this.scene.start("ItemScene", sceneObject);

      if (nextStep.actionType == "goQuestion") {
        sceneObject.currentProggressStep = nextStep.Page.length - 1;
        let newSceneName = "QuizScene" + Date.now();

        let newScene = await new QuizScene(newSceneName);
        this.scene.add(newSceneName, newScene, false, sceneObject);
        this.scene.launch(newSceneName, sceneObject);

        this.scene.get(newSceneName).cameras.main.scrollX =
          this.game.config.width;

        // this.cameras.main.pan(
        //   -this.cameras.main.width /2,
        //   this.cameras.main.height / 2,
        //   1000,
        //   "Power2"
        // );

        this.scene
          .get(newSceneName)
          .cameras.main.pan(
            this.scene.get(newSceneName).cameras.main.width / 2,
            this.scene.get(newSceneName).cameras.main.height / 2,
            1000,
            "Power2"
          );

        this.time.delayedCall(1000, () => {
          this.scene.stop();
        });
      }

      if (nextStep.actionType == "goReflection") {
        let newSceneName = "NilamScene" + Date.now();
        let newScene = await new NilamScene(newSceneName);
        this.scene.add(newSceneName, newScene, false, sceneObject);
        this.scene.launch(newSceneName, sceneObject);

        this.scene.get(newSceneName).cameras.main.scrollX =
          this.game.config.width;

        // this.cameras.main.pan(
        //   -this.cameras.main.width /2,
        //   this.cameras.main.height / 2,
        //   1000,
        //   "Power2"
        // );

        this.scene
          .get(newSceneName)
          .cameras.main.pan(
            this.scene.get(newSceneName).cameras.main.width / 2,
            this.scene.get(newSceneName).cameras.main.height / 2,
            1000,
            "Power2"
          );

        this.time.delayedCall(1000, () => {
          this.scene.stop();
        });
      }
    } else {
      this.scene.stop();
      router.push({
        name: this.isEvent
          ? "PageEventABCBookChapters"
          : this.isDemo
          ? "PageABCBookChaptersDemo"
          : "PageABCBookChapters",
        params: { bookKey: this.steps[0].Chapter.bookKey },
      });
    }
  }

  async nextQ() {
    this.imgNextCtrlBtn.removeInteractive();
    this.imgPrevCtrlBtn.removeInteractive();
    this.imgPlayCtrlBtn.removeInteractive();
    this.imgPauseCtrlBtn.removeInteractive();

    this.time.removeAllEvents();
    this.sound.stopAll();

    let sceneObject = {
      avatar: this.avatar,
      autoPlay: this.autoPlay,
      steps: this.steps,
      currentStep: this.currentStep,
      subjectLang: this.subjectLang,
      currentProggressStep: this.currentProggressStep + 1,
      isEvent: this.isEvent,
      isDemo: this.isDemo,
    };

    let newSceneName = "QuizScene" + Date.now();
    let newScene = await new QuizScene(newSceneName);
    this.scene.add(newSceneName, newScene);
    this.scene.launch(newSceneName, sceneObject);
    this.scene.bringToTop();
    // this.scene.get(newSceneName).cameras.main.scrollX =
    //   -this.game.config.width / 2;

    this.cameras.main.pan(
      this.cameras.main.width * 1.5,
      this.cameras.main.height / 2,
      1000,
      "Power2"
    );

    // this.scene
    //   .get(newSceneName)
    //   .cameras.main.pan(
    //     this.scene.get(newSceneName).cameras.main.width / 2,
    //     this.scene.get(newSceneName).cameras.main.height / 2,
    //     1000,
    //     "Power2"
    //   );

    this.time.delayedCall(1000, () => {
      this.scene.stop();
    });
  }

  async prevQ() {
    this.imgNextCtrlBtn.removeInteractive();
    this.imgPrevCtrlBtn.removeInteractive();
    this.imgPlayCtrlBtn.removeInteractive();
    this.imgPauseCtrlBtn.removeInteractive();

    this.time.removeAllEvents();
    this.sound.stopAll();

    let sceneObject = {
      avatar: this.avatar,
      autoPlay: this.autoPlay,
      steps: this.steps,
      currentStep: this.currentStep,
      subjectLang: this.subjectLang,
      currentProggressStep: this.currentProggressStep - 1,
      isEvent: this.isEvent,
      isDemo: this.isDemo,
    };

    let newSceneName = "QuizScene" + Date.now();

    let newScene = await new QuizScene(newSceneName);
    this.scene.add(newSceneName, newScene, false, sceneObject);
    this.scene.launch(newSceneName, sceneObject);

    this.scene.get(newSceneName).cameras.main.scrollX = this.game.config.width;

    // this.cameras.main.pan(
    //   -this.cameras.main.width /2,
    //   this.cameras.main.height / 2,
    //   1000,
    //   "Power2"
    // );

    this.scene
      .get(newSceneName)
      .cameras.main.pan(
        this.scene.get(newSceneName).cameras.main.width / 2,
        this.scene.get(newSceneName).cameras.main.height / 2,
        1000,
        "Power2"
      );

    this.time.delayedCall(1000, () => {
      this.scene.stop();
    });
  }

  autoPlayOFF() {
    this.toggleBtnOff.visible = true;
    this.toggleBtnOn.visible = false;
    this.toggleText.setText(
      i18n.t("string.autoplay") +
        "            " +
        i18n.t("miniGames.general.off")
    );
    this.autoPlay = false;
    this.stop();
    // if (this.countdownEvent) {
    //   this.countdownEvent.destroy();
    // }
    // this.countdownText.setVisible(false);
    let tempSettings = _.cloneDeep(store.state.settings.data);
    tempSettings.autoPlay = "OFF";
    store.commit("updateSettings", tempSettings);
  }

  autoPlayON() {
    this.toggleBtnOff.visible = false;
    this.toggleBtnOn.visible = true;
    this.toggleText.setText(
      i18n.t("string.autoplay") +
        "              " +
        i18n.t("miniGames.general.on")
    );
    this.autoPlay = true;
    // this.play();
    let tempSettings = _.cloneDeep(store.state.settings.data);
    tempSettings.autoPlay = "ON";
    store.commit("updateSettings", tempSettings);
  }

  clickClose() {
    this.sound.stopAll();
    this.simpleClickSound.setVolume(this.sfxVolume);
    this.simpleClickSound.play();
    this.scene.stop();
    router.push({
      name: this.isEvent
        ? "PageEventABCBookChapters"
        : this.isDemo
        ? "PageABCBookChaptersDemo"
        : "PageABCBookChapters",
      params: { bookKey: this.steps[0].Chapter.bookKey },
    });
  }

  clickSetting() {
    this.simpleClickSound.setVolume(this.sfxVolume);
    this.simpleClickSound.play();
    if (this.audio) this.audio.pause();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: this.scene.key,
      bookKey: this.steps[0].Chapter.bookKey,
      isEvent: this.isEvent,
      isDemo: this.isDemo,
    });
  }

  clickHint() {
    this.simpleClickSound.setVolume(this.sfxVolume);
    this.simpleClickSound.play();
    if (this.audio) this.audio.pause();
    this.scene.pause();
    this.scene.launch("Hint", {
      sceneName: this.scene.key,
      bookKey: this.steps[0].Chapter.bookKey,
      hintImage:
        this.currentStep.Page[this.currentProggressStep].isSecondaryImg &&
        this.isTranslateON
          ? "imgSecondary" +
            this.currentStep.Page[this.currentProggressStep].key
          : "imgPrimary" + this.currentStep.Page[this.currentProggressStep].key,
    });
  }

  update() {}

  renderHtmlString(scene, htmlString, x, y, maxWidth) {
    const style = {
      fontFamily: "Inter, sans-serif",
      fontSize: "18px",
      color: "#000000",
    };

    const vocabTextStyle = {
      fontFamily: "Inter, sans-serif",
      fontSize: "18px",
      // color: "#22c55e ",
      color: "#8E24AA",
      fontStyle: "bold",
    };

    const isMandarin = (text) => {
      // Check if the string contains Chinese characters using a regex
      return /[\u4e00-\u9fff]/.test(text);
    };

    const splitMixedText = (text) => {
      // Updated regex to split mixed Mandarin, English, and punctuation
      const regex =
        /[\u4e00-\u9fff]+[，。、！？：]*|[a-zA-Z0-9]+[.,!?'"“”‘’;:…*/&%$#@^|~`<>[\](){}\\+=_：—–-]*|[.,!?'"“”‘’;:…*/&%$#@^|~`<>[\](){}\\+=_：—–-]|[\s]+/g;
      return text.match(regex) || [];
    };

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const paragraph = doc.querySelector("p");

    if (paragraph) {
      let currentX = x;
      let currentY = y;
      let lineContentWidth = 0; // To track the width of the current line
      const lines = []; // Store each line's text objects for later centering
      const vocabElements = []; // Store vocab elements for underlining

      paragraph.childNodes.forEach((node) => {
        if (node.nodeType === 3) {
          // TEXT_NODE
          // Split mixed English, Mandarin, and punctuation text
          const parts = splitMixedText(node.textContent);

          parts.forEach((part) => {
            const tempText = scene.add.text(0, 0, part, style);
            const partWidth = tempText.width;
            tempText.destroy();

            // Check if the word/character exceeds maxWidth
            if (lineContentWidth + partWidth > maxWidth) {
              lines.push({ width: lineContentWidth, y: currentY }); // Store the line width and y position
              currentY += 30; // Move down to the next line
              lineContentWidth = 0; // Reset the line width
              currentX = x; // Reset to start of the line
            }

            // Add the word/character/punctuation as a text object
            this.questionTexts.push(
              scene.add.text(currentX, currentY, part, style)
            );
            lineContentWidth += partWidth; // Update the line width
            currentX += partWidth; // Move to the next position
          });
        } else if (node.nodeType === 1 && node.nodeName === "VOCAB") {
          const vocabTextNode = node.textContent.trim();
          const vocabKey = node.getAttribute("key");
          const tempText = scene.add.text(0, 0, vocabTextNode, vocabTextStyle);
          const vocabTextWidth = tempText.width;
          tempText.destroy();

          const prevNode = node.previousSibling;
          const nextNode = node.nextSibling;
          const isMandarinPrev =
            prevNode && isMandarin(prevNode.textContent.trim());
          const isMandarinNext =
            nextNode && isMandarin(nextNode.textContent.trim());

          // Add space only if it's English and doesn't have space before the vocab
          if (
            prevNode &&
            !isMandarinPrev &&
            !prevNode.textContent.endsWith(" ")
          ) {
            currentX += 5; // Add a space for English before vocab
          }

          // Check if adding vocab text exceeds maxWidth
          if (lineContentWidth + vocabTextWidth > maxWidth) {
            lines.push({ width: lineContentWidth, y: currentY }); // Store the line width and y position
            currentY += 30; // Move down to the next line
            lineContentWidth = 0; // Reset the line width
            currentX = x; // Reset to start of the line
          }

          // Add vocab text
          const vocabText = scene.add.text(
            currentX,
            currentY,
            vocabTextNode,
            vocabTextStyle
          );
          vocabText.setInteractive({ useHandCursor: true });
          vocabText.on("pointerdown", () => {
            console.log("Vocab clicked:", vocabKey);
          });

          this.questionTexts.push(vocabText);

          // Store vocab element for underlining later
          vocabElements.push({ text: vocabText, y: currentY });

          lineContentWidth += vocabTextWidth; // Update the line width
          currentX += vocabTextWidth; // Move to the next position

          // Add space after vocab if it's English and no space after the vocab
          if (
            nextNode &&
            !isMandarinNext &&
            !nextNode.textContent.startsWith(" ")
          ) {
            currentX += 5; // Add a space for English after vocab
          }
        }
      });

      // Center each line based on stored widths
      lines.forEach(({ width, y }) => {
        const centeredX = x + (maxWidth - width) / 2; // Center the line
        // Update the x position of each text object in that line
        scene.children.each((child) => {
          if (child.y === y) {
            child.x += centeredX - x; // Adjust x position to center
          }
        });
      });

      // Center the last line if it has content
      if (lineContentWidth > 0) {
        const lastLineY = currentY;
        const centeredX = x + (maxWidth - lineContentWidth) / 2; // Center the last line
        scene.children.each((child) => {
          if (child.y === lastLineY) {
            child.x += centeredX - x; // Adjust x position to center
          }
        });
      }

      // Draw underlines after centering
      vocabElements.forEach(({ text, y }) => {
        const underline = scene.add.graphics();
        underline.lineStyle(2, 0x22c55e);
        underline.lineBetween(
          text.x,
          y + text.height,
          text.x + text.width,
          y + text.height
        ); // Position underlines correctly
      });

      return currentY;
    }
  }

  createProgressBar() {
    const barX = 150;
    const barY = 33;
    const barWidth = 240;
    const barHeight = 15;

    const progressRatio =
      this.currentProggressStep > 0
        ? this.currentProggressStep / this.progressSteps
        : 0.05;

    // Create the yellow fill (initially empty)
    this.progressFill = this.add.graphics();
    this.progressFill.fillStyle(0x0fd02b, 1); // Yellow color
    this.progressFill.fillRoundedRect(
      barX,
      barY,
      barWidth * progressRatio,
      barHeight,
      5
    ); // Initially empty bar

    // Create black outline for the progress bar
    this.progressOutline = this.add.graphics();
    this.progressOutline.lineStyle(3, 0x202020, 1); // Black outline
    this.progressOutline.strokeRoundedRect(barX, barY, barWidth, barHeight, 5); // Full outline

    // Store bar dimensions for later use
    this.progressBar = { x: barX, y: barY, width: barWidth, height: barHeight };
  }

  updateProgress() {
    // Calculate progress as a percentage of total steps
    const progressRatio = (this.currentProggressStep + 1) / this.progressSteps;

    // Update the yellow fill to reflect the current progress
    this.progressFill.clear(); // Clear previous progress
    this.progressFill.fillStyle(0x0fd02b, 1); // Yellow color
    this.progressFill.fillRoundedRect(
      this.progressBar.x,
      this.progressBar.y,
      this.progressBar.width * progressRatio, // Updated width based on progress
      this.progressBar.height,
      5
    );
  }

  shakeObject(object, duration, intensity) {
    this.tweens.add({
      targets: object,
      x: "+=" + Phaser.Math.Between(-intensity, intensity),
      y: "+=" + Phaser.Math.Between(-intensity, intensity),
      ease: "Linear",
      duration: duration,
      repeat: 2,
      yoyo: true,
    });
  }

  startCountdown() {
    if (this.autoPlay) {
      let countdown = constants.AUTO_PLAY_COUNTER;
      this.countdownText.setVisible(true);
      this.countdownText.setText(countdown.toString());
      this.countdownEvent = this.time.addEvent({
        delay: 1000,
        callback: () => {
          countdown--;
          this.countdownText.setText(countdown.toString());
          if (countdown <= 0) {
            this.countdownEvent.remove(false);
            this.countdownText.setVisible(false);
            this.triggerNext();
          }
        },
        loop: true,
      });
    }
  }
}
