<template>
  <v-card class="list-card" flat @click="act()">
    <div class="d-flex flex-no-wrap">
      <v-avatar class="ma-3" size="125" tile>
        <v-img v-if="data.translatableImage" :src="JSON.parse(data.translatableImage)[$_getLocale()]
            ? JSON.parse(data.translatableImage)[$_getLocale()]
              .url
            : JSON.parse(data.translatableImage)[
              getSubjectLang()
            ].url
          "></v-img>
      </v-avatar>
      <div style="width: 100%" class="d-flex flex-column justify-space-between">
        <div class="text-h5 mx-2 pt-3">
          <!-- {{
          JSON.parse(data.translatableName)[$_getLocale()]
            ? JSON.parse(data.translatableName)[$_getLocale()]
            : JSON.parse(data.translatableName)[
                getSubjectLang()
              ]
        }} -->
          {{ JSON.parse(data.translatableName)[getSubjectLang()] }}
        </div>

        <div class="px-3 grey--text text-caption mb-1" v-if="
          data.timestampStart &&
          ($moment() < $moment(data.timestampStart) || data.PlayerBook.length == 0)
        ">
          {{ $moment(data.timestampStart).locale(($_getLocale() == "zh") ? "zh-cn" : $_getLocale()).format("LL") }}
          {{
            data.timestampEnd
              ? " - " +
              $moment(data.timestampEnd).locale(($_getLocale() == "zh") ? "zh-cn" : $_getLocale()).format("LL")
              : " +"
          }}
        </div>

        <div class="px-3" v-if="data.PlayerBook.length > 0">
          <v-progress-linear v-if="data.progress < 1" color="blue" rounded height="8"
            :value="data.progress * 100"></v-progress-linear>
          <v-progress-linear v-else color="green" rounded height="8" :value="100"></v-progress-linear>
        </div>

        <v-card-actions class="flex-wrap">
          <v-chip small dark class="my-1">{{ data.subject }}</v-chip>
          <ChipBookType :book="data" class="ml-2" />
          <v-spacer></v-spacer>
          <v-btn v-if="data.PlayerBook.length > 0 && data.progress < 1" class="text-h6 constant-tilt-shake" color="blue"
            dark @click="read">
            ▶ {{ $t("string.read") }}
          </v-btn>
          <v-btn v-else-if="data.PlayerBook.length > 0" class="text-h6" color="success" dark @click="read">
            ✔ {{ $t("string.read") }}
          </v-btn>
          <v-btn v-else-if="
            data.timestampStart &&
            $moment() < $moment(data.timestampStart)
          " text dark color="orange lighten-1">
            {{ $t("string.comingSoon") }}
          </v-btn>
          <v-btn v-else class="text-h6 constant-tilt-shake" color="green" dark
            :loading="api.isLoading && selectedBook == data">
            <div>{{ $t("action.claim") }}</div>
          </v-btn>
        </v-card-actions>
      </div>
    </div>
    <!-- <v-card-actions v-if="settings.debug">
    <v-btn small dark @click="claimBook(data)"><v-icon color="red" small> mdi-record </v-icon> Claim</v-btn>
  </v-card-actions> -->
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    ChipBookType: () =>
      import(
        /* webpackChunkName: "component-chip-book-type" */ "@/components/ABC/ChipBookType"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  props: [
    "api",
    "claimBook",
    "data",
    "onComingSoonButtonClicked",
    "selectedBook",
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    getSubjectLang() {
      if (this.data.subject == "Mandarin") {
        return "zh";
      } else if (this.data.subject == "Malay") {
        return "ms";
      } else {
        return "en";
      }
    },
    act() {
      if (this.data.PlayerBook.length > 0) {
        this.read()
      }
      else if (this.data.timestampStart && this.$moment() < this.$moment(this.data.timestampStart)) {
        this.onComingSoonButtonClicked(this.data)
      }
      else {
        this.claimBook(this.data)
      }
    },
    read() {
      this.$router.push({
        name: "PageABCBookCover",
        params: { bookKey: this.data.key },
      });
    },
  }
}
</script>

<style scoped>
.list-card {
  padding: 5px;
  color: #785c3a;
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #fff9f1;
  overflow-x: hidden !important;
}

.constant-tilt-shake {
  animation: tilt-shaking 2s infinite;
}

.constant-tilt-shake:hover {
  animation: none;
}

@keyframes tilt-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }

  3% {
    transform: translate(2px, -2px) rotate(5deg);
  }

  6% {
    transform: translate(0, 0) rotate(0eg);
  }

  9% {
    transform: translate(-2px, -2px) rotate(-5deg);
  }

  12% {
    transform: translate(0, 0) rotate(0deg);
  }

  15% {
    transform: translate(2px, -2px) rotate(5deg);
  }

  18% {
    transform: translate(0, 0) rotate(0eg);
  }

  21% {
    transform: translate(-2px, -2px) rotate(-5deg);
  }

  24% {
    transform: translate(0, 0) rotate(0deg);
  }

  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
</style>