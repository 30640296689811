import { Scene } from "phaser";
import store from "@/store";
import i18n from "@/plugins/i18n";

export default class LandingScene extends Scene {
  constructor() {
    super({ key: "LandingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.questions = data.questions;
    this.friend = data.friend;
    this.bossImage = data.bossImage;
    this.env = data.env;
    this.noOfRounds = data.noOfRounds;
    this.noOfCuts = data.noOfCuts;
    this.difficulty = data.difficulty;
    this.lampTime = data.lampTime;
    this.puzzle = data.puzzle;
  }

  create() {
    if (
      store.state.saveGame.data &&
      this.questions[0].key == store.state.saveGame.data.questions[0].key
    ) {
      this.backImg = this.add
        .image(0, 0, "imgBackground")
        .setDisplaySize(480, 854)
        .setOrigin(0);

      this.logoImg = this.add
        .image(240, 250, "imgLogo")
        .setOrigin(0.5)
        .setScale(0.7);

      this._continueBtn = this.add
        .image(240, 400, "imgOptionBtn")
        .setScale(0.35)
        .setInteractive({ useHandCursor: true })
        .on(
          "pointerdown",
          () => {
            this.backImg.destroy();
            this.logoImg.destroy();
            this._continueBtn.destroy();
            this._continueTxt.destroy();
            this._newBtn.destroy();
            this._newTxt.destroy();
            this.scene.launch("PlayScene", store.state.saveGame.data);
          },
          this
        );
      this._continueTxt = this.add
        .text(240, 400, i18n.t("miniGames.general.resume"), {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 20,
          color: "#000",
          align: "center",
        })
        .setOrigin(0.5);

      this._newBtn = this.add
        .image(240, 470, "imgOptionBtn")
        .setScale(0.35)
        .setInteractive({ useHandCursor: true })
        .on(
          "pointerdown",
          () => {
            this.backImg.destroy();
            this.logoImg.destroy();
            this._continueBtn.destroy();
            this._continueTxt.destroy();
            this._newBtn.destroy();
            this._newTxt.destroy();
            this.startNew();
          },
          this
        );
      this._newTxt = this.add
        .text(240, 470, i18n.t("miniGames.general.newGame"), {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 20,
          color: "#000",
          align: "center",
        })
        .setOrigin(0.5);

      this.tweens.add({
        targets: this._continueBtn,
        scaleX: 0.37,
        scaleY: 0.37,
        ease: "Linear",
        duration: 500,
        yoyo: true,
        repeat: -1,
      });

      this.tweens.add({
        targets: this._continueTxt,
        scaleX: 1.05,
        scaleY: 1.05,
        ease: "Linear",
        duration: 500,
        yoyo: true,
        repeat: -1,
      });
    } else {
      this.startNew();
    }

    this.bgSound = this.sound.add("bgm");
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
    this.bgSound.loop = true;
    this.bgSound.play();
  }

  startNew() {
    this.scene.launch(this.puzzle ? "PuzzleScene" : "IntroScene", {
      noOfRounds: this.noOfRounds,
      noOfCuts: this.noOfCuts,
      score: 0,
      round: 0,
      qCount: 0,
      avatar: this.avatar,
      name: this.name,
      questions: this.questions,
      friend: this.friend,
      difficulty: this.difficulty,
      countCorrect: 0,
      bossImage: this.bossImage,
      env: this.env,
      currentCampTileX: Math.floor(Math.random() * 5),
      nextCampTileX: Math.floor(Math.random() * 5),
      totalBones:
        this.noOfRounds +
        Math.floor(this.noOfRounds * (Math.random() * (0.6 - 0.2) + 0.2)),
      collectedBones: this.puzzle ? 4 : 0,
      collectedSkull: 0,
      lampTime: this.lampTime,
    });
  }
  update() {
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
  }
}
