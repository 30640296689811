import { Scene } from "phaser";
import Phaser from "phaser";
import store from "@/store";
import i18n from "@/plugins/i18n";
import router from "@/router";
import _ from "lodash";

export default class BossScene extends Scene {
  constructor() {
    super({ key: "BossScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.score = data.score;
    this.round = data.round;
    this.questions = data.questions;
    this.bossRound = data.bossRound;
    this.friend = data.friend;
    this.noOfRounds = data.noOfRounds;
    this.forceFriend = data.forceFriend;
    this.difficulty = data.difficulty;
    this.countCorrect = data.countCorrect;
    this.avatarX = data.avatarX;
    this._customers = data.customers;
    this._foods = data.foods;
  }

  avatarObject = [];
  customerCount = 0;
  servedFoodCount = 0;
  exitCustomerCount = 0;
  customerList = [];
  gameOngoing = true;
  indexToBeServe = 0;
  IS_MOBILE =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  create() {
    //* setup the Background  *//
    this.imgBackground = this.add
      .image(0, 0, "imgBackground")
      .setDisplaySize(480, 854)
      .setOrigin(0);
    this.imgDoor = this.add.image(0, 0, "imgDoor").setOrigin(0).setDepth(2);
    this.sptDoor = this.add.sprite(233, 150, "sptDoor").setScale(0.9);
    this.imgCounter = this.add
      .image(0, 550, "imgCounter")
      .setOrigin(0)
      .setDepth(2);
    this.imgWoodPlane1 = this.add.image(-383, 690, "imgWoodPlane").setDepth(2);
    this.imgWoodPlane2 = this.add.image(-243, 690, "imgWoodPlane").setDepth(2);
    this.imgWoodPlane3 = this.add.image(-103, 690, "imgWoodPlane").setDepth(2);
    this.scoreBoard = this.add
      .image(80, 35, "imgScorePanel")
      .setScale(0.3)
      .setDepth(2);
    this.resultBoard = this.add
      .image(70, 70, "imgScorePanel")
      .setScale(0.25, 0.2)
      .setDepth(2);
    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.6)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.scoreText = this.add
      .text(
        30,
        35,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 18,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5)
      .setDepth(2);
    this.roundText = this.add
      .text(30, 70, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#492a12",
      })
      .setOrigin(0, 0.5)
      .setDepth(2);

    this.feedback = this.add
      .text(240, 390, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 48,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(5)
      .setShadow(3, 3, "#000", 0, true, true);

    this.warningBackground = this.add
      .rectangle(240, 390, 480, 200, 0xff0000)
      .setDepth(4)
      .setAlpha(0);
    this.warningText = this.add
      .text(240, 370, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 48,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(5)
      .setShadow(3, 3, "#000", 0, true, true);

    this.warningMessageText = this.add
      .text(240, 420, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 28,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(5)
      .setShadow(1.5, 1.5, "#000", 0, true, true);

    // * setup the Sprite Animations *//
    this.anims.create({
      key: "animMagic",
      frames: this.anims.generateFrameNumbers("sptMagic", {
        frames: [0, 1, 2, 3, 4, 5, 6, 5, 6, 7, 8, 9],
      }),
      frameRate: 5,
    });

    this.anims.create({
      key: "animDoorOpen",
      frames: this.anims.generateFrameNumbers("sptDoor", {
        frames: [0, 1, 2, 3, 4, 5, 5, 5, 5, 5, 4, 3, 2, 1, 0],
      }),
      frameRate: 12,
    });

    this.anims.create({
      key: "animStoveOn",
      frames: this.anims.generateFrameNumbers("sptStovePan", {
        frames: [1, 2],
      }),
      frameRate: 3,
      repeat: -1,
    });

    this.anims.create({
      key: "animCooking",
      frames: this.anims.generateFrameNumbers("sptStoveActions", {
        frames: [3, 4, 5, 0],
      }),
      frameRate: 3,
      repeat: -1,
    });

    this.anims.create({
      key: "animCooked",
      frames: this.anims.generateFrameNumbers("sptStoveActions", {
        frames: [1, 2],
      }),
      frameRate: 2,
      repeat: -1,
    });

    this.anims.create({
      key: "animBurning",
      frames: this.anims.generateFrameNumbers("sptStoveActions", {
        frames: [8, 9],
      }),
      frameRate: 2,
      repeat: -1,
    });

    this.anims.create({
      key: "animOverCooked",
      frames: this.anims.generateFrameNumbers("sptStoveActions", {
        frames: [6, 7],
      }),
      frameRate: 2,
      repeat: -1,
    });

    this.anims.create({
      key: "animFireExting",
      frames: this.anims.generateFrameNumbers("sptFireExtingActions", {
        frames: [0, 1, 2, 3, 4, 4, 3, 4, 4, 3, 4, 4, 3, 2, 1, 0],
      }),
      frameRate: 10,
    });

    this.anims.create({
      key: "animBossL",
      frames: this.anims.generateFrameNumbers("sptBoss", {
        frames: [0, 1],
      }),
      frameRate: 1,
      repeat: -1,
    });

    this.anims.create({
      key: "animBossS",
      frames: this.anims.generateFrameNumbers("sptBoss", {
        frames: [2, 3],
      }),
      frameRate: 1,
      repeat: -1,
    });

    this.anims.create({
      key: "animShine",
      frames: this.anims.generateFrameNumbers("sptShine", {
        frames: [0, 2],
      }),
      repeat: -1,
      frameRate: 5,
    });

    //* declare the SFXs *//
    this.sfxScoreIncrease = this.sound.add("sfxScoreIncrease");
    this.sfxSimpleClick = this.sound.add("sfxSimpleClick");
    this.sfxGo = this.sound.add("sfxGo");
    this.sfxDoor = this.sound.add("sfxDoor");
    this.sfxBubble = this.sound.add("sfxBubble");
    this.sfxCookingBell = this.sound.add("sfxCookingBell");
    this.sfxMagic = this.sound.add("sfxMagic");
    this.sfxSlide = this.sound.add("sfxSlide");
    this.sfxShine = this.sound.add("sfxShine");
    this.sfxWarningAlarm = this.sound.add("sfxWarningAlarm");
    this.sfxWarningAlarm.loop = true;
    this.sfxBoss = this.sound.add("sfxBoss");
    this.sfxBossX = this.sound.add("sfxBossX");
    this.sfxWrong = this.sound.add("sfxWrong");
    this.sfxCorrect = this.sound.add("sfxCorrect");
    this.sfxBossSuccess = this.sound.add("sfxBossSuccess");
    this.sfxBossFail = this.sound.add("sfxBossFail");
    this.sfxCollect = this.sound.add("sfxCollect");

    this.sfxA = this.sound.add("sfxA");
    this.sfxB = this.sound.add("sfxB");
    this.sfxC = this.sound.add("sfxC");
    this.sfxD = this.sound.add("sfxD");
    this.sfxE = this.sound.add("sfxE");

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 770, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 770, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 770, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }
    this.tweens.add({
      targets: this.avatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    //* Boss *//
    let bossFoodList = [
      {
        item: "FISH",
        itemImg: this.add.image(0, 0, "imgFish").setScale(0).setOrigin(0, 0.5),
        sptCW: this.add.sprite(0, 0, "sptCW").setScale(0).setOrigin(0, 0.5),
      },
      {
        item: "FISH",
        itemImg: this.add.image(0, 0, "imgFish").setScale(0).setOrigin(0, 0.5),
        sptCW: this.add.sprite(0, 0, "sptCW").setScale(0).setOrigin(0, 0.5),
      },
      {
        item: "FISH",
        itemImg: this.add.image(0, 0, "imgFish").setScale(0).setOrigin(0, 0.5),
        sptCW: this.add.sprite(0, 0, "sptCW").setScale(0).setOrigin(0, 0.5),
      },
      {
        item: "FISH",
        itemImg: this.add.image(0, 0, "imgFish").setScale(0).setOrigin(0, 0.5),
        sptCW: this.add.sprite(0, 0, "sptCW").setScale(0).setOrigin(0, 0.5),
      },

      {
        item: "EGG",
        itemImg: this.add.image(0, 0, "imgEgg").setScale(0).setOrigin(0, 0.5),
        sptCW: this.add.sprite(0, 0, "sptCW").setScale(0).setOrigin(0, 0.5),
      },
      {
        item: "EGG",
        itemImg: this.add.image(0, 0, "imgEgg").setScale(0).setOrigin(0, 0.5),
        sptCW: this.add.sprite(0, 0, "sptCW").setScale(0).setOrigin(0, 0.5),
      },
      {
        item: "EGG",
        itemImg: this.add.image(0, 0, "imgEgg").setScale(0).setOrigin(0, 0.5),
        sptCW: this.add.sprite(0, 0, "sptCW").setScale(0).setOrigin(0, 0.5),
      },
      {
        item: "EGG",
        itemImg: this.add.image(0, 0, "imgEgg").setScale(0).setOrigin(0, 0.5),
        sptCW: this.add.sprite(0, 0, "sptCW").setScale(0).setOrigin(0, 0.5),
      },

      {
        item: "CORN",
        itemImg: this.add.image(0, 0, "imgCorn").setScale(0).setOrigin(0, 0.5),
        sptCW: this.add.sprite(0, 0, "sptCW").setScale(0).setOrigin(0, 0.5),
      },
      {
        item: "CORN",
        itemImg: this.add.image(0, 0, "imgCorn").setScale(0).setOrigin(0, 0.5),
        sptCW: this.add.sprite(0, 0, "sptCW").setScale(0).setOrigin(0, 0.5),
      },
      {
        item: "CORN",
        itemImg: this.add.image(0, 0, "imgCorn").setScale(0).setOrigin(0, 0.5),
        sptCW: this.add.sprite(0, 0, "sptCW").setScale(0).setOrigin(0, 0.5),
      },
      {
        item: "CORN",
        itemImg: this.add.image(0, 0, "imgCorn").setScale(0).setOrigin(0, 0.5),
        sptCW: this.add.sprite(0, 0, "sptCW").setScale(0).setOrigin(0, 0.5),
      },
    ];

    for (let i = bossFoodList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [bossFoodList[i], bossFoodList[j]] = [bossFoodList[j], bossFoodList[i]];
    }

    this.boss = this.add
      .sprite(240, -100, "sptBoss")
      .setScale(0.5)
      .setDepth(1)
      .setOrigin(0.5, 1);
    this.boss.bubble = this.add
      .image(240, 220, "imgBossBubble")
      .setScale(0)
      .setDepth(1);
    this.boss.tobeServe = this.add
      .circle(0, 0, 40, 0xffff00)
      .setScale(0)
      .setDepth(1);
    this.boss.foods = bossFoodList;

    this.boss.waitingTime = 0;
    this.boss.waitingTimeText = this.add
      .text(0, 0, this.boss.waitingTime + " s", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 18,
        fill: "#000",
      })
      .setScale(0)
      .setDepth(2);

    this.energyLineX = new Phaser.Geom.Line(-80, 530, 80, 530);
    this.energyGraphicsX = this.add.graphics({
      lineStyle: { width: 14, color: 0x000000 },
    });
    this.energyGraphicsX.strokeLineShape(this.energyLineX).setDepth(7);
    this.energyGraphicsX.x = 240;

    this.energyLine = new Phaser.Geom.Line(-78, 530, -78, 530);
    this.energyGraphics = this.add.graphics({
      lineStyle: { width: 10, color: 0xff0000 },
    });
    this.energyGraphics.strokeLineShape(this.energyLine).setDepth(7);
    this.energyGraphics.x = 240;
    this.energyLineX.visible = false;
    this.energyGraphicsX.visible = false;
    this.energyLine.visible = false;
    this.energyGraphics.visible = false;

    //* Stoves and raw foods *//
    this.stoveGroup = this.add.group();

    for (let index = 0; index < 3; index++) {
      const stove = this.add
        .sprite(140 * (index + 1) - 530, 605, "sptStovePan")
        .setFrame(0)
        .setDepth(2);
      stove.status = "OFF";
      stove.actions = this.add
        .sprite(140 * (index + 1) - 530, 580, "sptStoveActions")
        .setFrame(0)
        .setDepth(4);
      stove._id = index + 1;
      this.stoveGroup.add(stove);
    }

    this.imgRawCrab = this.add
      .sprite(-383, 690, "sptCrab")
      .setFrame(0)
      .setDepth(2);
    this.imgRawWing = this.add
      .sprite(-243, 690, "sptWing")
      .setFrame(0)
      .setDepth(2);
    this.imgRawSteak = this.add
      .sprite(-103, 690, "sptSteak")
      .setFrame(0)
      .setDepth(2);

    this.coockedFoodGroup = this.add.group();

    let foodList = [1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3];
    for (let i = foodList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [foodList[i], foodList[j]] = [foodList[j], foodList[i]];
    }

    for (let index = 0; index < 12; index++) {
      const food = this.add
        .image(
          105 * ((index + 1) % 4) + 560,
          575 + Math.floor(index / 4) * 65,
          foodList[index] == 1
            ? "imgCorn"
            : foodList[index] == 2
            ? "imgFish"
            : "imgEgg"
        )
        .setDepth(2);
      food._x = 105 * ((index + 1) % 4) + 80;
      food._y = 575 + Math.floor(index / 4) * 65;
      food.food =
        foodList[index] == 1 ? "CORN" : foodList[index] == 2 ? "FISH" : "EGG";
      food.score = foodList[index] == 1 ? 4 : 8;
      food.status = "DONE";
      food.actions = this.add
        .sprite(
          105 * ((index + 1) % 4) + 560,
          575 + Math.floor(index / 4) * 65,
          "sptStoveActions"
        )
        .setFrame(0)
        .setDepth(4);
      food.actions.play("animCooked");
      food._id = index + 1;
      food.setInteractive({ draggable: true });
      this.coockedFoodGroup.add(food);
    }

    //* Tools *//
    this.sptFireExting = this.add
      .sprite(420, 795, "sptFireExting")
      .setDepth(4)
      .setFrame(0)
      .setInteractive({ useHandCursor: true, draggable: true });
    this.sptFireExting.name = "Exting";
    this.sptFireExting._x = 420;
    this.sptFireExting._y = 795;

    this.imgBin = this.add.image(60, 810, "imgTrashBin").setDepth(2);

    //* Customers Group *//
    this.customerGroup = this.add.group();
    this._customers.forEach((cus) => {
      let customer = this.add
        .image(cus.x, cus.y, cus.texture.key)
        .setScale(cus.scaleX, cus.scaleY)
        .setDepth(1);
      customer.faceMask = this.add
        .image(customer.x, customer.y - 15, cus.faceMask.texture.key)
        .setScale(cus.faceMask.scaleX, cus.faceMask.scaleY)
        .setDepth(4);
      customer.order = {
        item: cus.order.item,
        itemBbl: this.add
          .image(
            cus.order.itemBbl.x,
            cus.order.itemBbl.y,
            cus.order.itemBbl.texture.key
          )
          .setScale(cus.order.itemBbl.scaleX, cus.order.itemBbl.scaleY)
          .setOrigin(0, 0.5),
        feedback: this.add
          .sprite(
            cus.order.feedback.x,
            cus.order.feedback.x,
            cus.order.feedback.texture.key
          )
          .setScale(cus.order.feedback.scaleX, cus.order.feedback.scaleY)
          .setDepth(4)
          .setFrame(cus.order.feedback.frame.name),
      };
      this.customerGroup.add(customer);
      this.tweens.add({
        targets: customer,
        scaleY: cus.scaleY + 0.01,
        scaleX: cus.scaleX - 0.01,
        duration: 500,
        ease: "Sine.easeInOut",
        repeat: -1,
        yoyo: true,
      });
      this.customerCount++;
    });

    this.oldFoodGroup = this.add.group();
    this._foods.forEach((foo) => {
      const food = this.add.image(foo.x, foo.y, foo.texture.key).setDepth(2);
      food.food = foo.food;
      food.score = foo.score;
      food.status = foo.status;
      food.actions = this.add
        .sprite(foo.actions.x, foo.actions.y, foo.actions.texture.key)
        .setFrame(0)
        .setDepth(4);
      food.actions.play("animCooked");
      food._id = foo._id;
      food.setInteractive({ draggable: true });
      this.oldFoodGroup.add(food);
    });

    //* Game transitions starts here *//
    this.sfxShine.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxShine.play();
    this.sfxShine.loop = true;

    this.avatarShine = [];
    this.avatarShine.push(
      this.add
        .sprite(
          this.avatarObject[0].x - 20,
          this.avatarObject[0].y + 20,
          "sptStoveActions"
        )
        .setFrame(0)
        .setDepth(6)
        .setScale(1.2)
        .play("animCooked"),
      this.add
        .sprite(
          this.avatarObject[0].x - 10,
          this.avatarObject[0].y - 20,
          "sptStoveActions"
        )
        .setFrame(0)
        .setDepth(6)
        .setScale(1.2)
        .play("animCooked"),

      this.add
        .sprite(
          this.avatarObject[0].x + 20,
          this.avatarObject[0].y + 20,
          "sptStoveActions"
        )
        .setFrame(0)
        .setDepth(6)
        .setScale(1.2)
        .play("animCooked"),

      this.add
        .sprite(
          this.avatarObject[0].x - 10,
          this.avatarObject[0].y - 20,
          "sptStoveActions"
        )
        .setFrame(0)
        .setDepth(6)
        .setScale(1.2)
        .play("animCooked")
    );

    this.startGame();

    // * Dragging settings *//
    this.input.on("drag", (pointer, gameObject, dragX, dragY) => {
      if (this.gameOngoing) {
        gameObject.x = dragX;
        gameObject.y = dragY;
      }

      if (gameObject.name == "Exting") {
        gameObject.setScale(0.8);
      }

      if (
        Phaser.Geom.Intersects.RectangleToRectangle(
          gameObject.getBounds(),
          this.imgBin.getBounds()
        )
      ) {
        this.imgBin.setScale(1.1);
      } else {
        this.imgBin.setScale(1);
      }

      if (
        Phaser.Geom.Intersects.RectangleToRectangle(
          gameObject.getBounds(),
          new Phaser.Geom.Rectangle(
            this.boss.getBounds().x + 10,
            this.boss.getBounds().y + 20,
            this.boss.getBounds().width - 20,
            this.boss.getBounds().height - 100
          )
        ) ||
        Phaser.Geom.Intersects.RectangleToRectangle(
          gameObject.getBounds(),
          this.boss.bubble.getBounds()
        )
      ) {
        if (this.boss.foods[this.indexToBeServe].item == gameObject.food) {
          this.boss.bubble.setTint(0x7affc1);
        } else {
          this.boss.bubble.setTint(0xff7a7a);
        }
      } else {
        this.boss.bubble.clearTint();
      }
    });

    this.input.on("dragstart", (pointer, gameObject) => {
      if (this.gameOngoing) {
        if (!(gameObject.name && gameObject.name == "Exting")) {
          gameObject.dragging = true;
          gameObject.actions.anims.stop("animCooked");
          gameObject.actions.setFrame(0);
        }
      }
    });

    this.input.on("dragend", (pointer, gameObject) => {
      if (
        Phaser.Geom.Intersects.RectangleToRectangle(
          gameObject.getBounds(),
          new Phaser.Geom.Rectangle(
            this.boss.getBounds().x + 10,
            this.boss.getBounds().y + 20,
            this.boss.getBounds().width - 20,
            this.boss.getBounds().height - 100
          )
        ) ||
        Phaser.Geom.Intersects.RectangleToRectangle(
          gameObject.getBounds(),
          this.boss.bubble.getBounds()
        )
      ) {
        this.boss.foods[this.indexToBeServe].sptCW.x =
          this.boss.foods[this.indexToBeServe].itemImg.x + 50;
        this.boss.foods[this.indexToBeServe].sptCW.y =
          this.boss.foods[this.indexToBeServe].itemImg.y + 20;
        this.boss.foods[this.indexToBeServe].sptCW.setDepth(3);
        this.boss.foods[this.indexToBeServe].sptCW.setScale(0.6);
        gameObject.actions.destroy();
        gameObject.destroy();
        this.boss.waitingTimeText.setScale(0);
        this.boss.tobeServe.setScale(0);

        this.time.removeEvent(this.bossTimeEvent);

        if (this.boss.foods[this.indexToBeServe].item == gameObject.food) {
          this.boss.foods[this.indexToBeServe].sptCW.setFrame(0);
          this.sfxCorrect.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxCorrect.play();
          this.servedFoodCount++;
          this.energyGraphics.clear();
          this.energyLine.x2 =
            this.difficulty == 1
              ? -78 + (156 * this.servedFoodCount) / 6
              : this.difficulty == 2
              ? -78 + (156 * this.servedFoodCount) / 9
              : -78 + (156 * this.servedFoodCount) / 12;
          this.energyGraphics.strokeLineShape(this.energyLine);
          let maxfoods =
            this.difficulty == 1 ? 9 : this.difficulty == 2 ? 6 : 3;
          if (this.indexToBeServe == 2) {
            if (this.boss.foods.length > maxfoods) {
              for (let index = 0; index < 3; index++) {
                this.boss.foods[index].itemImg.destroy();
                this.boss.foods[index].sptCW.destroy();
              }
              this.boss.foods.splice(0, 3);
              for (let index = 0; index < 3; index++) {
                this.boss.foods[index].itemImg.setDepth(2);
                this.boss.foods[index].itemImg.x = 110 + 90 * index;
                this.boss.foods[index].itemImg.y = 210;
                this.tweens.add({
                  targets: this.boss.foods[index].itemImg,
                  scaleX: 0.9,
                  scaleY: 0.9,
                  duration: 200,
                  ease: "Sine.easeInOut",
                });
              }
              this.indexToBeServe = 0;
              this.sfxBoss.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.sfxBoss.play();
              this.setTimer();
            } else {
              this.time.delayedCall(300, () => {
                for (let index = 0; index < 3; index++) {
                  this.boss.foods[index].itemImg.destroy();
                  this.boss.foods[index].sptCW.destroy();
                }
                this.gameSuccess();
              });
            }
          } else {
            this.indexToBeServe++;
            this.setTimer();
          }
        } else {
          this.boss.foods[this.indexToBeServe].sptCW.setFrame(1);
          this.sfxWrong.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxWrong.play();
          this.time.delayedCall(300, () => {
            this.gameFail();
          });
        }
      }

      if (gameObject.name && gameObject.name == "Exting") {
        this.stoveGroup.getChildren().forEach(function (stove) {
          if (
            Phaser.Geom.Intersects.RectangleToRectangle(
              gameObject.getBounds(),
              stove.actions.getBounds()
            )
          ) {
            if (stove.actions.isBurning) {
              stove.actions.isBurning = false;
              let fireExting = this.add
                .sprite(stove.x, stove.y - 15, "sptFireExtingActions")
                .setFrame(0)
                .setScale(0.8)
                .setDepth(5)
                .setOrigin(0.2, 0.7);
              fireExting.play("animFireExting").on("animationcomplete", () => {
                fireExting.setAlpha(0);
                fireExting.destroy();
                stove.actions.anims.stop("animBurning");
                stove.actions.setFrame(0);
                stove.actions.removeInteractive();
                stove.setFrame(0);
                stove.stop("animStoveOn");
              });
            }
          }
        }, this);
      }

      if (
        Phaser.Geom.Intersects.RectangleToRectangle(
          gameObject.getBounds(),
          this.imgBin.getBounds()
        )
      ) {
        gameObject.actions.destroy();
        gameObject.destroy();
        this.imgBin.setScale(1);
      }

      if (gameObject) {
        if (gameObject.name == "Exting") {
          gameObject.setScale(1);
        }
        gameObject.x = gameObject._x;
        gameObject.y = gameObject._y;
        gameObject.dragging = false;
      }

      this.boss.bubble.clearTint();

      // if (gameObject && gameObject.actions) {
      //   gameObject.actions.play("animCooked");
      // }
    });
  }

  update() {
    if (this.avatarMask && this.avatarObject[0]) {
      this.avatarMask.x = this.avatarObject[0].x;
      this.avatarMask.y = this.avatarObject[0].y;
    }
    this.customerGroup.getChildren().forEach(function (customer) {
      customer.order.itemBbl.x = customer.x + 40;
      customer.order.itemBbl.y = customer.y - 30;
      if (customer.order.feedback) {
        customer.order.feedback.x = customer.x + 90;
        customer.order.feedback.y = customer.y - 30;
      }
      if (customer.faceMask) {
        customer.faceMask.x = customer.x;
        customer.faceMask.y = customer.y - 5;
      }
    }, this);
  }

  setTimer() {
    switch (this.difficulty) {
      case 3:
        this.boss.waitingTime = this.IS_MOBILE ? 2 : 3;
        break;
      case 2:
        this.boss.waitingTime = this.IS_MOBILE ? 3 : 4;
        break;
      default:
        this.boss.waitingTime = this.IS_MOBILE ? 4 : 5;
        break;
    }

    this.boss.waitingTimeText.setText(this.boss.waitingTime + " s");
    this.boss.waitingTimeText.x =
      this.boss.foods[this.indexToBeServe].itemImg.x + 23;
    this.boss.waitingTimeText.y =
      this.boss.foods[this.indexToBeServe].itemImg.y + 23;
    this.boss.waitingTimeText.setScale(1);

    this.boss.tobeServe.x = this.boss.foods[this.indexToBeServe].itemImg.x + 35;
    this.boss.tobeServe.y = this.boss.foods[this.indexToBeServe].itemImg.y;
    this.boss.tobeServe.setScale(1);

    this.bossTimeEvent = this.time.addEvent({
      delay: 1000,
      callback: () => {
        this.boss.waitingTime -= 1;
        this.boss.waitingTimeText.setText(this.boss.waitingTime + " s");

        if (this.boss.waitingTime <= 0) {
          this.time.removeEvent(this.bossTimeEvent);
          this.boss.waitingTimeText.setScale(0);
          this.boss.tobeServe.setScale(0);
          this.gameFail();
        }
      },
      callbackScope: this,
      loop: true,
    });
  }

  startGame() {
    this.customerList = [1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2];
    for (let i = this.customerList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.customerList[i], this.customerList[j]] = [
        this.customerList[j],
        this.customerList[i],
      ];
    }

    this.time.delayedCall(1000, () => {
      this.sfxWarningAlarm.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxWarningAlarm.play();
      this.warningText.setText(i18n.t("miniGames.foraging.warning"));
      this.warningText.setTint(0xffffff);
      this.warningMessageText.setText(i18n.t("miniGames.cooking.boss"));
      this.warningMessageText.setTint(0xffffff);
      this.tweens.add({
        targets: this.warningBackground,
        alpha: 0.5,
        duration: 200,
        ease: "Sine.easeInOut",
      });
      this.tweens.add({
        targets: [this.warningText, this.warningMessageText],
        scaleY: 1,
        scaleX: 1,
        duration: 200,
        ease: "Sine.easeInOut",
        onComplete: () => {
          this.tweens.add({
            targets: this.warningText,
            alpha: 0,
            repeat: 3,
            yoyo: true,
            duration: 200,
            ease: "Sine.easeInOut",
            onComplete: () => {
              this.sfxWarningAlarm.stop();
              this.tweens.add({
                targets: [this.warningText, this.warningMessageText],
                scaleY: 0,
                scaleX: 0,
                duration: 200,
                delay: 1000,
                ease: "Sine.easeInOut",
              });
              this.tweens.add({
                targets: [this.warningBackground],
                alpha: 0,
                duration: 200,
                delay: 1000,
                ease: "Sine.easeInOut",
                onComplete: () => {
                  this.tweens.add({
                    targets: this.boss,
                    y: 530,
                    scaleX: 1,
                    scaleY: 1,
                    duration: 2000,
                    ease: "Sine.easeInOut",
                    onStart: () => {
                      this.doorOpen();
                      this.cameras.main.shake(500, 0.01);
                      this.sfxBoss.setVolume(
                        store.state.settings.data.audio.sfx *
                          store.state.settings.data.audio.master
                      );
                      this.sfxBoss.play();
                      this.boss.play("animBossL");
                    },
                    onComplete: () => {
                      this.energyLineX.visible = true;
                      this.energyGraphicsX.visible = true;
                      this.energyLine.visible = true;
                      this.energyGraphics.visible = true;
                      this.tweens.add({
                        targets: this.avatarObject,
                        y: "+=20",
                        ease: "bounce.inout",
                        duration: 500,
                        yoyo: true,
                        onStart: () => {
                          if (this.avatarMask) {
                            this.avatarMask.destroy();
                          }
                        },
                        onComplete: () => {
                          this.sptMagic = this.add
                            .sprite(0, 550, "sptMagic")
                            .setOrigin(0)
                            .setDepth(2);
                          this.sptMagic.play("animMagic");
                          this.sfxMagic.setVolume(
                            store.state.settings.data.audio.sfx *
                              store.state.settings.data.audio.master
                          );
                          this.sfxMagic.play();
                          this.sptMagic.on(
                            "animationupdate",
                            function (animation, frame) {
                              this.sfxShine.stop();
                              this.avatarShine.forEach((element) => {
                                element.anims.stop("animCooked");
                                element.visible = false;
                              });
                              if (frame.index === 5) {
                                this.oldFoodGroup
                                  .getChildren()
                                  .forEach((element) => {
                                    this.tweens.add({
                                      targets: element.actions,
                                      x: "-=480",
                                      duration: 1000,
                                      ease: "Sine.easeInOut",
                                    });
                                  });
                                this.tweens.add({
                                  targets: this.oldFoodGroup.getChildren(),
                                  x: "-=480",
                                  duration: 1000,
                                  ease: "Sine.easeInOut",
                                });
                                this.coockedFoodGroup
                                  .getChildren()
                                  .forEach((element) => {
                                    this.tweens.add({
                                      targets: element.actions,
                                      x: "-=480",
                                      duration: 1000,
                                      ease: "Sine.easeInOut",
                                    });
                                  });
                                this.tweens.add({
                                  targets: this.coockedFoodGroup.getChildren(),
                                  x: "-=480",
                                  duration: 1000,
                                  ease: "Sine.easeInOut",
                                  onComplete: () => {
                                    this.tweens.add({
                                      targets: this.boss.bubble,
                                      scaleX: 1.1,
                                      scaleY: 1.1,
                                      duration: 100,
                                      ease: "Sine.easeInOut",
                                      onStart: () => {
                                        this.sfxBubble.setVolume(
                                          store.state.settings.data.audio.sfx *
                                            store.state.settings.data.audio
                                              .master
                                        );
                                        this.sfxBubble.play();
                                      },
                                      onComplete: () => {
                                        for (
                                          let index = 0;
                                          index < 3;
                                          index++
                                        ) {
                                          this.boss.foods[
                                            index
                                          ].itemImg.setDepth(2);
                                          this.boss.foods[index].itemImg.x =
                                            110 + 90 * index;
                                          this.boss.foods[
                                            index
                                          ].itemImg.y = 210;
                                          this.tweens.add({
                                            targets:
                                              this.boss.foods[index].itemImg,
                                            scaleX: 0.9,
                                            scaleY: 0.9,
                                            duration: 200,
                                            ease: "Sine.easeInOut",
                                          });
                                        }
                                        this.time.delayedCall(200, () => {
                                          this.setTimer();
                                        });
                                      },
                                    });
                                  },
                                });
                              }
                            },
                            this
                          );
                        },
                      });
                    },
                  });
                  if (this.avatarMask) {
                    this.avatarMask.destroy();
                  }
                  this.avatarMask = this.add
                    .image(
                      this.avatarObject[0].x,
                      this.avatarObject[0].y,
                      "maskFear"
                    )
                    .setScale(0.25)
                    .setDepth(6);
                  this.customerGroup.getChildren().forEach(function (customer) {
                    if (customer.faceMask) {
                      customer.faceMask.destroy();
                    }
                    customer.faceMask = this.add
                      .image(customer.x, customer.y - 15, "maskFear")
                      .setScale(0.25)
                      .setDepth(4);

                    this.tweens.add({
                      targets: [
                        customer.order.feedback,
                        customer.order.itemBbl,
                      ],
                      alpha: 0,
                      duration: 200,
                      ease: "Sine.easeInOut",
                    });

                    this.time.delayedCall(1000, () => {
                      this.tweens.add({
                        targets: [customer],
                        y: "-=200",
                        duration: 1500,
                        ease: "bounce.in",
                        yoyo: true,
                      });
                      this.tweens.add({
                        targets: [customer],
                        x: customer.x == 100 ? "-=200" : "+=300",
                        duration: 1500,
                        ease: "sine.inout",
                      });
                    });
                  }, this);
                },
              });
            },
          });
        },
      });
    });
  }

  gameFail() {
    for (let index = 0; index < 3; index++) {
      this.boss.foods[index].itemImg.destroy();
      this.boss.foods[index].sptCW.destroy();
    }
    this.energyLineX.visible = false;
    this.energyGraphicsX.visible = false;
    this.energyLine.visible = false;
    this.energyGraphics.visible = false;
    this.sfxBossFail.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxBossFail.play();
    if (this.avatarMask) {
      this.avatarMask.destroy();
    }
    this.avatarMask = this.add
      .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskFear")
      .setScale(0.25)
      .setDepth(6);
    this.boss.bubble.visible = false;
    this.boss.setDepth(5);
    this.boss.anims.stop("animBossL");
    this.boss.setFrame(4);
    this.tweens.add({
      targets: this.boss,
      // scaleX: 4,
      // scaleY: 4,
      // y: "+=400",
      alpha: 0,
      delay: 1000,
      duration: 1000,
      ease: "Sine.easeInOut",
      onStart: () => {
        this.sfxBoss.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxBoss.play();
      },
      onComplete: () => {
        this.finishGame();
      },
    });
  }

  gameSuccess() {
    this.energyLineX.visible = false;
    this.energyGraphicsX.visible = false;
    this.energyLine.visible = false;
    this.energyGraphics.visible = false;
    this.boss.bubble.visible = false;
    this.boss.setDepth(4);
    this.tweens.add({
      targets: this.boss,
      scaleX: 0.4,
      scaleY: 0.4,
      duration: 1000,
      ease: "Sine.easeInOut",
      onStart: () => {
        this.sfxMagic.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxMagic.play();
      },
      onComplete: () => {
        this.sfxBossX.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxBossX.play();
        this.boss.setScale(0.5);
        this.boss.setFrame(2);
        this.boss.play("animBossS");
        this.shine = this.add
          .sprite(this.boss.x, this.boss.y - 300, "sptShine")
          .setAlpha(0)
          .setDepth(5)
          .setScale(2);
        this.collection = this.add
          .image(this.boss.x, this.boss.y, "imgCollection")
          .setAlpha(0)
          .setDepth(5)
          .setScale(0);
        this.tweens.add({
          targets: this.collection,
          alpha: 1,
          scaleX: 0.5,
          scaleY: 0.5,
          y: "-=300",
          duration: 500,
          ease: "Sine.easeInOut",
          onComplete: () => {
            this.time.delayedCall(1000, () => {
              this.sfxBossSuccess.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.sfxBossSuccess.play();
              let bgSh = this.add
                .rectangle(240, 427, 480, 854, 0x000000)
                .setAlpha(0.7)
                .setDepth(4);
              this.shine.setAlpha(1);
              this.shine.play("animShine");
              this.tweens.add({
                targets: this.shine,
                angle: "+=360",
                duration: 5000,
                ease: "Linear",
                repeat: -1,
              });
              this.shine.setInteractive({ cursor: "pointer" }).on(
                "pointerdown",
                () => {
                  this.math_data = _.cloneDeep(store.state.math.data);
                  this.math_data.boss.isCollected = true;
                  store.commit("updateMath", this.math_data);
                  this.sfxCollect.setVolume(
                    store.state.settings.data.audio.sfx *
                      store.state.settings.data.audio.master
                  );
                  this.sfxCollect.play();
                  bgSh.destroy();
                  this.shine.destroy();
                  this.tweens.add({
                    targets: this.collection,
                    y: 800,
                    scaleX: 0.1,
                    scaleY: 0.1,
                    duration: 300,
                    ease: "Linear",
                    onComplete: () => {
                      this.tweens.add({
                        targets: this.boss,
                        alpha: 0,
                        duration: 500,
                        ease: "Linear",
                        onComplete: () => {
                          if (this.avatarMask) {
                            this.avatarMask.destroy();
                          }
                          this.avatarMask = this.add
                            .image(
                              this.avatarObject[0].x,
                              this.avatarObject[0].y,
                              "maskHappyx"
                            )
                            .setScale(0.25)
                            .setDepth(6);
                          this.boss.destroy();
                        },
                      });
                      this.finishGame();
                    },
                  });
                },
                this
              );
            });
          },
        });
      },
    });
  }

  finishGame() {
    this.time.removeAllEvents();
    this.time.delayedCall(1000, () => {
      this.gameOngoing = false;
      this.tweens.add({
        targets: [
          this.imgWoodPlane1,
          this.imgWoodPlane2,
          this.imgWoodPlane3,
          this.imgRawCrab,
          this.imgRawSteak,
          this.imgRawWing,
        ],
        x: "+=480",
        duration: 1000,
        ease: "Sine.easeInOut",
      });
      this.tweens.add({
        targets: this.stoveGroup.getChildren(),
        x: "+=480",
        duration: 1000,
        ease: "Sine.easeInOut",
        onStart: () => {
          this.sfxSlide.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxSlide.play();
        },
        onComplete: () => {
          this.time.delayedCall(1000, () => {
            this.goPlay();
          });
        },
      });

      this.coockedFoodGroup.getChildren().forEach((element) => {
        this.tweens.add({
          targets: element.actions,
          x: "+=480",
          duration: 1000,
          ease: "Sine.easeInOut",
        });
      });

      this.tweens.add({
        targets: this.coockedFoodGroup.getChildren(),
        x: "+=480",
        duration: 1000,
        ease: "Sine.easeInOut",
      });
    });
  }

  customerEnter(token) {
    let customer = this.add
      .image(240, -100, "imgNpc")
      .setScale(0.45)
      .setDepth(1);

    customer.tokenNumber = token;
    customer.notServed = true;

    let waitingTime;

    switch (this.difficulty) {
      case 3:
        waitingTime = 6;
        break;
      case 2:
        waitingTime = 9;
        break;
      default:
        waitingTime = 12;
        break;
    }

    customer.order = {
      item: this.customerList[this.customerCount] == 1 ? "JELLY" : "CAKE",
      itemBbl: this.add
        .image(240, -100, "imgOrderBubble")
        .setScale(0)
        .setOrigin(0, 0.5),
      itemSpt:
        this.customerList[this.customerCount] == 1
          ? this.add.image(240, -100, "imgJelly").setScale(0).setOrigin(0, 0.5)
          : this.add.image(240, -100, "imgCake").setScale(0).setOrigin(0, 0.5),
      waitingTime: waitingTime,
      waitingTimeText: this.add
        .text(240, -100, waitingTime + " s", {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 18,
          fill: "#000",
        })
        .setScale(0),
    };
    this.customerGroup.add(customer);
    this.tweens.add({
      targets: customer,
      scaleY: 0.46,
      scaleX: 0.44,
      duration: 500,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    this.tweens.add({
      targets: customer,
      y: 150,
      duration: 800,
      ease: "Sine.easeInOut",
      onStart: () => {
        this.doorOpen();
      },
      onComplete: () => {
        if (!customer.moving) this.moveCustomer(customer);
      },
    });

    this.customerCount++;
  }

  moveCustomer(customer) {
    let x = 0;
    let y = 0;
    switch (customer.tokenNumber) {
      case 1:
        x = 100;
        y = 480;
        break;
      case 2:
        x = 320;
        y = 480;
        break;
      case 3:
        x = 100;
        y = 350;
        break;
      case 4:
        x = 320;
        y = 350;
        break;
      case 5:
        x = 100;
        y = 220;
        break;
      case 6:
        x = 320;
        y = 220;
        break;
      default:
        break;
    }

    if (customer.x != x || customer.y != y) {
      customer.moving = true;
      this.tweens.add({
        targets: customer,
        y: y,
        x: x,
        duration: 1000,
        delay: 1000,
        ease: "Sine.easeInOut",
        onComplete: () => {
          customer.moving = false;
        },
      });
    }
  }

  refreshToken(removedToken) {
    if (removedToken == 1) {
      let customer = this.customerGroup
        .getChildren()
        .find((customer) => customer.tokenNumber == 3);
      if (customer) {
        customer.tokenNumber = 1;
        this.moveCustomer(customer);
        this.refreshToken(3);
      }
    }

    if (removedToken == 2) {
      let customer = this.customerGroup
        .getChildren()
        .find((customer) => customer.tokenNumber == 4);
      if (customer) {
        customer.tokenNumber = 2;
        this.moveCustomer(customer);
        this.refreshToken(4);
      }
    }

    if (removedToken == 3) {
      let customer = this.customerGroup
        .getChildren()
        .find((customer) => customer.tokenNumber == 5);
      if (customer) {
        customer.tokenNumber = 3;
        this.moveCustomer(customer);
      }
    }

    if (removedToken == 4) {
      let customer = this.customerGroup
        .getChildren()
        .find((customer) => customer.tokenNumber == 6);
      if (customer) {
        customer.tokenNumber = 4;
        this.moveCustomer(customer);
      }
    }
  }

  customerExit(customer) {
    this.exitCustomerCount++;
    const token = customer.tokenNumber;

    this.tweens.add({
      targets: [customer, customer.order.feedback, customer.order.itemBbl],
      alpha: 0,
      duration: 100,
      ease: "Sine.easeInOut",
      onComplete: () => {
        customer.order.waitingTimeText.destroy();
        customer.order.feedback.destroy();
        customer.order.itemBbl.destroy();
        customer.destroy();
        this.refreshToken(token);
        if (this.exitCustomerCount >= 12 && this.gameOngoing) {
          this.time.removeAllEvents();
          this.time.delayedCall(200, () => {
            this.gameOngoing = false;
            this.finishGame();
          });
        }
      },
    });
  }

  doorOpen() {
    this.sptDoor.play("animDoorOpen");
    this.sfxDoor.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxDoor.play();
  }

  doorColose() {
    //
  }

  cooking() {
    //
  }

  finishCooking() {
    //
  }

  clickSetting() {
    if (this.gameOngoing) {
      this.sfxSimpleClick.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxSimpleClick.play();
      this.scene.pause();
      this.scene.launch("Settings", {
        sceneName: "BossScene",
      });
    }
  }

  goPlay() {
    if (this.round != this.noOfRounds) {
      let avatarX = this.avatarObject[0] ? this.avatarObject[0].x : 220;
      this.avatarObject = [];
      this.customerCount = 0;
      this.servedFoodCount = 0;
      this.customerList = [];
      this.gameOngoing = true;
      this.exitCustomerCount = 0;
      this.indexToBeServe = 0;
      this.registry.destroy();
      this.events.off();
      this.scene.stop("BossScene");

      this.scene.start("PlayScene", {
        avatar: this.avatar,
        round: this.round + 1,
        score: this.score,
        questions: this.questions,
        friend: this.friend,
        bossRound: this.bossRound,
        noOfRounds: this.noOfRounds,
        difficulty: this.difficulty,
        countCorrect: this.countCorrect,
        avatarX: avatarX,
      });
    } else {
      let star;
      this.math_data = _.cloneDeep(store.state.math.data);
      this.score += (this.difficulty - 1) * 50;
      if (this.countCorrect == 3) {
        star = 1;
      } else if (this.countCorrect == 4) {
        star = 2;
      } else if (this.countCorrect == 5) {
        star = 3;
      } else {
        star = 0;
      }
      this.math_data.star = star;

      this.math_data.countCorrect = this.countCorrect;
      this.math_data.score = this.score;
      this.math_data.difficulty = this.difficulty;
      this.math_data.noOfRounds = this.noOfRounds;
      store.commit("updateMath", this.math_data);

      this.time.delayedCall(1000, () => {
        router.push({
          name: "PageMathGameScore",
          params: {
            topic: store.state.math.data.topic,
            game: store.state.math.data.game,
          },
        });
      });
    }
  }
}
