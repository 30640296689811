<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <ErrorDialog
            v-if="error"
            :error="error"
            :message="errorMessage"
            @exitError="() => (error = false)"
          />
          <!-- EOC -->
          <RewardDialog
            v-if="energyNew && energyData"
            :callbackClose="() => (energyNew = false)"
            :showDialog="true"
            :rewards="[
              {
                Item: {
                  images: energyData.Energy.images,
                },
                quantity: energyData.Energy.quantity,
              },
            ]"
          />

          <SubscriptionDialog
            v-if="subscriptionDialog"
            :callbackClose="() => (subscriptionDialog = false)"
            :showDialog="true"
          />

          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("string.mathMastery") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>

          <div class="px-5">
            <div class="d-flex mb-4">
              <div class="list-card pe-2 d-flex align-center me-2">
                <v-icon color="amber" size="30">mdi-star</v-icon>
                {{
                  playerData && playerData.totalStar ? playerData.totalStar : 0
                }}
              </div>
              <div
                class="list-card d-flex align-center"
                v-if="
                  playerData &&
                  (playerData.totalGradeA ||
                    playerData.totalGradeB ||
                    playerData.totalGradeC ||
                    playerData.totalGradeF)
                "
              >
                <div
                  class="d-flex pe-2 align-center"
                  v-if="playerData && playerData.totalGradeA"
                >
                  <v-img
                    contain
                    max-width="30"
                    class="mx-auto"
                    :src="require('@/assets/math/shields/gradeA.png')"
                  ></v-img>
                  {{ playerData.totalGradeA }}
                </div>
                <div
                  class="d-flex pe-2 align-center"
                  v-if="playerData && playerData.totalGradeB"
                >
                  <v-img
                    contain
                    max-width="30"
                    class="mx-auto"
                    :src="require('@/assets/math/shields/gradeB.png')"
                  ></v-img>
                  {{ playerData.totalGradeB }}
                </div>
                <div
                  class="d-flex pe-2 align-center"
                  v-if="playerData && playerData.totalGradeC"
                >
                  <v-img
                    contain
                    max-width="30"
                    class="mx-auto"
                    :src="require('@/assets/math/shields/gradeC.png')"
                  ></v-img>
                  {{ playerData.totalGradeC }}
                </div>
                <div
                  class="d-flex pe-2 align-center"
                  v-if="playerData && playerData.totalGradeF"
                >
                  <v-img
                    contain
                    max-width="30"
                    class="mx-auto"
                    :src="require('@/assets/math/shields/gradeF.png')"
                  ></v-img>
                  {{ playerData.totalGradeF }}
                </div>
              </div>
              <v-spacer></v-spacer>
              <div class="list-card pe-2 d-flex align-center" v-if="energyData">
                <v-img
                  contain
                  width="30"
                  height="30"
                  class="me-1"
                  :src="energyData.Energy.images.icon.url"
                ></v-img>
                {{ energyData.Energy.quantity }}
              </div>
            </div>
            <div class="d-flex justify-end">
              <CountDown :endTime="endTime" :message="$t('string.resetIn')" />
            </div>

            <div class="d-flex justify-center">
              <v-select
                color="brown"
                v-model="selectedYear"
                :items="years"
                item-text="text"
                item-value="key"
                dense
                rounded
                flat
                hide-details
                class="mb-2 list-card-x"
                @change="fetchChapters()"
              ></v-select>
            </div>

            <div
              class="d-flex mb-4 pa-2 align-center justify-space-between cvcx"
            >
              <div>
                <v-btn
                  icon
                  @click="prevChapter"
                  :loading="api.isLoading"
                  :disabled="isFirstChap"
                >
                  <v-img
                    contain
                    max-width="40"
                    class="mx-auto"
                    :src="require('@/assets/island/ArrowButtonPre.png')"
                    :class="isFirstChap ? 'disabled-navx' : ''"
                  ></v-img>
                </v-btn>
              </div>
              <div class="mx-2" v-if="selectedChapter">
                {{ selectedChapter.text }}
              </div>
              <div>
                <v-btn
                  icon
                  @click="nextChapter"
                  :loading="api.isLoading"
                  :disabled="isLastChap"
                >
                  <v-img
                    contain
                    max-width="40"
                    class="mx-auto"
                    :src="require('@/assets/island/ArrowButtonNex.png')"
                    :class="isLastChap ? 'disabled-navx' : ''"
                  ></v-img>
                </v-btn>
              </div>
            </div>

            <div class="text-center text-h6 my-4" v-if="selectedChapter">
              {{ selectedChapter.name }}
            </div>

            <v-row v-if="topics.length > 0">
              <v-col
                v-for="(item, i) in topics"
                :key="i + 'all'"
                class="pa-2 pa-sm-3"
                cols="4"
              >
                <v-card class="list-card pa-2" flat @click="goGame(item, i)">
                  <div class="d-flex flex-column align-center">
                    <div class="" v-if="selectedYear && selectedChapter">
                      {{ selectedChapter.sort }}
                      - {{ i + 1 }}
                    </div>
                    <v-img
                      contain
                      max-width="50"
                      class="mx-auto"
                      :src="
                        require(item.playerData
                          ? item.playerData.grade == 'A'
                            ? '@/assets/math/shields/gradeA.png'
                            : item.playerData.grade == 'B'
                            ? '@/assets/math/shields/gradeB.png'
                            : item.playerData.grade == 'C'
                            ? '@/assets/math/shields/gradeC.png'
                            : '@/assets/math/shields/gradeF.png'
                          : '@/assets/math/shields/gradeX.png')
                      "
                    ></v-img>

                    <v-spacer></v-spacer>
                    <div>
                      <v-rating
                        :value="item.playerData ? item.playerData.topStar : 0"
                        background-color="amber"
                        color="amber"
                        length="3"
                        readonly
                        dense
                      ></v-rating>
                    </div>
                    <v-btn
                      block
                      color="blue"
                      dark
                      class="mt-3 px-0"
                      v-if="
                        (selectedChapter.sort == 1 && i < 9) || isSubscribed
                      "
                    >
                      <span class="text-h6">
                        {{ $t("string.go") }}
                      </span>

                      <v-icon class="ms-1" small> mdi-lightning-bolt </v-icon>
                      1
                    </v-btn>
                    <v-btn
                      block
                      color="yellow"
                      light
                      depressed
                      class="mt-3 px-0"
                      v-else
                    >
                      <v-icon class="ms-1"> mdi-crown </v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <div
              class="d-flex my-6 pa-2 align-center justify-space-between cvcx"
            >
              <div>
                <v-btn
                  icon
                  @click="prevChapter"
                  :loading="api.isLoading"
                  :disabled="isFirstChap"
                >
                  <v-img
                    contain
                    max-width="40"
                    class="mx-auto"
                    :src="require('@/assets/island/ArrowButtonPre.png')"
                    :class="isFirstChap ? 'disabled-navx' : ''"
                  ></v-img>
                </v-btn>
              </div>
              <div class="mx-2" v-if="selectedChapter">
                {{ selectedChapter.text }}
              </div>
              <div>
                <v-btn
                  icon
                  @click="nextChapter"
                  :loading="api.isLoading"
                  :disabled="isLastChap"
                >
                  <v-img
                    contain
                    max-width="40"
                    class="mx-auto"
                    :src="require('@/assets/island/ArrowButtonNex.png')"
                    :class="isLastChap ? 'disabled-navx' : ''"
                  ></v-img>
                </v-btn>
              </div>
            </div>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    CountDown: () =>
      import(
        /* webpackChunkName: "component-countdown" */ "@/components/CountDown.vue"
      ),
    ErrorDialog: () =>
      import(
        /* webpackChunkName: "component-error-dialog" */ "@/components/ErrorDialog.vue"
      ),

    RewardDialog: () =>
      import(
        /* webpackChunkName: "component-reward" */ "@/components/games/RewardDialog"
      ),
    SubscriptionDialog: () =>
      import(
        /* webpackChunkName: "component-subscription-dialog" */ "@/components/SubscriptionDialog"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
    math: (state) => state.math.data,
  }),
  props: [
    //
  ],
  data: () => ({
    subscriptionDialog: false,
    isSubscribed: false,
    energyNew: false,
    error: false,
    errorMessage: null,
    endTime: "",
    isFirstChap: true,
    isLastChap: false,
    navs: [
      {
        id: 1,
        text: "General",
        name: "Daily",
      },
      {
        id: 2,
        text: "Topics",
        name: "Learn",
      },
    ],

    selectedNav: {},
    selectedYear: "",
    selectedChapter: null,
    chapters: [],
    years: [],
    topics: [],
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    yearRecord: [],
    playerData: {},
    energyData: null,
  }),
  async created() {
    await this.myPlan();
    await this.loadEnergy();
    if (
      this.endTime == null ||
      this.$moment(this.endTime).diff(this.$moment()) <= 0
    ) {
      await this.resetEnergy();
      await this.loadEnergy();
      this.energyNew = true;
    }
    await this.fetchYears();
    await this.fetchChapters(false, true);
  },
  mounted() {
    this.selectedNav = this.navs[1];
  },
  methods: {
    async myPlan() {
      this.api.method = "get";
      this.api.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/player/myPlan";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
        console.error(e);
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        if (resp.Plan && resp.Plan.planKey == "PLAN_PRIMARY_010") {
          this.isSubscribed = true;
        }
      };
      await this.$api.fetch(this.api);
    },
    async loadEnergy() {
      this.api.method = "post";
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/energy/check";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.energyData = resp;
        this.endTime = resp.TimestampReset;
      };
      await this.$api.fetch(this.api);
    },
    async resetEnergy() {
      this.api.method = "post";
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/energy/reset";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
      };
      await this.$api.fetch(this.api);
    },
    async prevChapter() {
      let currentChapterIndex = this.chapters.findIndex(
        (element) => element.key == this.selectedChapter.key
      );
      let currentYearIndex = this.years.findIndex(
        (element) => element.key == this.selectedYear
      );

      if (currentChapterIndex <= 1 && currentYearIndex == 0) {
        this.isFirstChap = true;
      } else {
        this.isFirstChap = false;
      }
      this.isLastChap = false;

      if (currentChapterIndex > 0) {
        this.selectedChapter = this.chapters[currentChapterIndex - 1];
        let tempStore = this.$_.cloneDeep(this.math) || {};
        tempStore["currentChapterMath"] = this.selectedChapter;
        this.$store.commit("updateMath", tempStore);
        await this.fetchTopics();
      } else {
        if (currentYearIndex > 0) {
          this.selectedYear = this.years[currentYearIndex - 1].key;
          await this.fetchChapters(true);
        }
      }
    },
    async nextChapter() {
      let currentChapterIndex = this.chapters.findIndex(
        (element) => element.key == this.selectedChapter.key
      );
      let currentYearIndex = this.years.findIndex(
        (element) => element.key == this.selectedYear
      );
      if (
        currentChapterIndex >= this.chapters.length - 2 &&
        currentYearIndex == this.years.length - 1
      ) {
        this.isLastChap = true;
      } else {
        this.isLastChap = false;
      }
      this.isFirstChap = false;

      if (currentChapterIndex < this.chapters.length - 1) {
        this.selectedChapter = this.chapters[currentChapterIndex + 1];
        let tempStore = this.$_.cloneDeep(this.math) || {};
        tempStore["currentChapterMath"] = this.selectedChapter;
        this.$store.commit("updateMath", tempStore);
        await this.fetchTopics();
      } else {
        if (currentYearIndex < this.years.length - 1) {
          this.selectedYear = this.years[currentYearIndex + 1].key;
          await this.fetchChapters();
        }
      }
    },
    async loadPlayerData() {
      let currentYearIndex = this.years.find(
        (element) => element.key == this.selectedYear
      );
      this.api.method = "post";
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/math/visit";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.playerData = resp.Player;
        this.yearRecord = resp.Record;
      };
      this.api.params = {
        schoolYear: currentYearIndex.value,
      };
      await this.$api.fetch(this.api);
    },
    async fetchYears() {
      this.api.method = "get";
      this.api.url = this.$api.servers.question + "/v2/syllabi" + "?lang=" + this.$_getLocale();

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        resp.forEach((element, i) => {
          element.text = this.$t("string.year", { year: i + 1 });
          element.value = i + 1;
        });
        this.years = resp;
        if (!this.math.currentYearMath) {
          let schoolYear;
          if (this.auth.Classroom) {
            schoolYear = this.auth.Classroom.schoolYear;
            this.classroomMonth = this.auth.Classroom.month;
          } else if (this.auth.Player.userType == "adult") {
            schoolYear = 1;
          } else {
            schoolYear = this.auth.User.schoolYear;
            this.classroomMonth = this.auth.User.month;
          }
          this.selectedYear = this.years[schoolYear - 1].key;
          let tempStore = this.$_.cloneDeep(this.math) || {};
          tempStore["currentYearMath"] = this.selectedYear;
          this.$store.commit("updateMath", tempStore);
        } else {
          this.selectedYear = this.math.currentYearMath;
        }
      };
      this.api.params = {
        lang: this.$_getLocale(),
      };
      await this.$api.fetch(this.api);
    },
    async fetchChapters(isBack = false, isFirst = false) {
      let tempStore = this.$_.cloneDeep(this.math) || {};
      tempStore["currentYearMath"] = this.selectedYear;
      this.$store.commit("updateMath", tempStore);
      this.api.method = "get";
      this.api.url =
        this.$api.servers.question +
        "/v2/syllabi/" +
        this.selectedYear +
        "/chapters" + "?lang=" + this.$_getLocale();

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = async (resp) => {
        this.api.isLoading = false;
        resp.forEach((element) => {
          element.text = this.$t("string.chapter") + " " + element.sort;
        });
        this.chapters = resp;

        if (isFirst) {
          if (!this.math.currentChapterMath) {
            this.selectedChapter = this.chapters[0];
            let tempStore = this.$_.cloneDeep(this.math) || {};
            tempStore["currentChapterMath"] = this.selectedChapter;
            this.$store.commit("updateMath", tempStore);
          } else {
            this.selectedChapter = this.math.currentChapterMath;
          }
        } else {
          this.selectedChapter =
            this.chapters[isBack ? this.chapters.length - 1 : 0];
          let tempStore = this.$_.cloneDeep(this.math) || {};
          tempStore["currentChapterMath"] = this.selectedChapter;
          this.$store.commit("updateMath", tempStore);
        }

        let currentYearIndex = this.years.findIndex(
          (element) => element.key == this.selectedYear
        );
        if (!isBack && currentYearIndex == 0) {
          this.isFirstChap = true;
        } else {
          this.isFirstChap = false;
        }
        this.yearRecord = [];
        await this.loadPlayerData();
        await this.fetchTopics();
      };
      this.api.params = {
        lang: this.$_getLocale(),
      };
      await this.$api.fetch(this.api);
    },
    async fetchTopics() {
      this.api.method = "get";
      this.api.url =
        this.$api.servers.question +
        "/v2/syllabi/" +
        this.selectedYear +
        "/questions" + "?lang=" + this.$_getLocale();

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.topics = resp.filter((element) =>
          element.key.includes(this.selectedChapter.key)
        );

        this.topics.forEach((element) => {
          let xtopic = this.yearRecord.find(
            (elementx) => elementx.questionKey == element.key
          );
          if (xtopic) {
            element.playerData = xtopic;
          }
        });
      };
      this.api.params = {
        lang: this.$_getLocale(),
      };
      await this.$api.fetch(this.api);
    },
    exit() {
      this.$router.push({
        name: "PageMath",
      });
    },
    goGame(item, i) {
      if ((this.selectedChapter.sort == 1 && i < 9) || this.isSubscribed) {
        if (this.energyData && this.energyData.Energy.quantity > 0) {
          let currentYear =
            this.years.findIndex(
              (element) => element.key == this.selectedYear
            ) + 1;

          let tempStore = this.$_.cloneDeep(this.math) || {};
          tempStore["questionKey"] = item.key;
          tempStore["chapterKey"] = this.selectedChapter.key;
          tempStore["schoolYear"] = currentYear;
          tempStore["playerData"] = item.playerData ?? null;
          this.$store.commit("updateMath", tempStore);

          this.$router.push({
            name: "PageMathSelectGame",
            params: { topic: item.key },
            query: { topic: item.name },
          });
        } else {
          this.errorMessage = "Not enough energy.";
          this.error = true;
        }
      } else {
        this.subscriptionDialog = true;
      }
    },
  },
};
</script>

<style scoped>
.tab-bar-card {
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #e1c8aa;
  text-align: center;
  display: flex;
}

.cvcx {
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #f7efe3;
}

.disabled-navx {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
}

.tab-card {
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  width: calc(100% / 2);
  background-color: #cbae8c;
  text-align: center;
  color: #fff;
  text-shadow: #876a47 3px 0px 0px, #876a47 2.83487px 0.981584px 0px,
    #876a47 2.35766px 1.85511px 0px, #876a47 1.62091px 2.52441px 0px,
    #876a47 0.705713px 2.91581px 0px, #876a47 -0.287171px 2.98622px 0px,
    #876a47 -1.24844px 2.72789px 0px, #876a47 -2.07227px 2.16926px 0px,
    #876a47 -2.66798px 1.37182px 0px, #876a47 -2.96998px 0.42336px 0px,
    #876a47 -2.94502px -0.571704px 0px, #876a47 -2.59586px -1.50383px 0px,
    #876a47 -1.96093px -2.27041px 0px, #876a47 -1.11013px -2.78704px 0px,
    #876a47 -0.137119px -2.99686px 0px, #876a47 0.850987px -2.87677px 0px,
    #876a47 1.74541px -2.43999px 0px, #876a47 2.44769px -1.73459px 0px,
    #876a47 2.88051px -0.838247px 0px;
}

.selected-tab-card {
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  width: calc(100% / 2);
  border: #68fffc 4px solid;
  background-color: #d3fdfc;
  text-align: center;
  color: #fff;
  text-shadow: #29a399 3px 0px 0px, #29a399 2.83487px 0.981584px 0px,
    #29a399 2.35766px 1.85511px 0px, #29a399 1.62091px 2.52441px 0px,
    #29a399 0.705713px 2.91581px 0px, #29a399 -0.287171px 2.98622px 0px,
    #29a399 -1.24844px 2.72789px 0px, #29a399 -2.07227px 2.16926px 0px,
    #29a399 -2.66798px 1.37182px 0px, #29a399 -2.96998px 0.42336px 0px,
    #29a399 -2.94502px -0.571704px 0px, #29a399 -2.59586px -1.50383px 0px,
    #29a399 -1.96093px -2.27041px 0px, #29a399 -1.11013px -2.78704px 0px,
    #29a399 -0.137119px -2.99686px 0px, #29a399 0.850987px -2.87677px 0px,
    #29a399 1.74541px -2.43999px 0px, #29a399 2.44769px -1.73459px 0px,
    #29a399 2.88051px -0.838247px 0px;
}

.constant-tilt-shake {
  animation: tilt-shaking 2s infinite;
}

.constant-tilt-shake:hover {
  animation: none;
}

@keyframes tilt-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  3% {
    transform: translate(2px, -2px) rotate(5deg);
  }
  6% {
    transform: translate(0, 0) rotate(0eg);
  }
  9% {
    transform: translate(-2px, -2px) rotate(-5deg);
  }
  12% {
    transform: translate(0, 0) rotate(0deg);
  }
  15% {
    transform: translate(2px, -2px) rotate(5deg);
  }
  18% {
    transform: translate(0, 0) rotate(0eg);
  }
  21% {
    transform: translate(-2px, -2px) rotate(-5deg);
  }
  24% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.menu-button {
  padding: 0 5px;
  cursor: pointer;
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.list-card {
  padding: 5px;
  color: #785c3a;
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #fff9f1;
  overflow-x: hidden !important;
}

.list-card-x {
  padding: 5px;
  color: #785c3a;
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #f7efe3;
  overflow: hidden !important;
}

.level-cart {
  padding: 10px;
  margin-bottom: 12px;
  width: calc((100% / 3) - 8px);
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.main-body {
  /*background-color: #c7e5e9;*/
  background-color: rgba(214, 132, 49, 0.3);
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}
</style>