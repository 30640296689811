<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="290"
    content-class="gift-dialog"
    overlay-opacity="0.9"
  >
    <v-card
      color="transparent"
      class="d-flex flex-column justify-center align-center pa-3 white--text"
      v-if="beforeClicked"
      @click="claim"
    >
      <v-progress-circular
        v-if="api.isLoading"
        size="100"
        width="10"
        indeterminate
        color="white"
      ></v-progress-circular>
      <v-img
        v-else
        width="200"
        contain
        class="zoom-in-out-box"
        :src="require('@/assets/explore/CompletionGift1.png')"
      ></v-img>
      {{ $t("action.clickToClaim") }}
    </v-card>
    <RewardDialog
      v-else-if="afterClicked"
      :callbackClose="callbackClose"
      :showDialog="true"
      :rewards="rewards"
    />
  </v-dialog>
</template>
    
<script>
import { mapState } from "vuex";
export default {
  components: {
    RewardDialog: () =>
      import(
        /* webpackChunkName: "component-reward" */ "@/components/games/RewardDialog"
      ),
  },
  props: ["callback", "callbackClose"],
  data: () => ({
    dialog: true,
    beforeClicked: true,
    afterClicked: false,
    quantity: 0,
    extraQuantity: 0,
    rewards: [],
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  computed: mapState({
    coin: (state) => state.coin.data,
    event: (state) => state.event.data,
    series: (state) => state.series.data,
  }),
  mounted() {
    console.log(this.series);
    this.quantity = this.event.gameLoot;
    this.extraQuantity = this.event.gameLootExtra;
    if (this.extraQuantity > 0) {
      this.rewards = [
        {
          Item: {
            images: {
              icon: {
                url: require("@/assets/explore/coin.png"),
              },
            },
          },
          quantity: this.quantity,
        },
        {
          Item: {
            images: {
              icon: {
                url:
                  this.series.code == "EV2501"
                    ? require("@/assets/explore/CEV2501.png")
                    : require("@/assets/explore/blueCoin.png"),
              },
            },
          },
          quantity: this.extraQuantity,
        },
      ];
    } else {
      this.rewards = [
        {
          Item: {
            images: {
              icon: {
                url: require("@/assets/explore/coin.png"),
              },
            },
          },
          quantity: this.quantity,
        },
      ];
    }
  },
  methods: {
    async claim() {
      await this.addCoin(this.quantity, "goldcoin");
      if (this.extraQuantity > 0 && this.series.code == "EV2409") {
        await this.addCoin(this.quantity, "bluecoin");
      }
      if (this.extraQuantity > 0 && this.series.code == "EV2501") {
        await this.addCoin(this.quantity, "CEV2501");
      }
    },
    async addCoin(q, type) {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/item/updateMany";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
        console.error(e);
      };

      this.api.callbackSuccess = () => {
        this.callback();
        this.api.isLoading = false;
        this.beforeClicked = false;
        this.afterClicked = true;
      };
      this.api.params = {
        data: `[{"action":"add","itemKey":"${type}","quantity": ${q}}]`,
      };
      await this.$api.fetch(this.api);
    },
  },
};
</script>
    
<style>
.zoom-in-out-box {
  animation: zoom-in-zoom-out 2s ease infinite;
}

.gift-dialog {
  box-shadow: none;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>