<template>
  <router-view />
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  components: {
    //
  },
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>

<style>
</style>