<template>
  <v-container :style="intraction">
    <!-- BOC:[error] -->
    <ApiErrorDialog v-if="PlayerApi.isError" :api="PlayerApi" />
    <ApiErrorDialog v-if="EventApi.isError" :api="EventApi" />
    <ApiErrorDialog v-if="InventoryApi.isError" :api="InventoryApi" />
    <ErrorDialog
      v-if="error"
      :error="error"
      :message="errorMessage"
      @exitError="extiError"
    />
    <RecalculateConfirmationDialog
      :confirm= "confirm"
      type="event_math"
      @exitConfirm="exitConfirm"
    />
    <TutorialDialog
      v-if="tutorialDialog && event.translatableTutorialVideoUrl"
      :callbackClose="
        () => {
          tutorialDialog = false;
          $router.go();
        }
      "
      :showDialog="true"
      :link="JSON.parse(event.translatableTutorialVideoUrl)[$_getLocale()]"
    />
    <!-- EOC -->
    <div class="tab-bar-card">
      <div class="d-flex align-start">
        <div class="hamochi">
          <div class="image">
            <v-img :src="require('@/assets/explore/hamochi.png')" width="150">
            </v-img>
          </div>
          <div class="chat">
            <div class="speech-bubble">
              {{ $t("string.giftDescription") }}
            </div>
          </div>
        </div>
        <div class="gift pa-1">
          <v-img
            :src="require('@/assets/explore/gift_bg.png')"
            class="pb-3 px-2"
            width="150"
          >
            <v-img
              v-if="totalStars > 0 && maxStars == totalStars"
              :src="require('@/assets/explore/glow.png')"
              width="120"
              class="gift-shine"
            >
            </v-img>
            <v-img
              :src="require('@/assets/explore/gift.png')"
              width="150"
              :class="
                totalStars > 0 && maxStars == totalStars
                  ? 'gift-shake'
                  : 'gift-lock'
              "
              @click="gift()"
            >
            </v-img>
          </v-img>
        </div>
      </div>
      <div class="stars-panel">
        <div class="star-progress-back"></div>
        <div
          :class="progress == '84%' ? 'star-progress-full' : 'star-progress'"
          :style="{ width: progress }"
        ></div>
        <v-icon color="yellow" class="pe-3"> mdi-star</v-icon>
        {{ totalStars }}/{{ maxStars }}
      </div>
    </div>

    <div class="d-flex justify-space-between py-2 align-center">
      <div class="d-flex align-center" style="position: relative">
        <v-btn
          :disabled="!isOngoing"
          id="StartTutorial"
          small
          color="yellow"
          @click="
            () => {
              $intro().exit();
              // $router.push({
              //   name: 'PageEventGameTutorial',
              // });
              tutorialDialog = true;
              this.updateTutorial();
            }
          "
        >
          {{ $t("string.tutorial") }}
          <v-img
            v-if="isGameTutCompleted && isOngoing"
            :src="require('@/assets/explore/menu/tick.png')"
            class="ms-2"
            width="20"
          >
          </v-img>
          <v-icon v-if="!isOngoing"> mdi-lock </v-icon>
        </v-btn>
      </div>
      <div class="d-flex align-center">
        {{ $t("string.totalScore") }} : {{ totalScore }}
      </div>
      <div class="d-flex align-center ticket-card">
        <v-img
          contain
          width="30"
          :src="require('@/assets/explore/menu/ticket.png')"
        ></v-img>
        <div class="text-h5 ps-2 game-title" v-if="_event_data.Save">
          {{ JSON.parse(_event_data.Save.data).token }}
        </div>
      </div>
    </div>

    <div
      class="pa-1 d-flex flex-wrap justify-space-between"
      style="position: relative"
    >
      <div
        class="level-cart"
        v-for="(level, i) in levels"
        :key="i"
        :id="i == 0 ? 'StartLevel' : ''"
        @click="
          () => {
            $intro().exit();
            if (isOngoing) {
              $router.push({
                name: 'PageEventLevelDetail',
                params: { id: level.Level.id },
              });
            }
          }
        "
      >
        <div class="level-img">
          <div class="container-1x1">
            <div class="aspect-ratio-item">
              <v-img
                v-if="bossList[i]"
                :src="bossList[i].images.icon.url"
                width="150"
                :class="bossList[i].inventory > 0 ? '' : 'no-boss'"
              ></v-img>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-center text-h5 mt-2"
          style="background-color: #cbb18f"
        >
          {{ level.EventRecord ? level.EventRecord.topScore : "000" }}
        </div>
        <div class="d-flex justify-space-around my-2">
          <v-icon
            :color="
              level.EventRecord && level.EventRecord.topStar > 0
                ? 'yellow darken-2'
                : 'gray'
            "
          >
            mdi-star</v-icon
          >
          <v-icon
            :color="
              level.EventRecord && level.EventRecord.topStar > 1
                ? 'yellow darken-2'
                : 'gray'
            "
          >
            mdi-star</v-icon
          >
          <v-icon
            :color="
              level.EventRecord && level.EventRecord.topStar > 2
                ? 'yellow darken-2'
                : 'gray'
            "
          >
            mdi-star</v-icon
          >
        </div>
        <div class="level-go">
          <v-btn
            :disabled="!isOngoing"
            color="yellow"
            class="text-h6"
            width="100%"
            @click="
              () => {
                $intro().exit();
                $router.push({
                  name: 'PageEventLevelDetail',
                  params: { id: level.Level.id },
                });
              }
            "
          >
            <span v-if="isOngoing">
              {{ $t("action.go") }}
            </span>
            <v-icon v-else> mdi-lock </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="text-center py-2">
        <div class="text-caption">
          {{ $t("string.recalculate") }}
        </div>
        <div class="py-2 ">
        <v-btn
          small
          dark
          color="orange"
          @click="openConfirm"
        >
          {{ $t("action.recalculate") }}
          <v-icon right> mdi-reload </v-icon>
        </v-btn>
      </div>
      </div>
     
    <v-dialog
      v-model="mail"
      width="460"
      content-class="mail-box"
      overlay-opacity="0.8"
      persistent
    >
      <div class="d-flex flex-column align-center" v-if="mailOpen">
        <div class="mt-3 mail-text text-h4">{{ $t("string.you_got") }}</div>
        <div class="mail-text text-h3 d-flex align-center">
          <v-img
            contain
            width="80"
            :src="require('@/assets/explore/menu/ticket.png')"
          ></v-img>
          X 20
        </div>
        <v-img
          :src="require('@/assets/explore/mail_open.png')"
          width="70%"
          contain
          class="mx-auto mail-img"
        ></v-img>
        <v-btn
          height="60"
          width="200"
          class="text-h5 mb-3"
          color="yellow"
          @click="
            () => {
              startTut();
              mail = false;
            }
          "
        >
          OK
        </v-btn>
      </div>
      <div class="d-flex flex-column align-center" v-else>
        <div class="mt-3 mail-text text-h3">Tata!</div>
        <div class="mail-text text-h4">{{ $t("string.you_have_mail") }}</div>
        <v-img
          :src="require('@/assets/explore/mail_close.png')"
          width="70%"
          contain
          class="mx-auto mail-img"
        ></v-img>
        <v-btn
          height="60"
          width="200"
          class="text-h5 mb-3"
          color="yellow"
          @click="
            () => {
              mailOpen = true;
            }
          "
        >
          {{ $t("string.open") }}
        </v-btn>
      </div>
    </v-dialog>

    <AbstractChat
      v-if="tutorialChat && chatStart"
      X1="Hamochie"
      :chats="tutorialChat"
      :callback="callback"
    />
    <SkipTut />
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    RecalculateConfirmationDialog: () =>
      import(
        /* webpackChunkName: "component-confirm-dialog" */ "@/components/RecalculateConfirmationDialog.vue"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    ErrorDialog: () =>
      import(
        /* webpackChunkName: "component-error-dialog" */ "@/components/ErrorDialog.vue"
      ),
    AbstractChat: () =>
      import(
        /* webpackChunkName: "component-abstract-chat" */ "@/components/tutorial/AbstractChat"
      ),
    SkipTut: () =>
      import(
        /* webpackChunkName: "component-skip-tut" */ "@/components/tutorial/SkipTut"
      ),
    TutorialDialog: () =>
      import(
        /* webpackChunkName: "component-tutorial-dialog" */ "@/components/TutorialDialog.vue"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    _event_data: (state) => state.event.data,
    tutorial: (state) => state.tutorial.data,
    series: (state) => state.series.data,
  }),
  props: [
    //
  ],
  data: () => ({
    tutorialDialog: false,
    intraction: null,
    error: false,
    errorMessage: null,
    mail: false,
    mailOpen: false,
    progress: 0,
    maxStars: 0,
    totalStars: 0,
    totalScore: 0,
    schoolYear: null,
    isGameTutCompleted: false,
    bossList: [],
    levels: [],
    event: {},
    PlayerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    EventApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    InventoryApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    tutorialApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },

    chatStart: false,
    tutorialChat: null,
    tutExploreMiningChats: [],
    tutExploreLevelChats: [],
    tutExploreForagingChats: [],
    tutExploreCookingChats: [],
    tutExploreKombatChats: [],
    tutExploreFishingChats: [],
    tutExploreDinoChats: [],
    isOngoing: false,
    confirm: false
  }),
  created() {
    this.PlayerApi.url =
      this.$api.servers.event +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/event/player/load";

    this.PlayerApi.callbackReset = () => {
      this.PlayerApi.isLoading = true;
      this.PlayerApi.isError = false;
    };

    this.PlayerApi.callbackError = (e) => {
      this.PlayerApi.isLoading = false;
      this.PlayerApi.isError = true;
      this.PlayerApi.error = e;
    };
    this.PlayerApi.callbackSuccess = (resp) => {
      this.PlayerApi.isLoading = false;
      this.$store.commit("updateEvent", resp);
      if (this.isOngoing) {
        if (!JSON.parse(this._event_data.Save.data).isInitToken) {
          this.savePlayer();
        } else if (
          !this.$moment(
            JSON.parse(this._event_data.Save.data).isInitToken
          ).isSame(this.$moment(), "day")
        ) {
          this.savePlayer();
        } else {
          this.startTut();
        }
      }
    };
    this.PlayerApi.params = {
      eventCode: this.$route.params.code,
    };
    this.InventoryApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/inventory/listByTypeAndCategory";

    this.InventoryApi.callbackReset = () => {
      this.InventoryApi.isLoading = true;
      this.InventoryApi.isError = false;
    };

    this.InventoryApi.callbackError = (e) => {
      this.InventoryApi.isLoading = false;
      this.InventoryApi.isError = true;
      this.InventoryApi.error = e;
    };
    this.InventoryApi.callbackSuccess = (resp) => {
      this.bossList = resp.Item;
      this.InventoryApi.isLoading = false;
    };
    this.InventoryApi.params = {
      type:
        this.series.gameCode == "BATTLE"
          ? "doll"
          : this.series.gameCode == "COOK"
          ? "food"
          : this.series.gameCode == "GEMS"
          ? "gem"
          : this.series.gameCode == "FISH"
          ? "fish2"
          : this.series.gameCode == "DINO"
          ? "dino"
          : "fruit",
      category: "large",
    };

    this.EventApi.url =
      this.$api.servers.event +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/event/level";

    this.EventApi.callbackReset = () => {
      this.EventApi.isLoading = true;
      this.EventApi.isError = false;
    };

    this.EventApi.callbackError = (e) => {
      this.EventApi.isLoading = false;
      this.EventApi.isError = true;
      this.EventApi.error = e;
    };
    this.EventApi.callbackSuccess = (resp) => {
      this.isOngoing =
        this.$moment().isAfter(resp.Event.timestampStart) &&
        !this.$moment().isAfter(resp.Event.timestampEnd);
      this.event = resp.Event;
      this.levels = resp.Levels;
      this.EventApi.isLoading = false;
      this.$api.fetch(this.PlayerApi);

      this.levels.forEach((level) => {
        if (level.EventRecord) {
          this.totalStars += level.EventRecord.topStar;
          this.totalScore += level.EventRecord.topScore;
        }
        this.maxStars += 3;
      });

      let prog =
        (this.totalStars / this.maxStars) * 100 -
        (this.totalStars / this.maxStars) * 16;

      if (prog > 0 && prog < 5) {
        this.progress = `5%`;
      } else {
        this.progress = `${prog}%`;
      }

      let tempSeries = this.$_.cloneDeep(this.series);
      if (this.totalStars > 0 && this.totalStars == this.maxStars) {
        tempSeries.isCompleted = true;
      } else {
        tempSeries.isCompleted = false;
      }
      this.$store.commit("updateSeries", tempSeries);
    };
  },
  mounted() {
    this.tutExploreMiningChats = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreMining.dialog_1"),
        sound: "sfx_chat_2",
      },
    ];
    this.tutExploreForagingChats = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreForaging.dialog_1"),
        sound: "sfx_chat_2",
      },
    ];
    this.tutExploreCookingChats = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreCooking.dialog_1"),
        sound: "sfx_chat_2",
      },
    ];
    this.tutExploreKombatChats = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreKombat.dialog_1"),
        sound: "sfx_chat_2",
      },
    ];
    this.tutExploreFishingChats = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreFishing.dialog_1"),
        sound: "sfx_chat_2",
      },
    ];
    this.tutExploreDinoChats = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreDino.dialog_1"),
        sound: "sfx_chat_2",
      },
    ];
    this.tutExploreLevelChats = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreLevel.dialog_1"),
        sound: "sfx_chat_2",
      },
    ];
    var tutExploreMining = this.tutorial.Tutorial.filter(function (entry) {
      return entry.key == "tutExploreMining";
    });

    var tutExploreForaging = this.tutorial.Tutorial.filter(function (entry) {
      return entry.key == "tutExploreForaging";
    });

    var tutExploreCooking = this.tutorial.Tutorial.filter(function (entry) {
      return entry.key == "tutExploreCooking";
    });

    var tutExploreKombat = this.tutorial.Tutorial.filter(function (entry) {
      return entry.key == "tutExploreKombat";
    });

    var tutExploreFishing = this.tutorial.Tutorial.filter(function (entry) {
      return entry.key == "tutExploreFishing";
    });

    var tutExploreDino = this.tutorial.Tutorial.filter(function (entry) {
      return entry.key == "tutExploreDino";
    });

    if (this.series.gameCode == "GEMS") {
      this.isGameTutCompleted = tutExploreMining.length > 0;
    }
    if (this.series.gameCode == "FRUIT") {
      this.isGameTutCompleted = tutExploreForaging.length > 0;
    }
    if (this.series.gameCode == "COOK") {
      this.isGameTutCompleted = tutExploreCooking.length > 0;
    }
    if (this.series.gameCode == "BATTLE") {
      this.isGameTutCompleted = tutExploreKombat.length > 0;
    }
    if (this.series.gameCode == "FISH") {
      this.isGameTutCompleted = tutExploreFishing.length > 0;
    }
    if (this.series.gameCode == "DINO") {
      this.isGameTutCompleted = tutExploreDino.length > 0;
    }

    if (this.auth.Classroom) {
      this.schoolYear = this.auth.Classroom.schoolYear;
    } else {
      this.schoolYear = this.auth.User.schoolYear;
    }
    this.EventApi.params = {
      eventCode: this.$route.params.code,
      schoolYear: this.schoolYear,
    };
    this.$api.fetch(this.InventoryApi);
    this.$api.fetch(this.EventApi);
  },
  updated() {
    let level_boss_list = [];
    if (this.levels.length > 0 && this.bossList.length > 0) {
      for (let index = 0; index < this.levels.length; index++) {
        let level_boss = {};
        level_boss.event_id = this.event.id;
        level_boss.level_id = this.levels[index].Level.id;
        level_boss.boss_id = this.bossList[index].id;
        level_boss.boss_key = this.bossList[index].key;
        level_boss.boss_name = this.bossList[index].name;
        level_boss.boss_img = this.bossList[index].images.icon.url;
        level_boss.inventory = this.bossList[index].inventory;
        level_boss_list.push(level_boss);
      }
      this.$store.commit("updateBoss", level_boss_list);
    }
  },
  methods: {
    startTut() {
      var tutExploreMining = this.tutorial.Tutorial.filter(function (entry) {
        return entry.key == "tutExploreMining";
      });
      var tutExploreCooking = this.tutorial.Tutorial.filter(function (entry) {
        return entry.key == "tutExploreCooking";
      });
      var tutExploreKombat = this.tutorial.Tutorial.filter(function (entry) {
        return entry.key == "tutExploreKombat";
      });
      var tutExploreFishing = this.tutorial.Tutorial.filter(function (entry) {
        return entry.key == "tutExploreFishing";
      });
      var tutExploreDino = this.tutorial.Tutorial.filter(function (entry) {
        return entry.key == "tutExploreDino";
      });
      var tutExploreForaging = this.tutorial.Tutorial.filter(function (entry) {
        return entry.key == "tutExploreForaging";
      });
      var tutExploreLevel = this.tutorial.Tutorial.filter(function (entry) {
        return entry.key == "tutExploreLevel";
      });

      if (this.isOngoing) {
        if (this.series.gameCode == "GEMS" && tutExploreMining.length == 0) {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          tempTut.Ongoing = "tutExploreMining";
          tempTut.Step = 1;
          this.$store.commit("updateTutorial", tempTut);

          this.intraction = "pointer-events: none;";
          setTimeout(
            () => (
              (this.tutorialChat = this.tutExploreMiningChats),
              (this.chatStart = true),
              (this.intraction = "pointer-events:all")
            ),
            800
          );
        } else if (
          this.series.gameCode == "FRUIT" &&
          tutExploreForaging.length == 0
        ) {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          tempTut.Ongoing = "tutExploreForaging";
          tempTut.Step = 1;
          this.$store.commit("updateTutorial", tempTut);

          this.intraction = "pointer-events: none;";
          setTimeout(
            () => (
              (this.tutorialChat = this.tutExploreForagingChats),
              (this.chatStart = true),
              (this.intraction = "pointer-events:all")
            ),
            800
          );
        } else if (
          this.series.gameCode == "COOK" &&
          tutExploreCooking.length == 0
        ) {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          tempTut.Ongoing = "tutExploreCooking";
          tempTut.Step = 1;
          this.$store.commit("updateTutorial", tempTut);

          this.intraction = "pointer-events: none;";
          setTimeout(
            () => (
              (this.tutorialChat = this.tutExploreCookingChats),
              (this.chatStart = true),
              (this.intraction = "pointer-events:all")
            ),
            800
          );
        } else if (
          this.series.gameCode == "BATTLE" &&
          tutExploreKombat.length == 0
        ) {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          tempTut.Ongoing = "tutExploreKombat";
          tempTut.Step = 1;
          this.$store.commit("updateTutorial", tempTut);

          this.intraction = "pointer-events: none;";
          setTimeout(
            () => (
              (this.tutorialChat = this.tutExploreKombatChats),
              (this.chatStart = true),
              (this.intraction = "pointer-events:all")
            ),
            800
          );
        } else if (
          this.series.gameCode == "FISH" &&
          tutExploreFishing.length == 0
        ) {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          tempTut.Ongoing = "tutExploreFishing";
          tempTut.Step = 1;
          this.$store.commit("updateTutorial", tempTut);

          this.intraction = "pointer-events: none;";
          setTimeout(
            () => (
              (this.tutorialChat = this.tutExploreFishingChats),
              (this.chatStart = true),
              (this.intraction = "pointer-events:all")
            ),
            800
          );
        } else if (
          this.series.gameCode == "DINO" &&
          tutExploreDino.length == 0
        ) {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          tempTut.Ongoing = "tutExploreDino";
          tempTut.Step = 1;
          this.$store.commit("updateTutorial", tempTut);

          this.intraction = "pointer-events: none;";
          setTimeout(
            () => (
              (this.tutorialChat = this.tutExploreDinoChats),
              (this.chatStart = true),
              (this.intraction = "pointer-events:all")
            ),
            800
          );
        } else if (tutExploreLevel.length == 0) {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          tempTut.Ongoing = "tutExploreLevel";
          tempTut.Step = 1;
          this.$store.commit("updateTutorial", tempTut);

          this.intraction = "pointer-events: none;";
          setTimeout(
            () => (
              (this.tutorialChat = this.tutExploreLevelChats),
              (this.chatStart = true),
              (this.intraction = "pointer-events:all")
            ),
            800
          );
        }
      } else {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        tempTut.Ongoing = null;
        tempTut.Step = null;
        this.$store.commit("updateTutorial", tempTut);
      }
    },
    extiError() {
      this.error = false;
    },
    exitConfirm() {
      this.confirm = false;
    },
    openConfirm() {
      this.confirm = true;
    },
    gift() {
      if (this.auth.User.type != "child") {
        this.errorMessage = this.$t("string.gifts_error");
        this.error = true;
      } else if (this.auth.Group.type == "institution") {
        this.$router.push({
          name: "PageEventGiftSent",
        });
      } else {
        this.$router.push({
          name: "PageEventGiftForm",
          query: { action: "add" },
        });
      }
    },
    updateTutorial() {
      if (!this.isGameTutCompleted) {
        this.tutorialApi.url =
          this.$api.servers.game +
          "/api/v1/" +
          this.$_getLocale() +
          "/game/tutorial/complete";
        this.tutorialApi.callbackReset = () => {
          this.tutorialApi.isLoading = true;
          this.tutorialApi.isError = false;
          this.tutorialApi.error = null;
        };
        this.tutorialApi.callbackError = (e) => {
          this.tutorialApi.isLoading = false;
          this.tutorialApi.isError = true;
          this.tutorialApi.error = e;
        };
        this.tutorialApi.callbackSuccess = (resp) => {
          this.tutorialApi.isLoading = false;
          this.$store.commit("updateTutorial", resp);
          setTimeout(() => this.next(), 5000);
        };
        if (this.tutorial.Ongoing == "tutExploreDino") {
          this.tutorialApi.params = {
            key: "tutExploreDino",
          };
        }
        this.$api.fetch(this.tutorialApi);
      }
    },
    savePlayer() {
      this.event_data = this.$_.cloneDeep(this._event_data);
      this.PlayerApi.url =
        this.$api.servers.event +
        "/api/v2/" +
        this.$_getLocale() +
        "/game/event/player/save";

      this.PlayerApi.callbackReset = () => {
        this.PlayerApi.isLoading = true;
        this.PlayerApi.isError = false;
      };

      this.PlayerApi.callbackError = (e) => {
        this.PlayerApi.isLoading = false;
        this.PlayerApi.isError = true;
        this.PlayerApi.error = e;
      };
      this.PlayerApi.callbackSuccess = (resp) => {
        this.PlayerApi.isLoading = false;
        this.event_data.Save = resp.Save;
        this.event_data.Player = resp.Player;
        this.$store.commit("updateEvent", this.event_data);
        this.mail = true;
      };
      let _data = JSON.parse(this.event_data.Save.data);
      _data.isInitToken = this.$moment();
      _data.token = 20;
      this.event_data.Save.data = JSON.stringify(_data);
      this.PlayerApi.params = {
        eventCode: this.$route.params.code,
        version: this.event_data.Save.version
          ? this.event_data.Save.version
          : "1.0",
        build: this.event_data.Save.build + 1,
        data: this.event_data.Save.data,
        totalStar: this.event_data.Player.totalStar,
        totalScore: this.event_data.Player.totalScore,
        totalAttempt: this.event_data.Player.totalAttempt,
        totalSubmission: this.event_data.Player.totalSubmission,
        progress: this.event_data.Player.totalStar / (this.levels.length * 3),
        schoolYear: this.schoolYear,
      };
      this.$api.fetch(this.PlayerApi);
    },
    callback() {
      this.chatStart = false;

      if (this.tutorial.Ongoing == "tutExploreMining") {
        this.intraction = "pointer-events: none;";
        setTimeout(() => {
          this.$intro().exit();
          this.$intro()
            .setOptions({
              exitOnOverlayClick: false,
              showButtons: false,
              showBullets: false,
              steps: [
                {
                  element: document.querySelector("#StartTutorial"),
                  intro:
                    '<img src="' +
                    require("@/assets/tutorial/chat_1.png") +
                    '">' +
                    this.$t("tutorial.exploreMining.dialog_2"),
                },
              ],
            })
            .start();
          this.intraction = "pointer-events: all;";
          let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
          audio.volume = this.settings.audio.sfx * this.settings.audio.master;
          audio.play();
        }, 1000);
      } else if (this.tutorial.Ongoing == "tutExploreForaging") {
        this.intraction = "pointer-events: none;";
        setTimeout(() => {
          this.$intro().exit();
          this.$intro()
            .setOptions({
              exitOnOverlayClick: false,
              showButtons: false,
              showBullets: false,
              steps: [
                {
                  element: document.querySelector("#StartTutorial"),
                  intro:
                    '<img src="' +
                    require("@/assets/tutorial/chat_1.png") +
                    '">' +
                    this.$t("tutorial.exploreForaging.dialog_2"),
                },
              ],
            })
            .start();
          this.intraction = "pointer-events: all;";
          let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
          audio.volume = this.settings.audio.sfx * this.settings.audio.master;
          audio.play();
        }, 1000);
      } else if (this.tutorial.Ongoing == "tutExploreCooking") {
        this.intraction = "pointer-events: none;";
        setTimeout(() => {
          this.$intro().exit();
          this.$intro()
            .setOptions({
              exitOnOverlayClick: false,
              showButtons: false,
              showBullets: false,
              steps: [
                {
                  element: document.querySelector("#StartTutorial"),
                  intro:
                    '<img src="' +
                    require("@/assets/tutorial/chat_1.png") +
                    '">' +
                    this.$t("tutorial.exploreCooking.dialog_2"),
                },
              ],
            })
            .start();
          this.intraction = "pointer-events: all;";
          let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
          audio.volume = this.settings.audio.sfx * this.settings.audio.master;
          audio.play();
        }, 1000);
      } else if (this.tutorial.Ongoing == "tutExploreKombat") {
        this.intraction = "pointer-events: none;";
        setTimeout(() => {
          this.$intro().exit();
          this.$intro()
            .setOptions({
              exitOnOverlayClick: false,
              showButtons: false,
              showBullets: false,
              steps: [
                {
                  element: document.querySelector("#StartTutorial"),
                  intro:
                    '<img src="' +
                    require("@/assets/tutorial/chat_1.png") +
                    '">' +
                    this.$t("tutorial.exploreKombat.dialog_2"),
                },
              ],
            })
            .start();
          this.intraction = "pointer-events: all;";
          let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
          audio.volume = this.settings.audio.sfx * this.settings.audio.master;
          audio.play();
        }, 1000);
      } else if (this.tutorial.Ongoing == "tutExploreFishing") {
        this.intraction = "pointer-events: none;";
        setTimeout(() => {
          this.$intro().exit();
          this.$intro()
            .setOptions({
              exitOnOverlayClick: false,
              showButtons: false,
              showBullets: false,
              steps: [
                {
                  element: document.querySelector("#StartTutorial"),
                  intro:
                    '<img src="' +
                    require("@/assets/tutorial/chat_1.png") +
                    '">' +
                    this.$t("tutorial.exploreFishing.dialog_2"),
                },
              ],
            })
            .start();
          this.intraction = "pointer-events: all;";
          let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
          audio.volume = this.settings.audio.sfx * this.settings.audio.master;
          audio.play();
        }, 1000);
      } else if (this.tutorial.Ongoing == "tutExploreDino") {
        this.intraction = "pointer-events: none;";
        setTimeout(() => {
          this.$intro().exit();
          this.$intro()
            .setOptions({
              exitOnOverlayClick: false,
              showButtons: false,
              showBullets: false,
              steps: [
                {
                  element: document.querySelector("#StartTutorial"),
                  intro:
                    '<img src="' +
                    require("@/assets/tutorial/chat_1.png") +
                    '">' +
                    this.$t("tutorial.exploreDino.dialog_2"),
                },
              ],
            })
            .start();
          this.intraction = "pointer-events: all;";
          let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
          audio.volume = this.settings.audio.sfx * this.settings.audio.master;
          audio.play();
        }, 1000);
      } else if (this.tutorial.Ongoing == "tutExploreLevel") {
        this.intraction = "pointer-events: none;";
        setTimeout(() => {
          this.$intro().exit();
          this.$intro()
            .setOptions({
              exitOnOverlayClick: false,
              showButtons: false,
              showBullets: false,
              scrollToElement: false,
              preventScrolling: true,
              steps: [
                {
                  element: document.querySelector("#StartLevel"),
                  intro:
                    '<img src="' +
                    require("@/assets/tutorial/chat_1.png") +
                    '">' +
                    this.$t("tutorial.exploreLevel.dialog_2"),
                },
              ],
            })
            .start();
          this.intraction = "pointer-events: all;";
          let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
          audio.volume = this.settings.audio.sfx * this.settings.audio.master;
          audio.play();
        }, 1000);
      }
    },
  },
};
</script>

<style>
.custom-introjs-class {
  position: absolute;
  top: calc(50px - 100%);
  left: 50%;
  transform: translateX(-50%);
}

.custom-introjs-highlight-class {
  margin-top: 100px;
}

.mail-text {
  color: #fff;
  text-align: center;
  text-shadow: #000 3px 0px 0px, #000 2.83487px 0.981584px 0px,
    #000 2.35766px 1.85511px 0px, #000 1.62091px 2.52441px 0px,
    #000 0.705713px 2.91581px 0px, #000 -0.287171px 2.98622px 0px,
    #000 -1.24844px 2.72789px 0px, #000 -2.07227px 2.16926px 0px,
    #000 -2.66798px 1.37182px 0px, #000 -2.96998px 0.42336px 0px,
    #000 -2.94502px -0.571704px 0px, #000 -2.59586px -1.50383px 0px,
    #000 -1.96093px -2.27041px 0px, #000 -1.11013px -2.78704px 0px,
    #000 -0.137119px -2.99686px 0px, #000 0.850987px -2.87677px 0px,
    #000 1.74541px -2.43999px 0px, #000 2.44769px -1.73459px 0px,
    #000 2.88051px -0.838247px 0px;
}
.mail-box {
  box-shadow: none;
}
.tab-bar-card {
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #e1c8aa;
  text-align: center;
  position: relative;
}

.hamochi {
  width: 70%;
  display: inline-flex;
  align-items: center;
}
.image {
  width: 25%;
  display: inline-block;
}
.chat {
  width: 75%;
  display: inline-block;
}
.gift {
  width: 30%;
  display: inline-block;
  z-index: 4;
  margin-top: 10px;
}

.speech-bubble {
  font-size: small;
  position: relative;
  background: #fff;
  border-radius: 0.4em;
  margin-left: 10px;
  padding: 5px;
  line-height: 18px;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: #fff;
  border-left: 0;
  margin-top: -10px;
  margin-left: -9px;
}

.stars-panel {
  color: #fff;
  position: absolute;
  border-radius: 50px 0 0 50px;
  bottom: 0;
  height: 40px;
  width: 80%;
  background-color: #6b583c;
  margin: 10px;
  z-index: 3;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.star-progress {
  height: calc(100% - 10px);
  left: 5px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: rgb(0, 233, 0);
  position: absolute;
  z-index: -1;
}

.star-progress-full {
  height: calc(100% - 10px);
  left: 5px;
  border-radius: 15px;
  background-color: rgb(0, 233, 0);
  position: absolute;
  z-index: -1;
}

.star-progress-back {
  width: 84%;
  height: calc(100% - 10px);
  left: 5px;
  position: absolute;
  border-radius: 15px;
  background-color: rgba(0, 233, 0, 0.25);
  position: absolute;
  z-index: -1;
}

.ticket-card {
  background-color: #fff;
  height: fit-content;
  padding: 0 10px;
}

.level-cart {
  padding: 10px;
  margin-bottom: 12px;
  width: calc((100% / 3) - 8px);
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.level-img {
  background-color: rgb(140, 219, 140);
}

.gift-shake {
  animation: shake 1s infinite;
  cursor: pointer;
}

.gift-lock {
  pointer-events: none;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(-1deg);
  }
  20% {
    transform: translate(-4px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
}

.no-boss {
  filter: brightness(0);
  opacity: 0.5 !important;
}

.gift-shine {
  position: absolute;
  animation: shine 5s linear infinite;
}

@keyframes shine {
  0% {
    transform: scale(1, 1) rotate(0deg);
  }
  50% {
    transform: scale(1.5, 1.5) rotate(180deg);
  }
  100% {
    transform: scale(1, 1) rotate(359deg);
  }
}

.container-1x1 {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.aspect-ratio-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2; /* Placeholder color */
  animation: skeleton-loader 1s ease-in-out infinite alternate;
}

@keyframes skeleton-loader {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.3;
  }
}
</style>