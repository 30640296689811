<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("string.mathMastery") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="px-5 py-2 text-center brown--text text-h5 mb-2 cvcx">
            {{ $route.query.topic }}
          </div>
          <div class="pa-5 mt-4 cvcx">
            <v-row>
              <v-col
                cols="4"
                v-for="(item, i) in items"
                :key="i"
                style="position: relative"
              >
                <div
                  :class="
                    selectedItem == item ? 'selected-menu-item' : 'menu-item'
                  "
                  v-if="item.icon"
                >
                  <v-img
                    :src="item.icon"
                    width="150"
                    class="d-flex justify-center align-end text-center text-stroke-sm text-h6"
                    @click="
                      selectedItem = item;
                      countDown = 5;
                    "
                  >
                    <!-- {{ item.name }} -->
                  </v-img>
                </div>

                <div
                  v-else-if="item.name"
                  style="aspect-ratio: 1"
                  class="d-flex align-center justify-center text-stroke-sm menu-item text-h5"
                  :class="
                    selectedItem == item ? 'selected-menu-item' : 'menu-item'
                  "
                  @click="
                    selectedItem = item;
                    countDown = 5;
                  "
                >
                  {{ item.name }}
                </div>
                <div
                  v-else
                  style="aspect-ratio: 1"
                  class="d-flex align-center justify-center"
                >
                  <v-icon color="brown lighten-2">mdi-circle</v-icon>
                </div>
              </v-col>
              <!-- <v-col cols="4" v-for="i in 9 - items.length" :key="i + 7">
                <div
                  style="aspect-ratio: 1"
                  class="d-flex align-center justify-center"
                >
                  <v-icon color="brown lighten-2">mdi-circle</v-icon>
                </div>
              </v-col> -->
            </v-row>
            <div
              class="text-center brown--text text-h5 mt-4"
              v-if="selectedItem"
            >
              {{ selectedItem.name }}
            </div>
          </div>
          <div class="pa-5 mt-4 cvcx">
            <div class="d-flex flex-column justify-center align-center">
              <div class="d-flex justify-center ma-3 mx-auto">
                <v-hover v-slot="{ hover }">
                  <v-card
                    :elevation="hover ? 6 : 2"
                    class="ma-2 d-flex align-center justify-center"
                    width="80"
                    height="80"
                    :color="
                      difficulty == 1 ? 'yellow lighten-4' : 'grey lighten-4'
                    "
                    :class="{ 'on-hover': hover }"
                    @click="difficulty = 1"
                  >
                    <span>
                      <div class="text-cption font-weight-medium">
                        {{ $t("string.easy") }}
                      </div>
                      <!-- <div>+50</div> -->
                      <div class="text-h4 text-center text--primary mb-3 pt-3">
                        <v-row align="center" justify="center">
                          <v-img
                            max-width="15"
                            max-height="25"
                            class="mt-2"
                            :src="
                              require('@/assets/bugCatcher/DifficultyFire.png')
                            "
                          ></v-img>
                        </v-row>
                      </div>
                      <div class="text-center text-caption">+ 0</div>
                    </span>
                  </v-card>
                </v-hover>
                <v-hover v-slot="{ hover }">
                  <v-card
                    :elevation="hover ? 6 : 2"
                    class="ma-2 d-flex align-center justify-center"
                    width="80"
                    height="80"
                    :color="
                      difficulty == 2 ? 'yellow lighten-4' : 'grey lighten-4'
                    "
                    :class="{ 'on-hover': hover }"
                    @click="difficulty = 2"
                  >
                    <span>
                      <div class="text-cption font-weight-medium">
                        {{ $t("string.medium") }}
                      </div>
                      <!-- <div>+100</div> -->
                      <div class="text-h4 text-center text--primary mb-3 pt-3">
                        <v-row align="center" justify="center">
                          <v-img
                            max-width="15"
                            max-height="25"
                            class="mt-2"
                            :src="
                              require('@/assets/bugCatcher/DifficultyFire.png')
                            "
                          ></v-img>
                          <v-img
                            max-width="15"
                            max-height="25"
                            class="mt-2"
                            :src="
                              require('@/assets/bugCatcher/DifficultyFire.png')
                            "
                          ></v-img>
                        </v-row>
                      </div>
                      <div class="text-center text-caption">+ 50</div>
                    </span>
                  </v-card>
                </v-hover>
                <v-hover v-slot="{ hover }">
                  <v-card
                    :elevation="hover ? 6 : 2"
                    class="ma-2 d-flex align-center justify-center"
                    width="80"
                    height="80"
                    :color="
                      difficulty == 3 ? 'yellow lighten-4' : 'grey lighten-4'
                    "
                    :class="{ 'on-hover': hover }"
                    @click="difficulty = 3"
                  >
                    <span>
                      <div class="text-cption font-weight-medium">
                        {{ $t("string.hard") }}
                      </div>
                      <!-- <div>+150</div> -->
                      <div class="text-h4 text-center text--primary mb-3 pt-3">
                        <v-row align="center" justify="center">
                          <v-img
                            max-width="15"
                            max-height="25"
                            class="mt-2"
                            :src="
                              require('@/assets/bugCatcher/DifficultyFire.png')
                            "
                          ></v-img>
                          <v-img
                            max-width="15"
                            max-height="25"
                            class="mt-2"
                            :src="
                              require('@/assets/bugCatcher/DifficultyFire.png')
                            "
                          ></v-img>
                          <v-img
                            max-width="15"
                            max-height="25"
                            class="mt-2"
                            :src="
                              require('@/assets/bugCatcher/DifficultyFire.png')
                            "
                          ></v-img>
                        </v-row>
                      </div>
                      <div class="text-center text-caption">+ 100</div>
                    </span>
                  </v-card>
                </v-hover>
              </div>
              <v-btn block large @click="playGame(selectedItem.key)">
                {{ $t("string.continueIn", { sec: countDown }) }}
              </v-btn>
            </div>
          </div>
        </v-container>
      </v-main>
      <SkipTut />
    </v-app>
  </div>
  <!-- </transition> -->
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    SkipTut: () =>
      import(
        /* webpackChunkName: "component-skip-tut" */ "@/components/tutorial/SkipTut"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
    tutorial: (state) => state.tutorial.data,
  }),
  props: [
    //
  ],
  data: () => ({
    items: [],
    selectedItem: null,
    interval: null,
    countDown: 5,
    difficulty: 1,
  }),
  created() {
    //
  },
  mounted() {
    this.items = [
      {
        name: this.$t("games.random"),
        key: "RANDOM",
        icon: require("@/assets/math/icon_RANDOM.png"),
      },
      {
        name: this.$t("games.noGame"),
        key: "NOGAME",
        icon: require("@/assets/math/icon_NOGAME.png"),
      },
      {
        name: this.$t("games.mining"),
        key: "GEMS",
        icon: require("@/assets/math/icon_GEMS.png"),
      },
      {
        name: this.$t("games.foraging"),
        key: "FRUIT",
        icon: require("@/assets/math/icon_FRUIT.png"),
      },
      {
        name: this.$t("games.cooking"),
        key: "COOK",
        icon: require("@/assets/math/icon_COOK.png"),
      },
      // {
      //   key: "BATTLE",
      //   icon: require("@/assets/math/icon_BATTLE.png"),
      // },
      {
        name:this.$t("games.fishing"),
        key: "FISH",
        icon: require("@/assets/math/icon_FISH.png"),
      },
    ];
    this.selectedItem = this.items[0];

    this.interval = setInterval(() => {
      if (this.countDown > 0) {
        this.countDown--;
      } else {
        clearInterval(this.interval);
        this.playGame(this.selectedItem.key);
      }
    }, 1000);
  },
  methods: {
    playGame(game) {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.$router.push({
        name: "PageMathGame",
        params: { topic: this.$route.params.topic, game: game },
        query: { difficulty: this.difficulty },
      });
    },
    exit() {
      this.$router.push({
        name: "PageMathMastery",
      });
    },
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>

<style scoped>
.cvcx {
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #f7efe3;
}

.menu-button {
  padding: 0 5px;
  cursor: pointer;
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.menu-item {
  cursor: pointer;
  height: 100%;
}

.menu-item:hover {
  /* scale: 1.1; */
  border: #caad8794 solid 5px;
}

.selected-menu-item {
  cursor: pointer;
  height: 100%;
  border: #caad87 solid 5px;
  background-color: #f7efe388;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.main-body {
  /*background-color: #c7e5e9;*/
  background-color: rgba(214, 132, 49, 0.3);
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}
</style>