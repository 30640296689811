<template>
  <div>
    <div
      style="height: 100%"
      v-if="isDisabled"
      class="d-flex justify-center align-center white--text text-h4 disabled-item-set"
    >
      <v-icon color="brown lighten-3">mdi-circle</v-icon>
    </div>
    <div class="item-set" :class="checkClass()" v-else>
      <div class="image-card">
        <div class="container-1x1">
          <div class="aspect-ratio-item">
            <v-img
              max-width="100%"
              max-height="100%"
              contain
              class="pa-1 text-right"
              :src="imageUrl"
              :style="isLock ? 'filter: brightness(0); opacity: 0.4' : ''"
            >
            </v-img>
          </div>
        </div>
      </div>
      <div v-if="isLock" class="text-center">
        <v-icon color="brown lighten-1">mdi-lock</v-icon>
      </div>

      <div v-else>
        <div v-if="isTick" class="pb-1">
          <v-img
            :src="require('@/assets/explore/menu/tick.png')"
            width="30"
            class="mx-auto"
            style="margin-top: -8px"
          >
          </v-img>
        </div>
        <div v-if="isShowCount" class="item-count text-center">
          +{{ count }}
        </div>
        <div v-if="isShowQuantity" class="item-count text-center">
          <span class="ma-3" v-if="quantity"> x{{ quantity }} </span>
          <span class="ma-3" v-else> - </span>
        </div>
        <div v-if="isShowPrice" class="item-count text-center">
          <span class="d-flex justify-center align-center">
            {{ price }}
            <v-img class="ml-1" :src="currency" max-width="20"> </v-img>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    imageUrl: {
      type: String,
    },
    currency: {
      type: String,
      default: null,
    },
    isTick: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isShowCount: {
      type: Boolean,
      default: false,
    },
    isShowQuantity: {
      type: Boolean,
      default: false,
    },
    isLock: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
    quantity: {
      type: Number,
      default: null,
    },
    isShowPrice: {
      type: Boolean,
      default: false,
    },
    price: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    checkClass() {
      if (this.isSelected) {
        return "selected-item";
      }
    },
  },
};
</script>
<style>
.item-set {
  position: relative !important;
  border-radius: 5px !important;
  background-color: #fff;
  border: 5px solid #cca06d;
}
.disabled-item-set {
  position: relative !important;
  aspect-ratio: 1;
}
.selected-item {
  border: 5px solid #23d2d8;
}
.tick-mark {
  position: absolute !important;
  color: #00e676;
  bottom: 0%;
  right: 5%;
  font-size: 25px;
}
.item-count {
  /* border: 2px solid black; */
  /* color: white; */
  margin-left: -1px;
  margin-bottom: -1px;
  font-size: large;
}
.image-card {
  background-color: #9f7e57;
  height: 100%;
}

/* .image-card:after {
  content: "";
  display: block;
  padding-bottom: 100%;
} */
@media screen and (max-width: 480px) {
  .tick-mark {
    font-size: 5vw;
  }
}
.disabled-item-image {
  filter: brightness(0%) contrast(10%) !important;
}

.container-1x1 {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.aspect-ratio-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2; /* Placeholder color */
  animation: skeleton-loader 1s ease-in-out infinite alternate;
}

@keyframes skeleton-loader {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.3;
  }
}
</style>