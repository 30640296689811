import Phaser from "phaser";
import LoadingScene from "@/dailyGames/Mining/scenes/LoadingScene";
import RoundStartScene from "@/dailyGames/Mining/scenes/RoundStartScene";
import QuestionScene from "@/dailyGames/Mining/scenes/QuestionScene";
import PlayScene from "@/dailyGames/Mining/scenes/PlayScene";
import BossScene from "@/dailyGames/Mining/scenes/BossScene";
import LandingScene from "@/dailyGames/Mining/scenes/LandingScene";
import Settings from "@/dailyGames/Settings";

function launch(containerId) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    backgroundColor: 0xf5f5f5,
    parent: containerId,
    scale: {
      parent: containerId,
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
      width: 480,
      height: 854,
    },
    dom: {
      createContainer: true,
    },
    scene: [
      LoadingScene,
      RoundStartScene,
      QuestionScene,
      PlayScene,
      BossScene,
      LandingScene,
      Settings,
    ],
  });
}

export default launch;
export { launch };
