var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"list-card",attrs:{"flat":""},on:{"click":function($event){return _vm.act()}}},[_c('div',{staticClass:"d-flex flex-no-wrap"},[_c('v-avatar',{staticClass:"ma-3",attrs:{"size":"125","tile":""}},[(_vm.data.translatableImage)?_c('v-img',{attrs:{"src":JSON.parse(_vm.data.translatableImage)[_vm.$_getLocale()]
          ? JSON.parse(_vm.data.translatableImage)[_vm.$_getLocale()]
            .url
          : JSON.parse(_vm.data.translatableImage)[
            _vm.getSubjectLang()
          ].url}}):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column justify-space-between",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"text-h5 mx-2 pt-3"},[_vm._v(" "+_vm._s(JSON.parse(_vm.data.translatableName)[_vm.getSubjectLang()])+" ")]),(
        _vm.data.timestampStart &&
        (_vm.$moment() < _vm.$moment(_vm.data.timestampStart) || _vm.data.PlayerBook.length == 0)
      )?_c('div',{staticClass:"px-3 grey--text text-caption mb-1"},[_vm._v(" "+_vm._s(_vm.$moment(_vm.data.timestampStart).locale((_vm.$_getLocale() == "zh") ? "zh-cn" : _vm.$_getLocale()).format("LL"))+" "+_vm._s(_vm.data.timestampEnd ? " - " + _vm.$moment(_vm.data.timestampEnd).locale((_vm.$_getLocale() == "zh") ? "zh-cn" : _vm.$_getLocale()).format("LL") : " +")+" ")]):_vm._e(),(_vm.data.PlayerBook.length > 0)?_c('div',{staticClass:"px-3"},[(_vm.data.progress < 1)?_c('v-progress-linear',{attrs:{"color":"blue","rounded":"","height":"8","value":_vm.data.progress * 100}}):_c('v-progress-linear',{attrs:{"color":"green","rounded":"","height":"8","value":100}})],1):_vm._e(),_c('v-card-actions',{staticClass:"flex-wrap"},[_c('v-chip',{staticClass:"my-1",attrs:{"small":"","dark":""}},[_vm._v(_vm._s(_vm.data.subject))]),_c('ChipBookType',{staticClass:"ml-2",attrs:{"book":_vm.data}}),_c('v-spacer'),(_vm.data.PlayerBook.length > 0 && _vm.data.progress < 1)?_c('v-btn',{staticClass:"text-h6 constant-tilt-shake",attrs:{"color":"blue","dark":""},on:{"click":_vm.read}},[_vm._v(" ▶ "+_vm._s(_vm.$t("string.read"))+" ")]):(_vm.data.PlayerBook.length > 0)?_c('v-btn',{staticClass:"text-h6",attrs:{"color":"success","dark":""},on:{"click":_vm.read}},[_vm._v(" ✔ "+_vm._s(_vm.$t("string.read"))+" ")]):(
          _vm.data.timestampStart &&
          _vm.$moment() < _vm.$moment(_vm.data.timestampStart)
        )?_c('v-btn',{attrs:{"text":"","dark":"","color":"orange lighten-1"}},[_vm._v(" "+_vm._s(_vm.$t("string.comingSoon"))+" ")]):_c('v-btn',{staticClass:"text-h6 constant-tilt-shake",attrs:{"color":"green","dark":"","loading":_vm.api.isLoading && _vm.selectedBook == _vm.data}},[_c('div',[_vm._v(_vm._s(_vm.$t("action.claim")))])])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }