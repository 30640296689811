<template>
  <v-card
    class="mb-12 xc-card pa-4"
    width="100%"
    max-width="400"
    min-height="455"
  >
    <!-- <div class="text-center text-h5">{{ $t("string.result") }}</div>
      <v-divider></v-divider> -->
    <div class="mt-4" style="position: relative">
      <!-- <div class="d-flex justify-center">
          <div class="px-2">{{ $t("string.gameScore") }} :</div>
          <div class="px-2">{{ score - 50 * (difficulty - 1) }}</div>
        </div>
        <div class="d-flex justify-center align-center">
          <div class="px-2">
            {{
              difficulty == 3
                ? $t("string.hard")
                : difficulty == 2
                ? $t("string.medium")
                : $t("string.easy")
            }}
          </div>
          <v-img
            v-for="i in difficulty"
            :key="i"
            max-width="20"
            max-height="20"
            :src="require('@/assets/bugCatcher/DifficultyFire.png')"
          ></v-img>
          <div class="ml-2">:</div>

          <div class="px-2">+ {{ 50 * (difficulty - 1) }}</div>
        </div>
        <div class="d-flex text-h6 justify-center">
          <div class="pa-2">{{ $t("string.totalScore") }} :</div>
          <div class="pa-2">{{ score }}</div>
        </div>

        <v-divider></v-divider> -->

      <div class="text-h5 text-center mb-4">
        {{ $t("string.completed") }}
      </div>
      <!-- <div
        class="text-center text-h4 mt-5"
        :class="
          star == 3
            ? 'teal--text'
            : star == 2
            ? 'indigo--text'
            : star == 1
            ? 'orange--text'
            : 'red--text'
        "
      >
        {{
          star == 3
            ? "Master"
            : star == 2
            ? "Excel"
            : star == 1
            ? "Pass"
            : "Fail"
        }}
      </div> -->

      <v-sheet height="100" color="transparent">
        <v-img
          v-if="showshield"
          contain
          max-width="100"
          class="mx-auto zoom-in"
          :src="
            require(star == 3
              ? '@/assets/math/shields/gradeA.png'
              : star == 2
              ? '@/assets/math/shields/gradeB.png'
              : star == 1
              ? '@/assets/math/shields/gradeC.png'
              : '@/assets/math/shields/gradeF.png')
          "
        ></v-img>
      </v-sheet>

      <v-sheet height="80" color="transparent">
        <div class="d-flex justify-center fade-in" v-if="showstars">
          <div class="mx-2">
            <v-icon
              :color="totalStars > 0 ? 'amber darken-1' : 'gray lighten-2'"
              x-large
            >
              mdi-star</v-icon
            >
            <div class="text-center text-h6">3</div>
          </div>
          <div class="mx-2">
            <v-icon
              :color="totalStars > 1 ? 'amber darken-1' : 'gray lighten-2'"
              x-large
            >
              mdi-star</v-icon
            >
            <div class="text-center text-h6">4</div>
          </div>
          <div class="mx-2">
            <v-icon
              :color="totalStars > 2 ? 'amber darken-1' : 'gray lighten-2'"
              x-large
            >
              mdi-star</v-icon
            >
            <div class="text-center text-h6">5</div>
          </div>
        </div>
      </v-sheet>

      <div
        class="d-flex justify-center text-h6 align-center zoom-in-x"
        v-if="showComplete"
      >
        <v-icon class="me-2" color="green"> mdi-check-circle-outline </v-icon>
        {{ countCorrect }} / {{ totalQ }}
      </div>
      <div class="d-flex flex-column align-center mt-6 fade-in" v-if="showBtn">
        <v-btn
          color="green"
          max-width="200"
          width="100%"
          class="my-2"
          rounded
          dark
          @click="clickDone"
        >
          <!-- {{ $t("action.done") }} -->
          SIGN UP
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          class="my-2"
          max-width="200"
          width="100%"
          rounded
          @click="clickReplay"
        >
          {{ $t("action.playAgain") }}
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    "score",
    "playAgain",
    "difficulty",
    "star",
    "countCorrect",
    "totalQ",
    "game",
    "topic",
    "isDemo",
  ],
  data: () => ({
    showBtn: false,
    showstars: false,
    showshield: false,
    showComplete: false,
    totalStars: 0,
  }),
  created() {
    //
  },
  mounted() {
    setTimeout(() => {
      this.showComplete = true;
      setTimeout(() => {
        this.showstars = true;
        for (let index = 0; index < this.star; index++) {
          setTimeout(() => {
            this.totalStars++;
          }, (index + 1) * 500);
        }
        setTimeout(() => {
          this.showshield = true;
          setTimeout(() => {
            this.showBtn = true;
          }, 1000);
        }, this.star * 500 + 1000);
      }, 500);
    }, 500);
  },
  methods: {
    clickDone() {
      if (this.isDemo) {
        window.location.href = `${process.env.VUE_APP_URL_WEB_SCHOOL}/${this.$_getLocale()}/register`;
      } else {
        this.$router.push({
          name: "PageMathMastery",
        });
      }
    },
    clickReplay() {
      this.$router.push({
        name: this.isDemo ? "PageMathGameDemo" : "PageMathGame",
        params: {
          topic: this.topic,
          game: this.game,
        },
      });
    },
  },
};
</script>

<style scoped>
.no-boss {
  filter: brightness(0);
  opacity: 50%;
}

.xc-card {
  padding: 5px;
  color: #785c3a;
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #fff9f1;
}

.zoom-in {
  animation: zoom-in 0.5s ease-out;
}

.zoom-in-x {
  animation: zoom-in 0.2s ease-out;
}

.fade-in {
  animation: fade-in 0.5s ease-out;
}

@keyframes zoom-in {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>