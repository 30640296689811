<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <!-- EOC -->
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("string.maths") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>

          <div class="px-5">
            <v-card class="list-card my-2">
              <v-img
                :src="require('@/assets/background/BannerDailyMath.png')"
                height="200px"
                style="border-radius: 0px !important"
              ></v-img>
              <v-card-title class="text-h5"> {{ $t("string.mathDaily") }} </v-card-title>
              <v-card-actions class="d-flex align-center">
                <div class="list-card-x d-flex pe-2" v-if="towerData">
                  {{ $t("miniGames.towerClimbing.floor") }}
                  {{ towerData.currentFloor }}
                </div>
                <v-spacer></v-spacer>
                <div
                  class="list-card-x pe-2 d-flex align-center"
                  v-if="footPrints"
                >
                  <v-img
                    contain
                    width="30"
                    height="30"
                    class="me-1"
                    :src="footPrints.images.icon.url"
                  ></v-img>
                  {{ footPrints.quantity }}
                </div>
                <v-btn
                  color="blue"
                  dark
                  class="ms-4"
                  @click="
                    $router.push({
                      name: 'PageExploreDailyGame',
                    })
                  "
                >
                  <span class="text-h6">
                    {{ $t("string.go") }}
                  </span>
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card class="list-card my-6">
              <v-img
                :src="require('@/assets/background/BannerMathMastery.png')"
                height="200px"
                style="border-radius: 0px !important"
              ></v-img>
              <v-card-title class="text-h5"> {{ $t("string.mathMastery") }} </v-card-title>
              <v-card-actions class="d-flex align-center">
                <div class="list-card-x pe-2 d-flex align-center me-2">
                  <v-icon color="amber" size="30">mdi-star</v-icon>
                  {{
                    masteryData && masteryData.totalStar
                      ? masteryData.totalStar
                      : 0
                  }}
                </div>
                <!-- <div
                  class="list-card-x d-flex align-center"
                  v-if="
                    masteryData &&
                    (masteryData.totalGradeA ||
                      masteryData.totalGradeB ||
                      masteryData.totalGradeC ||
                      masteryData.totalGradeF)
                  "
                >
                  <div
                    class="d-flex pe-2 align-center"
                    v-if="masteryData && masteryData.totalGradeA"
                  >
                    <v-img
                      contain
                      max-width="30"
                      class="mx-auto"
                      :src="require('@/assets/math/shields/gradeA.png')"
                    ></v-img>
                    {{ masteryData.totalGradeA }}
                  </div>
                  <div
                    class="d-flex pe-2 align-center"
                    v-if="masteryData && masteryData.totalGradeB"
                  >
                    <v-img
                      contain
                      max-width="30"
                      class="mx-auto"
                      :src="require('@/assets/math/shields/gradeB.png')"
                    ></v-img>
                    {{ masteryData.totalGradeB }}
                  </div>
                  <div
                    class="d-flex pe-2 align-center"
                    v-if="masteryData && masteryData.totalGradeC"
                  >
                    <v-img
                      contain
                      max-width="30"
                      class="mx-auto"
                      :src="require('@/assets/math/shields/gradeC.png')"
                    ></v-img>
                    {{ masteryData.totalGradeC }}
                  </div>
                  <div
                    class="d-flex pe-2 align-center"
                    v-if="masteryData && masteryData.totalGradeF"
                  >
                    <v-img
                      contain
                      max-width="30"
                      class="mx-auto"
                      :src="require('@/assets/math/shields/gradeF.png')"
                    ></v-img>
                    {{ masteryData.totalGradeF }}
                  </div>
                </div> -->
                <v-spacer></v-spacer>
                <div class="list-card-x pe-2 d-flex align-center" v-if="energy">
                  <v-img
                    contain
                    width="30"
                    height="30"
                    class="me-1"
                    :src="energy.images.icon.url"
                  ></v-img>
                  {{ energy.quantity }}
                </div>
                <v-btn
                  color="blue"
                  dark
                  class="ms-4"
                  @click="
                    $router.push({
                      name: 'PageMathMastery',
                    })
                  "
                >
                  <span class="text-h6">
                    {{ $t("string.go") }}
                  </span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
        
    <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    masteryData: null,
    towerData: null,
    energy: null,
    footPrints: null,
    schoolYear: null,
    classroomMonth: 3,
  }),
  async created() {
    if (this.auth.Classroom) {
      this.schoolYear = this.auth.Classroom.schoolYear;
      this.classroomMonth = this.auth.Classroom.month;
    } else if (this.auth.Player.userType == "adult") {
      this.schoolYear = 1;
    } else {
      this.schoolYear = this.auth.User.schoolYear;
      this.classroomMonth = this.auth.User.month;
    }

    await this.loadMastery();
    await this.loadEnergy();
    await this.loadTower();
    await this.loadFootprints();
  },
  mounted() {},
  methods: {
    exit() {
      this.$router.push({
        name: "PageExploreLanding",
      });
    },
    async loadTower() {
      this.api.url =
        this.$api.servers.game +
        "/api/v2/" +
        this.$_getLocale() +
        "/game/dailyGame/visit";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.towerData = resp.Game;
      };
      this.api.params = {
        schoolYear: this.schoolYear,
        classroomMonth: this.classroomMonth,
      };
      await this.$api.fetch(this.api);
    },
    async loadEnergy() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/energy/check";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.energy = resp.Energy;
      };
      await this.$api.fetch(this.api);
    },
    async loadMastery() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/math/player/load";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.masteryData = resp.Player;
      };
      await this.$api.fetch(this.api);
    },
    async loadFootprints() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/footprint/check";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.footPrints = resp.Footprint;
      };
      await this.$api.fetch(this.api);
    },
  },
};
</script>
  
  <style scoped>
.tab-bar-card {
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #e1c8aa;
  text-align: center;
  display: flex;
}

.tab-card {
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  width: calc(100% / 2);
  background-color: #cbae8c;
  text-align: center;
  color: #fff;
  text-shadow: #876a47 3px 0px 0px, #876a47 2.83487px 0.981584px 0px,
    #876a47 2.35766px 1.85511px 0px, #876a47 1.62091px 2.52441px 0px,
    #876a47 0.705713px 2.91581px 0px, #876a47 -0.287171px 2.98622px 0px,
    #876a47 -1.24844px 2.72789px 0px, #876a47 -2.07227px 2.16926px 0px,
    #876a47 -2.66798px 1.37182px 0px, #876a47 -2.96998px 0.42336px 0px,
    #876a47 -2.94502px -0.571704px 0px, #876a47 -2.59586px -1.50383px 0px,
    #876a47 -1.96093px -2.27041px 0px, #876a47 -1.11013px -2.78704px 0px,
    #876a47 -0.137119px -2.99686px 0px, #876a47 0.850987px -2.87677px 0px,
    #876a47 1.74541px -2.43999px 0px, #876a47 2.44769px -1.73459px 0px,
    #876a47 2.88051px -0.838247px 0px;
}

.selected-tab-card {
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  width: calc(100% / 2);
  border: #68fffc 4px solid;
  background-color: #d3fdfc;
  text-align: center;
  color: #fff;
  text-shadow: #29a399 3px 0px 0px, #29a399 2.83487px 0.981584px 0px,
    #29a399 2.35766px 1.85511px 0px, #29a399 1.62091px 2.52441px 0px,
    #29a399 0.705713px 2.91581px 0px, #29a399 -0.287171px 2.98622px 0px,
    #29a399 -1.24844px 2.72789px 0px, #29a399 -2.07227px 2.16926px 0px,
    #29a399 -2.66798px 1.37182px 0px, #29a399 -2.96998px 0.42336px 0px,
    #29a399 -2.94502px -0.571704px 0px, #29a399 -2.59586px -1.50383px 0px,
    #29a399 -1.96093px -2.27041px 0px, #29a399 -1.11013px -2.78704px 0px,
    #29a399 -0.137119px -2.99686px 0px, #29a399 0.850987px -2.87677px 0px,
    #29a399 1.74541px -2.43999px 0px, #29a399 2.44769px -1.73459px 0px,
    #29a399 2.88051px -0.838247px 0px;
}

.constant-tilt-shake {
  animation: tilt-shaking 2s infinite;
}

.constant-tilt-shake:hover {
  animation: none;
}

@keyframes tilt-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  3% {
    transform: translate(2px, -2px) rotate(5deg);
  }
  6% {
    transform: translate(0, 0) rotate(0eg);
  }
  9% {
    transform: translate(-2px, -2px) rotate(-5deg);
  }
  12% {
    transform: translate(0, 0) rotate(0deg);
  }
  15% {
    transform: translate(2px, -2px) rotate(5deg);
  }
  18% {
    transform: translate(0, 0) rotate(0eg);
  }
  21% {
    transform: translate(-2px, -2px) rotate(-5deg);
  }
  24% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.menu-button {
  padding: 0 5px;
  cursor: pointer;
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.list-card {
  color: #785c3a;
  border-radius: 10px;
  border: #ccaf8b 4px solid;
  background-color: #fff9f1;
  overflow-x: hidden !important;
}

.list-card-x {
  padding: 5px;
  color: #785c3a;
  border-radius: 5px;
  border: #ccaf8b 2px solid;
  background-color: #fff9f1;
  overflow-x: hidden !important;
}

.level-cart {
  padding: 10px;
  margin-bottom: 12px;
  width: calc((100% / 3) - 8px);
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.main-body {
  /*background-color: #c7e5e9;*/
  background-color: rgba(214, 132, 49, 0.3);
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}
</style>