import Phaser from "phaser";
import LoadingScene from "@/dailyGames/Questions/scenes/LoadingScene";
import QuestionScene from "@/dailyGames/Questions/scenes/QuestionScene";
import Settings from "@/dailyGames/Settings";
import Recommendation from "@/dailyGames/Questions/scenes/Recommendation";


function launch(containerId) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    backgroundColor: 0xf5f5f5,
    parent: containerId,
    scale: {
      parent: containerId,
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
      width: 480,
      height: 854,
    },
    physics: {
      default: "arcade",
    },
    dom: {
      createContainer: true,
    },
    scene: [
      LoadingScene,
      QuestionScene,
      Settings,
      Recommendation,
    ],
  });
}

export default launch;
export { launch };
